var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckCircle, Error } from '@mui/icons-material';
import Button from 'components/library/Button';
import ValidationComments from 'components/Validation/ValidationComments';
import styles from './TunnelSummary.module.css';
import TunnelSummaryActivities from './TunnelSummaryActivities';
export default function TunnelSummary(_a) {
    var status = _a.status, activities = _a.activities, comments = _a.comments, onCreate = _a.onCreate, onDelete = _a.onDelete, onEdit = _a.onEdit, markAsResolved = _a.markAsResolved, onReview = _a.onReview, onReject = _a.onReject, onApprove = _a.onApprove, children = _a.children;
    var getLastActivity = function () {
        var activitiesSorted = __spreadArray([], activities, true);
        activitiesSorted.sort(function (a, b) { return new Date(a.date).getDate() - new Date(b.date).getDate(); });
        return activitiesSorted[0];
    };
    var displayKycStatus = function () {
        var lastActivity = getLastActivity();
        if ((lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.action) === 'validated_kyc') {
            return (_jsxs("div", { className: "bounce-in ".concat(styles.validation), children: [_jsxs("p", { className: styles.title, children: [_jsx(CheckCircle, { className: styles.icon }), "KYC has been approved"] }), _jsx("p", { className: styles.description, children: "Kyc has been approved the ".concat(lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.date, " by ").concat(lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.actor) })] }));
        }
        if ((lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.action) === 'rejected_kyc') {
            return (_jsxs("div", { className: "bounce-in ".concat(styles.validation), children: [_jsxs("p", { className: styles.title, children: [_jsx(Error, { className: styles.icon }), "KYC has been rejected"] }), _jsx("p", { className: styles.description, children: "Kyc has been rejected the ".concat(lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.date, " by ").concat(lastActivity === null || lastActivity === void 0 ? void 0 : lastActivity.actor) })] }));
        }
        return null;
    };
    return (_jsxs(_Fragment, { children: [displayKycStatus(), _jsxs("div", { className: styles.row, children: [_jsxs("div", { children: [_jsx("p", { className: styles.title, children: "Summary" }), children] }), _jsxs("div", { className: styles.comments, children: [_jsx("p", { className: styles.title, children: "Comments" }), _jsx(ValidationComments, { comments: comments || [], onCreate: onCreate, onEdit: onEdit, onDelete: onDelete, markAsResolved: markAsResolved }), _jsx(TunnelSummaryActivities, { activities: activities })] })] }), (status === 'default')
                && (_jsxs("footer", { className: styles.footer, children: [_jsx(Button, { type: "primary", onClick: onReject, children: "Reject the KYC" }), _jsx(Button, { type: "primary", onClick: onApprove, children: "Approved the KYC" }), _jsx(Button, { onClick: onReview, children: "Begin the review" })] }))] }));
}
