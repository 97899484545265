var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import Button from 'components/library/Button';
import useAPI from 'hooks/useAPI';
import { createWorldCheckByIss, getWorldcheckDocument, updateWorldCheckByIss, uploadFileUser, } from 'services/network/api/users';
import * as statuses from 'services/store/states';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import fetchMedia from 'utils/fetchMedia';
import { formatDate } from 'utils/date';
import ValidationSummaryValue from '../ValidationSummaryValue';
import styles from './ValidationSummaryWorldCheck.module.css';
import { logError } from 'utils/logs';
export default function ValidationSummaryWorldCheck(_a) {
    var _this = this;
    var clientId = _a.clientId, worldCheck = _a.worldCheck;
    var _b = useState(worldCheck || { validation: null, comment: '', type: null }), form = _b[0], setForm = _b[1];
    var _c = useState(), documentToUpload = _c[0], setDocumentToUpload = _c[1];
    var dispatch = useDispatch();
    var worldCheckDocument = useAPI({
        request: function (source) { return getWorldcheckDocument(clientId, source); },
    });
    var handleFile = function (e) {
        var _a;
        if (e.target.files && e.target.files[0]) {
            setDocumentToUpload({
                file: e.target.files[0],
                iss: clientId,
                type: 'worldcheck',
                name: (_a = e.target.files[0]) === null || _a === void 0 ? void 0 : _a.name,
            });
        }
    };
    var onWorldCheckSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var formData, newDocument, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 7, , 8]);
                    if (!worldCheck) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateWorldCheckByIss(clientId, form)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, createWorldCheckByIss(clientId, form)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (!documentToUpload) return [3 /*break*/, 6];
                    formData = new FormData();
                    formData.append('file', documentToUpload.file);
                    formData.append('iss_user', documentToUpload.iss);
                    formData.append('type', documentToUpload.type);
                    formData.append('name', documentToUpload.name);
                    return [4 /*yield*/, uploadFileUser(formData)];
                case 5:
                    newDocument = (_a.sent()).data;
                    worldCheckDocument.setData(newDocument);
                    _a.label = 6;
                case 6:
                    setDocumentToUpload(undefined);
                    dispatch(addToast({
                        type: 'success',
                        title: 'World check and sanction screening has been updated',
                        description: '',
                    }));
                    return [3 /*break*/, 8];
                case 7:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible to submit WorldCheck changes',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var openWorldCheckDocument = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, fetchMedia(function (url) { return window.open(url); }, null, "/media".concat((_a = worldCheckDocument === null || worldCheckDocument === void 0 ? void 0 : worldCheckDocument.data) === null || _a === void 0 ? void 0 : _a.path, "/").concat((_b = worldCheckDocument === null || worldCheckDocument === void 0 ? void 0 : worldCheckDocument.data) === null || _b === void 0 ? void 0 : _b.name))];
                case 1:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleWorldCheck = function (e) {
        setForm(function (curr) { return (__assign(__assign({}, curr), { validation: (e.target.checked) ? formatDate(new Date()) : null })); });
    };
    return (_jsx(ValidationSummaryValue, { label: "WorldCheck and sanction screening", value: (_jsxs("div", { className: styles['world-check'], children: [_jsxs("p", { children: [_jsx("span", { className: styles.label, children: "Type :" }), _jsx("span", { children: (form === null || form === void 0 ? void 0 : form.type) || 'N/A' })] }), _jsxs("p", { children: [_jsx("span", { className: styles.label, children: "Comment :" }), _jsx("span", { children: (form === null || form === void 0 ? void 0 : form.comment) || 'N/A' })] }), _jsxs("p", { children: [_jsx("span", { className: styles.label, children: "Validation :" }), _jsx("span", { children: (form === null || form === void 0 ? void 0 : form.validation) || 'N/A' })] }), ((worldCheckDocument.status === statuses.LOADING)
                    || (worldCheckDocument.status === statuses.IDLE))
                    && (_jsxs("p", { children: [_jsx("i", { className: "gg-spinner" }), "Loading world check and sanction screening file..."] })), (worldCheckDocument.status === statuses.SUCCESS)
                    && (_jsxs(Button, { onClick: openWorldCheckDocument, variant: "ghost", className: styles.document, disabled: !worldCheckDocument.data.path, children: [_jsx(Visibility, { className: styles['visible-icon'] }), "See world check document"] }))] })), name: "worldCheck", onSave: onWorldCheckSubmit, children: _jsxs("form", { className: styles.form, children: [_jsxs("label", { htmlFor: "type", className: styles['customer-type'], children: ["Type", _jsxs("select", { id: "type", className: styles['customer-type-select'], value: (form === null || form === void 0 ? void 0 : form.type) || '', onChange: function (e) { return setForm(function (curr) { return (__assign(__assign({}, curr), { type: (e.target.value) })); }); }, children: [_jsx("option", { value: "", disabled: true, children: "Select a type" }), _jsx("option", { value: "PEP", children: "PEP" }), _jsx("option", { value: "CFT", children: "CFT" }), _jsx("option", { value: "other", children: "other" })] })] }), _jsxs("label", { htmlFor: "comment", className: styles.comment, children: ["Comment", _jsx("textarea", { id: "comment", className: styles['comment-input'], value: (form === null || form === void 0 ? void 0 : form.comment) || '', onChange: function (e) { return setForm(function (curr) { return (__assign(__assign({}, curr), { comment: e.target.value })); }); } })] }), _jsxs("label", { htmlFor: "worldCheck", className: styles['checkbox-label'], children: [_jsx("input", { id: "worldCheck", type: "checkbox", name: "worldCheck", className: styles.checkbox, checked: !!((form === null || form === void 0 ? void 0 : form.validation) && (form.validation !== '')), onChange: handleWorldCheck }), "World check and sanction screen has been done"] }), _jsxs("label", { htmlFor: "worldCheckFile", className: styles.file, children: ["Add a file for world check and sanction screening", _jsx("input", { id: "worldCheckFile", type: "file", onChange: handleFile })] })] }) }));
}
