// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fiWehBflra4rN7YZKPdQ {
  align-self: center;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-name: IpU21LQs1ziuIdHB8zG6;
  animation-timing-function: linear;
  height: 20px;
  opacity: 0.5;
  width: 20px;
}

@keyframes IpU21LQs1ziuIdHB8zG6 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelDocuments/TunnelDocuments.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,0BAA0B;EAC1B,mCAAmC;EACnC,oCAAoB;EACpB,iCAAiC;EACjC,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading {\n  align-self: center;\n  animation-duration: 1000ms;\n  animation-iteration-count: infinite;\n  animation-name: spin;\n  animation-timing-function: linear;\n  height: 20px;\n  opacity: 0.5;\n  width: 20px;\n}\n\n@keyframes spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `fiWehBflra4rN7YZKPdQ`,
	"spin": `IpU21LQs1ziuIdHB8zG6`
};
export default ___CSS_LOADER_EXPORT___;
