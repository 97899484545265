import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowForward } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import styles from './TableRows.module.css';
export default function TableRows(_a) {
    var name = _a.name, columns = _a.columns, data = _a.data, link = _a.link, onSelect = _a.onSelect, columnsStyle = _a.columnsStyle;
    return (_jsx("div", { children: (data && data.map(function (row) { return (_jsxs("div", { className: styles.row, style: columnsStyle, children: [_jsxs("div", { className: styles.checkbox, children: [_jsx("input", { type: "checkbox", checked: row.selected, onChange: function (e) { return onSelect((row === null || row === void 0 ? void 0 : row.iss) || (row === null || row === void 0 ? void 0 : row.issUser) || 0, e.target.checked); } }), _jsx("p", { children: name(row) })] }), columns.map(function (column, index) { return (
                // Columns are not changing so index can be used without risking rendering problems
                // eslint-disable-next-line react/no-array-index-key
                _jsx("div", { children: column(row) }, index)); }), _jsx("div", { children: _jsx("p", { className: "".concat(styles.status, " ").concat(styles[row.kycStatus]), children: row.kycStatus }) }), _jsx(NavLink, { to: link(row), className: styles.button, children: _jsx(ArrowForward, {}) })] }, (row === null || row === void 0 ? void 0 : row.iss) || (row === null || row === void 0 ? void 0 : row.id))); })) }));
}
