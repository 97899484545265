// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B4puXI4GnHnoWKjT3ela,
.Kry3tZHmasVB8eMvkPa7 {
  border-radius: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 1rem;
}

.B4puXI4GnHnoWKjT3ela {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-light"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-dark"]};
}

.Kry3tZHmasVB8eMvkPa7 {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-bg"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/FilterDocument/FilterDocument.module.css"],"names":[],"mappings":"AAGA;;EAEE,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,8DAAqC;EACrC,mDAAyB;AAC3B;;AAEA;EACE,8DAA8B;EAC9B,mDAAqB;AACvB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-success-light, color-success-dark, color-red-bg, color-red-dark from colors;\n\n.valid,\n.expired {\n  border-radius: 0.25rem;\n  font-size: 0.875rem;\n  font-weight: 500;\n  padding: 0 1rem;\n}\n\n.valid {\n  background-color: color-success-light;\n  color: color-success-dark;\n}\n\n.expired {\n  background-color: color-red-bg;\n  color: color-red-dark;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-success-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-light"]}`,
	"color-success-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-dark"]}`,
	"color-red-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-bg"]}`,
	"color-red-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]}`,
	"valid": `B4puXI4GnHnoWKjT3ela`,
	"expired": `Kry3tZHmasVB8eMvkPa7`
};
export default ___CSS_LOADER_EXPORT___;
