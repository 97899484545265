import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import styles from './ButtonTooltip.module.css';
export default function ButtonTooltip(_a) {
    var className = _a.className, children = _a.children, value = _a.value, onClick = _a.onClick;
    var _b = useState(false), isHovered = _b[0], setIsHovered = _b[1];
    var handleMouseEnter = function () { return setIsHovered(true); };
    var handleMouseLeave = function () { return setIsHovered(false); };
    return (_jsxs("div", { className: styles.container, children: [_jsx("button", { type: "button", className: "".concat(styles.button, " ").concat(className), onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onClick: onClick, children: children }), (isHovered && value)
                && (_jsx("div", { className: "fade-in ".concat(styles.tooltip), children: _jsx("div", { className: styles.content, children: value }) }))] }));
}
