var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { keysToCamel, keysToSnake } from 'utils/converter';
var isAddress = function (data) { return !!data
    && typeof data === 'object'
    && 'address' in data
    && 'city' in data; };
var formatAddress = function (data) {
    if (isAddress(data)) {
        var address = data.address, city = data.city, country = data.country, streetNumber = data.streetNumber, zipCode = data.zipCode;
        return {
            streetNumber: streetNumber,
            address: address,
            zipCode: zipCode,
            city: city,
            country: country,
        };
    }
    return {
        streetNumber: '',
        address: '',
        zipCode: '',
        city: '',
        country: '',
    };
};
export var isEntity = function (data) { return !!data
    && typeof data === 'object'
    && 'siren' in data
    && 'societyName' in data
    && 'id' in data; };
export function entityAdaptor(data) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var formattedData = keysToCamel(data);
    if (isEntity(formattedData)) {
        var NAF = formattedData.NAF, tag = formattedData.tag, legalName = formattedData.legalName, societyName = formattedData.societyName, activity = formattedData.activity, address = formattedData.address, legalForm = formattedData.formJuridique, siren = formattedData.siren, number = formattedData.number, email = formattedData.email, startingExercise = formattedData.startingExercise, updated = formattedData.updated, id = formattedData.id, issUser = formattedData.issUser, status_1 = formattedData.status, others = __rest(formattedData, ["NAF", "tag", "legalName", "societyName", "activity", "address", "formJuridique", "siren", "number", "email", "startingExercise", "updated", "id", "issUser", "status"]);
        var user = void 0;
        if (formattedData.user) {
            var _l = formattedData.user, idAddress = _l.idAddress, idAddressBirth = _l.idAddressBirth, idContactAddress = _l.idContactAddress, newUser = __rest(_l, ["idAddress", "idAddressBirth", "idContactAddress"]);
            user = newUser;
        }
        return __assign(__assign({}, others), { general: __assign(__assign(__assign(__assign(__assign({ id: id, tag: tag, legalName: legalName, societyName: societyName, activity: activity, legalForm: legalForm || 'N/A', siren: siren, status: status_1, startingExercise: startingExercise, updated: updated, companyNumber: number || '', companyEmail: email || (user === null || user === void 0 ? void 0 : user.email) || '', country: (address === null || address === void 0 ? void 0 : address.country) || '', city: (address === null || address === void 0 ? void 0 : address.city) || '', zipCode: (address === null || address === void 0 ? void 0 : address.zipCode) || '', address: (address === null || address === void 0 ? void 0 : address.address) || '', streetNumber: (address === null || address === void 0 ? void 0 : address.streetNumber) || '', incorporationForm: ((_a = formattedData === null || formattedData === void 0 ? void 0 : formattedData.incorporation) === null || _a === void 0 ? void 0 : _a.incorporationForm) || '', incorporation: ((_b = formattedData === null || formattedData === void 0 ? void 0 : formattedData.incorporation) === null || _b === void 0 ? void 0 : _b.incorporation) || 'N/A', website: formattedData.website || 'N/A', leiStatus: ((_c = formattedData.lei) === null || _c === void 0 ? void 0 : _c.leiStatus) || 'Not available', lei: ((_d = formattedData.lei) === null || _d === void 0 ? void 0 : _d.leiCode) || 'Not available yet', financialInstitution: (_e = formattedData.financialInstitution) === null || _e === void 0 ? void 0 : _e.status, issUser: issUser }, (((_f = formattedData.financialInstitution) === null || _f === void 0 ? void 0 : _f.status)
                ? {
                    GIIN: formattedData.financialInstitution.GIIN || '',
                    authority: formattedData.financialInstitution.authority || '',
                    homeCountry: formattedData.financialInstitution.homeCountry || '',
                    institutionType: formattedData.financialInstitution.institutionType || '',
                    license: formattedData.financialInstitution.license || '',
                    regulatoryStatus: formattedData.financialInstitution.regulatoryStatus || '',
                    regulatoryWebsite: formattedData.financialInstitution.regulatoryWebsite || '',
                }
                : {})), { companyGroup: (_g = formattedData.companyGroup) === null || _g === void 0 ? void 0 : _g.status }), (((_h = formattedData.companyGroup) === null || _h === void 0 ? void 0 : _h.status)
                ? {
                    companyGroupCountry: formattedData.companyGroup.country || '',
                    parentCompanyGroup: formattedData.companyGroup.parentCompanyGroup || '',
                    subsidiaryCompanies: formattedData.companyGroup.subsidiaryCompanies || '',
                }
                : {})), { UBO: formattedData.UBO || 'N/A', otherLicense: ((_j = formattedData.otherLicense) === null || _j === void 0 ? void 0 : _j.status) || false }), (((_k = formattedData.otherLicense) === null || _k === void 0 ? void 0 : _k.status)
                ? {
                    otherLicenseAuthority: formattedData.otherLicense.authority || '',
                    otherLicenseCountry: formattedData.otherLicense.country || '',
                    otherLicenseName: formattedData.otherLicense.license || '',
                }
                : {})), user: __assign(__assign({}, user), { email: (user === null || user === void 0 ? void 0 : user.email) || '', isConfig: (user === null || user === void 0 ? void 0 : user.isConfig) || false, iss: issUser, address: formatAddress(user === null || user === void 0 ? void 0 : user.address), contactAddress: formatAddress(user === null || user === void 0 ? void 0 : user.contactAddress), addressBirth: formatAddress(user === null || user === void 0 ? void 0 : user.addressBirth), worldCheck: (user === null || user === void 0 ? void 0 : user.worldCheck) || null }) });
    }
    throw new Error('Invalid data');
}
export function entityBackendAdaptor(data) {
    var _a = data.general, legalName = _a.legalName, societyName = _a.societyName, activity = _a.activity, companyNumber = _a.companyNumber, companyEmail = _a.companyEmail, legalForm = _a.legalForm, siren = _a.siren, startingExercise = _a.startingExercise, address = __rest(_a, ["legalName", "societyName", "activity", "companyNumber", "companyEmail", "legalForm", "siren", "startingExercise"]);
    var general = data.general, others = __rest(data, ["general"]);
    return keysToSnake(__assign({ legalName: legalName, societyName: societyName, activity: activity, formJuridique: legalForm, number: companyNumber, email: companyEmail, siren: siren, startingExercise: startingExercise, address: address, issUser: data.user.iss, iss: data.user.iss }, others));
}
export function fileEntityBackendAdaptor(data) {
    var formData = new FormData();
    formData.append('file', data.file);
    formData.append('siren', data.siren);
    formData.append('iss_user', data.iss);
    formData.append('type', data.type);
    formData.append('name', data.name);
    return formData;
}
