// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Mq4SEUtRd7araJZUrNQP {
  border-bottom: 1px solid #e5e5e5;
}

.tKygQ4LMDikHs5Hsh0Qk {
  align-items: center;
  column-gap: 16px;
  display: flex;
  flex-direction: row;
  min-height: 56px;
}

.fUnS8BXXQeLUzup7jb9J {
  font-family: "Fjalla One", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
}

.VT7vrIbFRCSjs6mrLoSQ,
.VMEEL2Y2r_Mr0hzwjSgA {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  height: 42px;
  justify-content: center;
  padding: 0 32px;
  text-decoration: none;
  transition: 0.3s;
}

.VT7vrIbFRCSjs6mrLoSQ {
  color: black;
}

.JEy1mpMxOmIACcQpl6Td {
  margin-right: 4px;
  transition: 0.3s;
}

.VT7vrIbFRCSjs6mrLoSQ .JEy1mpMxOmIACcQpl6Td {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
}

.VT7vrIbFRCSjs6mrLoSQ:hover .JEy1mpMxOmIACcQpl6Td {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.VMEEL2Y2r_Mr0hzwjSgA,
.VT7vrIbFRCSjs6mrLoSQ:hover,
.VMEEL2Y2r_Mr0hzwjSgA:hover {
  background-color: #f8f9fb;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/Navigation/Navigation.module.css"],"names":[],"mappings":"AAGA;EACE,gCAAgC;AAClC;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,qCAAqC;EACrC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;AACX;;AAEA;;EAEE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,mDAAiB;AACnB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;;;EAGE,yBAAyB;EACzB,mDAAmB;AACrB","sourcesContent":["@value colors: \"../library/style/colors.css\";\n@value color-gray, color-purple-bg, color-purple from colors;\n\n.container {\n  border-bottom: 1px solid #e5e5e5;\n}\n\n.navigation {\n  align-items: center;\n  column-gap: 16px;\n  display: flex;\n  flex-direction: row;\n  min-height: 56px;\n}\n\n.logo {\n  font-family: \"Fjalla One\", sans-serif;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 400;\n  margin: 0;\n}\n\n.link,\n.active {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  font-size: 16px;\n  height: 42px;\n  justify-content: center;\n  padding: 0 32px;\n  text-decoration: none;\n  transition: 0.3s;\n}\n\n.link {\n  color: black;\n}\n\n.icon {\n  margin-right: 4px;\n  transition: 0.3s;\n}\n\n.link .icon {\n  color: color-gray;\n}\n\n.link:hover .icon {\n  color: color-purple;\n}\n\n.active,\n.link:hover,\n.active:hover {\n  background-color: #f8f9fb;\n  color: color-purple;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"container": `Mq4SEUtRd7araJZUrNQP`,
	"navigation": `tKygQ4LMDikHs5Hsh0Qk`,
	"logo": `fUnS8BXXQeLUzup7jb9J`,
	"link": `VT7vrIbFRCSjs6mrLoSQ`,
	"active": `VMEEL2Y2r_Mr0hzwjSgA`,
	"icon": `JEy1mpMxOmIACcQpl6Td`
};
export default ___CSS_LOADER_EXPORT___;
