// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrVYEP8MZ8RBC_fygcBN {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}

.LXv_zMXC6k3JpBQqlcPi {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.wDwHOyahA6cXnciLcNOr {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.zcb1r7No4Sj3yohUCKA5 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.x4kSnT0RI3Xc9g57tK3T .zcb1r7No4Sj3yohUCKA5 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/DataViewer/DataViewerLabel/DataViewerLabel.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,mDAAkB;AACpB","sourcesContent":["@value colors: \"../../library/style/colors.css\";\n@value color-text-subtle, color-error from colors;\n\n.row {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n\n.group {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n}\n\n.label {\n  font-family: Roboto, sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  margin: 0;\n}\n\n.icon {\n  color: color-text-subtle;\n}\n\n.active .icon {\n  color: color-error;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../../library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"row": `wrVYEP8MZ8RBC_fygcBN`,
	"group": `LXv_zMXC6k3JpBQqlcPi`,
	"label": `wDwHOyahA6cXnciLcNOr`,
	"icon": `zcb1r7No4Sj3yohUCKA5`,
	"active": `x4kSnT0RI3Xc9g57tK3T`
};
export default ___CSS_LOADER_EXPORT___;
