var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Search } from '@mui/icons-material';
import COUNTRY from 'constants/country';
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import filterStyles from '../FiltersStatus/FiltersStatus.module.css';
import styles from './FilterCountry.module.css';
// Wavy country flag : https://flagpedia.net/download/icons
export default function FilterCountry() {
    var _a = useState(''), query = _a[0], setQuery = _a[1];
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var updateSelection = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                country: (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected.country || []), true), [e.target.value], false) : (selected.country || []).filter(function (value) { return value !== e.target.value; }),
            },
        });
    };
    var filteredCountries = COUNTRY
        .filter(function (country) { return country.code.includes(query.toUpperCase())
        || country.name.toLowerCase().includes(query.toLowerCase()); });
    return (_jsxs("div", { className: filterStyles.dropdown, children: [_jsxs("label", { className: styles.searchbar, htmlFor: "search", children: [_jsx(Search, { className: styles.icon }), _jsx("input", { type: "text", placeholder: "Search", value: query, onChange: function (e) { return setQuery(e.target.value); } })] }), _jsx("div", { className: "".concat(styles.list, " styled-scrollbars"), children: (filteredCountries.length === 0)
                    ? _jsx("p", { className: styles['no-result'], children: "No result" })
                    : filteredCountries
                        .map(function (country) {
                        var _a;
                        return (_jsxs("label", { htmlFor: "country-".concat(country.code.toLowerCase()), className: styles.country, children: [_jsx("input", { type: "checkbox", id: "country-".concat(country.code.toLowerCase()), value: country.code, checked: ((_a = selected.country) === null || _a === void 0 ? void 0 : _a.includes(country.code)) || false, onChange: updateSelection, "aria-label": country.name }), _jsxs("div", { className: styles['country-name'], children: [_jsx("img", { src: "https://flagcdn.com/20x15/".concat(country.code.toLowerCase(), ".png"), srcSet: "https://flagcdn.com/40x30/".concat(country.code.toLowerCase(), ".png 2x, https://flagcdn.com/60x45/").concat(country.code.toLowerCase(), ".png 3x"), width: "20", height: "15", alt: country.name }), country.name] })] }, country.code));
                    }) })] }));
}
