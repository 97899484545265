var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from '../axiosClient';
export var getReservationByIss = function (iss, source) { return axios.get('/payment/reservation', {
    params: { iss_person: iss },
    cancelToken: source.token,
}); };
export var getReservationsByProject = function (project, source) { return axios.get('/payment/reservation', {
    params: { transaction__id_project: project, status: 'succeeded' },
    cancelToken: source.token,
}); };
export var getAllUsersFromPayment = function (source, page, resultsPerPage, filters) { return axios.get('/payment/user', {
    params: __assign(__assign({}, filters), { page: page, page_size: resultsPerPage }),
    cancelToken: source.token,
}); };
export var getAllEntitiesFromPayment = function (source, page, resultsPerPage, filters) { return (axios.get('/payment/entity', {
    params: __assign(__assign({}, filters), { iss: (filters === null || filters === void 0 ? void 0 : filters.q) === '' ? undefined : filters === null || filters === void 0 ? void 0 : filters.q, page: page, page_size: resultsPerPage }),
    cancelToken: source.token,
})); };
