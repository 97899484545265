import { jsx as _jsx } from "react/jsx-runtime";
import { Check, Clear } from '@mui/icons-material';
import styles from './BreadcrumbStatus.module.css';
export default function BreadcrumbStatus(_a) {
    var status = _a.status, step = _a.step, current = _a.current, active = _a.active;
    if (current === -1 || (current + 1) <= step) {
        return (_jsx("div", { className: "".concat(styles.step, " ").concat((active) ? styles.active : ''), children: _jsx("span", { className: styles.number, children: step }) }));
    }
    if (status === 'completed') {
        return (_jsx("div", { className: "".concat(styles.step, " ").concat(styles.success, " ").concat((active) ? styles.active : ''), children: _jsx(Check, { className: styles.icon }) }));
    }
    if (status === 'error') {
        return (_jsx("div", { className: "".concat(styles.step, " ").concat(styles.error, " ").concat((active) ? styles.active : ''), children: _jsx(Clear, { className: styles.icon }) }));
    }
    return (_jsx("div", { className: "".concat(styles.step, " ").concat((active) ? styles.active : ''), children: _jsx("span", { className: styles.number, children: step }) }));
}
