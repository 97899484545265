// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oGzSZzrEq9JWUHzBpqIg {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  color: white;
}

.oGzSZzrEq9JWUHzBpqIg:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
  color: white;
}

.iduLTeo4GkzcqyKIJp5e {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red"]};
  color: white;
}

.iduLTeo4GkzcqyKIJp5e:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]};
  color: white;
}

.KZIGJQTFtC_dqdFPVc62 {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 42px;
  justify-content: center;
  padding: 0 16px;
  transition: 0.3s;
}

.KZIGJQTFtC_dqdFPVc62.xzcc74I0fQVpoalobTaP {
  background: none;
  padding: 0;
  width: fit-content;
}

.KZIGJQTFtC_dqdFPVc62.oGzSZzrEq9JWUHzBpqIg.xzcc74I0fQVpoalobTaP {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.KZIGJQTFtC_dqdFPVc62.oGzSZzrEq9JWUHzBpqIg.xzcc74I0fQVpoalobTaP:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
}

.KZIGJQTFtC_dqdFPVc62[disabled],
.KZIGJQTFtC_dqdFPVc62[disabled]:hover {
  opacity: 0.5;
}

.KZIGJQTFtC_dqdFPVc62.xzcc74I0fQVpoalobTaP[disabled],
.KZIGJQTFtC_dqdFPVc62.xzcc74I0fQVpoalobTaP[disabled]:hover {
  opacity: 0.5;
  width: fit-content;
}

.YgtUFo7spBnxS6YJm1Ns {
  background: none;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.YgtUFo7spBnxS6YJm1Ns:hover {
  background: none;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/library/Button/Button.module.css"],"names":[],"mappings":"AAGA;EACE,8DAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,8DAAmC;EACnC,YAAY;AACd;;AAEA;EACE,8DAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,8DAAgC;EAChC,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;;EAEE,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,8DAA8B;EAC9B,mDAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,8DAAmC;EACnC,mDAAwB;AAC1B","sourcesContent":["@value colors: \"../style/colors.css\";\n@value color-purple, color-purple-dark, color-gray-light, color-text-subtle, color-red, color-red-dark from colors;\n\n.secondary {\n  background-color: color-purple;\n  color: white;\n}\n\n.secondary:hover {\n  background-color: color-purple-dark;\n  color: white;\n}\n\n.primary {\n  background-color: color-red;\n  color: white;\n}\n\n.primary:hover {\n  background-color: color-red-dark;\n  color: white;\n}\n\n.button {\n  align-items: center;\n  border-radius: 4px;\n  display: flex;\n  height: 42px;\n  justify-content: center;\n  padding: 0 16px;\n  transition: 0.3s;\n}\n\n.button.ghost {\n  background: none;\n  padding: 0;\n  width: fit-content;\n}\n\n.button.secondary.ghost {\n  color: color-purple;\n}\n\n.button.secondary.ghost:hover {\n  color: color-purple-dark;\n}\n\n.button[disabled],\n.button[disabled]:hover {\n  opacity: 0.5;\n}\n\n.button.ghost[disabled],\n.button.ghost[disabled]:hover {\n  opacity: 0.5;\n  width: fit-content;\n}\n\n.outline {\n  background: none;\n  border: 1px solid color-purple;\n  color: color-purple;\n}\n\n.outline:hover {\n  background: none;\n  border: 1px solid color-purple-dark;\n  color: color-purple-dark;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]}`,
	"color-gray-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray-light"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-red": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red"]}`,
	"color-red-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]}`,
	"secondary": `oGzSZzrEq9JWUHzBpqIg`,
	"primary": `iduLTeo4GkzcqyKIJp5e`,
	"button": `KZIGJQTFtC_dqdFPVc62`,
	"ghost": `xzcc74I0fQVpoalobTaP`,
	"outline": `YgtUFo7spBnxS6YJm1Ns`
};
export default ___CSS_LOADER_EXPORT___;
