import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import useAPI from 'hooks/useAPI';
import { getEntityBySiren } from 'services/network/api/entity';
import { entityAdaptor, } from 'services/network/adaptor/entity';
import getErrorMessage from 'utils/error';
import Loading from 'components/library/Loading';
import EntityResult from './EntityResult';
import styles from './Entity.module.css';
export default function Entity(_a) {
    var _b;
    var connectedUserIss = _a.connectedUserIss, userName = _a.userName;
    var _c = useParams(), siren = _c.siren, iss = _c.iss;
    if (siren) {
        var _d = useAPI({
            request: function (source) { return getEntityBySiren(source, siren, iss); },
            adaptor: entityAdaptor,
        }), status_1 = _d.status, error = _d.error, data = _d.data, setData = _d.setData;
        if ((_b = data === null || data === void 0 ? void 0 : data.general) === null || _b === void 0 ? void 0 : _b.id) {
            return (_jsx(Loading, { status: status_1, children: _jsx(EntityResult, { connectedUserIss: connectedUserIss, userName: userName, error: getErrorMessage(error), data: data, siren: siren, setData: setData }) }));
        }
        return _jsx("p", { className: styles.section, children: "Sorry, Entity ID is missing. Please try again." });
    }
    return _jsx("p", { className: styles.section, children: "Please provide entity registration number" });
}
