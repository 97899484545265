// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uqBoo4d2wLULGOiV9Jmg {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0.25rem 0 0;
}

.uqBoo4d2wLULGOiV9Jmg span {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  margin: 0;
}

.wAswwVqJ5p_r9W2Cs4x6 {
  margin: 0.25rem 0 0;
}

.wAswwVqJ5p_r9W2Cs4x6 span {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  margin-right: 0.25rem;
}

.CJkyEv6LsgX1eyobDdT0 {
  margin-top: 0.5rem;
}

.CJkyEv6LsgX1eyobDdT0 label {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.xarZ7OzOQLxnwmceRx89 {
  border: 0;
  border-radius: 0.25rem;
  border-right: 0.5rem solid transparent;
  outline: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  padding: 0.5rem;
  transition: 0.3s;
}

.xarZ7OzOQLxnwmceRx89:hover {
  outline-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.xarZ7OzOQLxnwmceRx89:focus {
  outline: 2px solid #000;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationSummary/ValidationSummaryClient/ValidationSummaryClient.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,SAAS;EACT,sBAAsB;EACtB,sCAAsC;EACtC,+DAA6B;EAC7B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2DAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-gray, color-purple from colors;\n\n.loading {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  margin: 0.25rem 0 0;\n}\n\n.loading span {\n  color: color-text-subtle;\n  margin: 0;\n}\n\n.type {\n  margin: 0.25rem 0 0;\n}\n\n.type span {\n  color: color-text-subtle;\n  margin-right: 0.25rem;\n}\n\n.form {\n  margin-top: 0.5rem;\n}\n\n.form label {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n\n.select {\n  border: 0;\n  border-radius: 0.25rem;\n  border-right: 0.5rem solid transparent;\n  outline: 1px solid color-gray;\n  padding: 0.5rem;\n  transition: 0.3s;\n}\n\n.select:hover {\n  outline-color: color-purple;\n}\n\n.select:focus {\n  outline: 2px solid #000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"loading": `uqBoo4d2wLULGOiV9Jmg`,
	"type": `wAswwVqJ5p_r9W2Cs4x6`,
	"form": `CJkyEv6LsgX1eyobDdT0`,
	"select": `xarZ7OzOQLxnwmceRx89`
};
export default ___CSS_LOADER_EXPORT___;
