import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import styles from './Toasts.module.css';
function Toast(_a) {
    var item = _a.item, _b = _a.autoDeleteTime, autoDeleteTime = _b === void 0 ? 2500 : _b, actions = _a.actions;
    var _c = useState(0), time = _c[0], setTime = _c[1];
    var _d = useState(false), unmountTransition = _d[0], setUnmountTransition = _d[1];
    useEffect(function () {
        if (item.autoDeleteTime) {
            var setTimeInterval_1 = setInterval(function () { return setTime(function (state) { return state + 40; }); }, 40);
            var interval_1 = setInterval(function () {
                setUnmountTransition(true);
                clearInterval(setTimeInterval_1);
            }, autoDeleteTime);
            return function () {
                clearInterval(interval_1);
                clearInterval(setTimeInterval_1);
            };
        }
        return undefined;
    }, []);
    return (_jsxs("div", { className: "".concat(styles.toast, " ").concat((unmountTransition) ? styles.hideToast : ''), onAnimationEnd: function () {
            if (unmountTransition && item.id) {
                actions.removeToast(item.id);
            }
        }, children: [_jsx("div", { className: styles.time, style: {
                    backgroundColor: item.color,
                    width: (item.autoDeleteTime) ? "".concat((time * 100) / autoDeleteTime, "%") : '100%',
                } }), _jsxs("div", { className: styles.content, children: [_jsx("i", { style: { color: item.color }, children: item.icon }), _jsxs("div", { className: styles.textContainer, children: [_jsx("p", { className: styles.title, children: item.title }), _jsx("p", { className: styles.description, children: item.description })] }), _jsx("button", { type: "button", className: styles.button, onClick: function () { return setUnmountTransition(true); }, children: _jsx(Close, { className: styles.icon }) })] })] }));
}
export default function Toasts(_a) {
    var list = _a.list, autoDeleteTime = _a.autoDeleteTime, actions = _a.actions;
    return (_jsx("div", { className: styles.toastContainer, children: list && list.map(function (toast) { return (_jsx(Toast, { item: toast, autoDeleteTime: autoDeleteTime, actions: actions }, toast.id)); }) }));
}
