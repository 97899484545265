var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { keysToCamel } from 'utils/converter';
import { ENTITY_DOCUMENT_TYPES } from 'constants/documents';
import { entityAdaptor } from './entity';
import { userAdaptor } from './user';
import { commentAdaptor } from './comment';
export var areBackendHistories = function (data) { return !!data
    && Array.isArray(data)
    && data.every(function (item) { return typeof item === 'object' && item !== null && 'action' in item && 'created' in item; }); };
export function activityAdaptor(data) {
    if (!areBackendHistories(data))
        return [];
    return data.map(function (item) {
        var _a, _b;
        var formattedData = keysToCamel(item);
        return {
            actor: "".concat(((_a = formattedData.actor) === null || _a === void 0 ? void 0 : _a.firstName) || 'A', " ").concat(((_b = formattedData.actor) === null || _b === void 0 ? void 0 : _b.lastName) || ''),
            action: formattedData.action,
            date: formattedData.updated || formattedData.created,
        };
    });
}
export function userDocumentsAdaptor(documentMissing, identityCardInformation, proofOfAddressInformation) {
    var documents = {};
    if (identityCardInformation) {
        var _a = keysToCamel(identityCardInformation), expirationDate = _a.expirationDate, identityNumber = _a.identityNumber, files = _a.files;
        documents = __assign(__assign({}, documents), { identityCardInformation: {
                expirationDate: expirationDate,
                identityNumber: identityNumber,
                files: files,
            } });
    }
    if (proofOfAddressInformation) {
        var _b = keysToCamel(proofOfAddressInformation), proofOfAddressExpirationDate = _b.expirationDate, type = _b.type, proofOfAddressFiles = _b.files;
        documents = __assign(__assign({}, documents), { proofOfAddressInformation: {
                expirationDate: proofOfAddressExpirationDate,
                type: type,
                files: proofOfAddressFiles,
            } });
    }
    return documents;
}
export function entityDocumentsAdaptor(data) {
    return {
        documentMissing: data.filter(function (document) { return ENTITY_DOCUMENT_TYPES.includes(document); }),
    };
}
export function pipeUserAdaptor(data) {
    if (!data)
        return undefined;
    var formattedData = keysToCamel(data);
    var activities = formattedData.activities, comments = formattedData.comments, documentMissing = formattedData.documentMissing, entity = formattedData.entity, identityCardInformation = formattedData.identityCardInformation, proofOfAddressInformation = formattedData.proofOfAddressInformation, user = formattedData.user;
    return {
        type: (entity) ? 'entity' : 'user',
        entity: (entity) ? entityAdaptor(entity) : undefined,
        user: userAdaptor(user),
        userDocuments: userDocumentsAdaptor(documentMissing, identityCardInformation, proofOfAddressInformation),
        entityDocuments: entityDocumentsAdaptor(documentMissing),
        activities: activityAdaptor(activities),
        comments: comments.map(function (comment) { return commentAdaptor(comment); }),
    };
}
export function backendPipeActionAdaptor(data) {
    return {
        action: data.action,
        id_entity: data.idEntity || null,
        iss_user: data.iss,
    };
}
