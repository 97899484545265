// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rl1mcpRim63kFYCkLMTs {
  background-color: #f8f9fb;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}

.JnLYtatuuYAqqO7EIXRW {
  margin: auto;
  max-width: 1440px;
}

.fodIIbkBmkR4tr8PdQ3K {
  color: #9c9c9c;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  height: 48px;
  padding: 0;
}

.fodIIbkBmkR4tr8PdQ3K + .fodIIbkBmkR4tr8PdQ3K {
  margin-left: 16px;
}

.sHnBQVAtijwMVRANK5sg {
  border-bottom: 2px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/views/Search/SearchTabs/SearchTabs.module.css"],"names":[],"mappings":"AAGA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,qEAAqC;EACrC,YAAY;AACd","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple from colors;\n\n.navigation {\n  background-color: #f8f9fb;\n  border-bottom: 1px solid #e5e5e5;\n  width: 100%;\n}\n\n.container {\n  margin: auto;\n  max-width: 1440px;\n}\n\n.button {\n  color: #9c9c9c;\n  font-family: Roboto, sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  height: 48px;\n  padding: 0;\n}\n\n.button + .button {\n  margin-left: 16px;\n}\n\n.active {\n  border-bottom: 2px solid color-purple;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"navigation": `Rl1mcpRim63kFYCkLMTs`,
	"container": `JnLYtatuuYAqqO7EIXRW`,
	"button": `fodIIbkBmkR4tr8PdQ3K`,
	"active": `sHnBQVAtijwMVRANK5sg`
};
export default ___CSS_LOADER_EXPORT___;
