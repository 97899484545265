// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tHwkp42Qz7I4P1OJGA62 {
  column-gap: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
}

.Wd6rykEwxuaildNtuuSi {
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelVerificationFooter/TunnelVerificationFooter.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".footer {\n  column-gap: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 16px;\n}\n\n.next {\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `tHwkp42Qz7I4P1OJGA62`,
	"next": `Wd6rykEwxuaildNtuuSi`
};
export default ___CSS_LOADER_EXPORT___;
