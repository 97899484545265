import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ValidationCustomerCareProjectComment from './ValidationCustomerCareProjectComment';
import styles from './ValidationCustomerCareProject.module.css';
export default function ValidationCustomerCareProject(_a) {
    var user = _a.user, project = _a.project, projectName = _a.projectName;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs("button", { type: "button", onClick: function () { return setIsOpen(function (curr) { return !curr; }); }, className: styles.project, "data-testid": "customer-care-comments-".concat(project), children: [_jsxs("span", { children: [projectName, _jsx("span", { className: styles['project-id'], children: "#".concat(project) })] }), (isOpen)
                        ? _jsx(ExpandLess, {})
                        : _jsx(ExpandMore, {})] }), isOpen && (_jsx(ValidationCustomerCareProjectComment, { id: user }))] }));
}
