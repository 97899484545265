var downloadFile = function (file, name) {
    var url = window.URL.createObjectURL(new Blob(file));
    var link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export default downloadFile;
