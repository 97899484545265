// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Primary Color */

/* Accent Color */

/* Neutral color */

/* Feedback color */

/* Typography */

/* Border */

/* Forms */

/* Button */
`, "",{"version":3,"sources":["webpack://./src/components/library/style/colors.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAMlB,iBAAiB;;AAOjB,kBAAkB;;AAMlB,mBAAmB;;AAWnB,eAAe;;AAMf,WAAW;;AAGX,UAAU;;AAMV,WAAW","sourcesContent":["/* Primary Color */\n@value color-red: #DE330D;\n@value color-red-light: #F24C27;\n@value color-red-dark: #A32003;\n@value color-red-bg: #F8D7DA;\n\n/* Accent Color */\n@value color-purple: #4F67F3;\n@value color-purple-light: #7D8FF5;\n@value color-purple-dark: #404FA1;\n@value color-purple-bg: #F4F6FE;\n@value color-purple-gray: #f0f3f8;\n\n/* Neutral color */\n@value color-gray: #CCCCCC;\n@value color-gray-light: #EFEFEF;\n@value color-gray-dark: #72757E;\n@value color-black: #2C333E;\n\n/* Feedback color */\n@value color-success: #0EDC97;\n@value color-success-light: #D1ECF1;\n@value color-success-dark: #155724;\n@value color-warning: #FBC506;\n@value color-warning-light: #FFF3CD;\n@value color-warning-dark: #FBC506;\n@value color-error: #DE330D;\n@value color-error-light: #F8D7DA;\n@value color-error-dark: #DE330D;\n\n/* Typography */\n@value color-text: color-black;\n@value color-text-subtle: color-gray-dark;\n@value color-link: color-purple;\n@value color-link-visited: color-purple-dark;\n\n/* Border */\n@value color-border: color-gray;\n\n/* Forms */\n@value color-form-border: color-gray;\n@value color-form-focus: color-purple;\n@value color-form-error: color-error;\n@value color-form-success: color-success;\n\n/* Button */\n@value color-button-primary: color-red;\n@value color-button-primary-hover: color-red-dark;\n@value color-button-primary-focus: color-red-light;\n@value color-button-secondary: color-purple;\n@value color-button-secondary-hover: color-purple-dark;\n@value color-button-secondary-focus: color-purple-light;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color-red": `#DE330D`,
	"color-red-light": `#F24C27`,
	"color-red-dark": `#A32003`,
	"color-red-bg": `#F8D7DA`,
	"color-purple": `#4F67F3`,
	"color-purple-light": `#7D8FF5`,
	"color-purple-dark": `#404FA1`,
	"color-purple-bg": `#F4F6FE`,
	"color-purple-gray": `#f0f3f8`,
	"color-gray": `#CCCCCC`,
	"color-gray-light": `#EFEFEF`,
	"color-gray-dark": `#72757E`,
	"color-black": `#2C333E`,
	"color-success": `#0EDC97`,
	"color-success-light": `#D1ECF1`,
	"color-success-dark": `#155724`,
	"color-warning": `#FBC506`,
	"color-warning-light": `#FFF3CD`,
	"color-warning-dark": `#FBC506`,
	"color-error": `#DE330D`,
	"color-error-light": `#F8D7DA`,
	"color-error-dark": `#DE330D`,
	"color-text": `#2C333E`,
	"color-text-subtle": `#72757E`,
	"color-link": `#4F67F3`,
	"color-link-visited": `#404FA1`,
	"color-border": `#CCCCCC`,
	"color-form-border": `#CCCCCC`,
	"color-form-focus": `#4F67F3`,
	"color-form-error": `#DE330D`,
	"color-form-success": `#0EDC97`,
	"color-button-primary": `#DE330D`,
	"color-button-primary-hover": `#A32003`,
	"color-button-primary-focus": `#F24C27`,
	"color-button-secondary": `#4F67F3`,
	"color-button-secondary-hover": `#404FA1`,
	"color-button-secondary-focus": `#7D8FF5`
};
export default ___CSS_LOADER_EXPORT___;
