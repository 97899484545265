// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EZrZdYnHCuBnBiXGdokS {
  align-items: center;
  background-color: #f3f3f3;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  transition: 0.3s;
}

.Kh78StZHQHYH2b7APHH_ {
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.GWbVBSXoTwcd16RERW4r {
  font-size: 1rem !important;
}

.lpiT_KqjvWNFiIiG55yh {
  margin-right: 0.25rem;
}

.lpiT_KqjvWNFiIiG55yh,
.js3UwzewvFhuyNUxWftW {
  font-size: 0.875rem;
}

.lpiT_KqjvWNFiIiG55yh,
.Kh78StZHQHYH2b7APHH_ {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-weight: 400;
}

.js3UwzewvFhuyNUxWftW {
  font-weight: 500;
}

.EZrZdYnHCuBnBiXGdokS:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.EZrZdYnHCuBnBiXGdokS:hover,
.Kh78StZHQHYH2b7APHH_:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/FiltersSelected/FiltersSelected.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,8DAA4B;EAC5B,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,mDAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,8DAAiC;EACjC,8DAA8B;AAChC;;AAEA;;EAEE,eAAe;AACjB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray, color-text-subtle, color-purple, color-purple-bg from colors;\n\n.selected {\n  align-items: center;\n  background-color: #f3f3f3;\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  padding: 0.25rem 0.5rem;\n  transition: 0.3s;\n}\n\n.clear {\n  align-items: center;\n  background-color: transparent;\n  border: 0;\n  display: flex;\n  justify-content: center;\n  margin: 0;\n  padding: 0;\n}\n\n.clear-icon {\n  font-size: 1rem !important;\n}\n\n.filter {\n  margin-right: 0.25rem;\n}\n\n.filter,\n.selection {\n  font-size: 0.875rem;\n}\n\n.filter,\n.clear {\n  color: color-text-subtle;\n  font-weight: 400;\n}\n\n.selection {\n  font-weight: 500;\n}\n\n.selected:hover {\n  background-color: color-purple-bg;\n  border: 1px solid color-purple;\n}\n\n.selected:hover,\n.clear:hover {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"selected": `EZrZdYnHCuBnBiXGdokS`,
	"clear": `Kh78StZHQHYH2b7APHH_`,
	"clear-icon": `GWbVBSXoTwcd16RERW4r`,
	"filter": `lpiT_KqjvWNFiIiG55yh`,
	"selection": `js3UwzewvFhuyNUxWftW`
};
export default ___CSS_LOADER_EXPORT___;
