var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from '../axiosClient';
export var getAllUsers = function (source, page, resultsPerPage, filters) { return axios.get('/user', {
    params: __assign(__assign({}, filters), { page: page, page_size: resultsPerPage }),
    cancelToken: source.token,
}); };
export var exportUsers = function (filters) { return axios.get('/user/export', { params: __assign({}, filters) }); };
export var getUserByIss = function (iss) { return axios.get("/user/".concat(iss)); };
export var getUserFileByIss = function (source, iss) { return axios.get('/file_user', {
    params: { iss_user: iss },
    cancelToken: source.token,
}); };
export var getUserAnswersByIss = function (source, iss) { return axios.get("/user_answer/".concat(iss), {
    cancelToken: source.token,
}); };
export var updateUserAnswersByIss = function (iss, data) { return axios.put("/user_answer/".concat(iss), data); };
export var updateUserByIss = function (iss, data) { return axios.put("/user/".concat(iss), data); };
export var getUserIdentityFileByIss = function (source, iss) { return axios.get("/identity_card_information/".concat(iss), { cancelToken: source.token }); };
export var getUserProofOfAddress = function (source, iss) { return axios.get("/proof_of_address_information/".concat(iss), { cancelToken: source.token }); };
export var updateUserIdentityFileByIss = function (iss, data) { return axios.put("/identity_card_information/".concat(iss), data); };
export var updateUpdateProofOfAddressByIss = function (iss, data) { return axios.put("/proof_of_address_information/".concat(iss), data); };
export var uploadFileUser = function (data) { return axios.post('/file_user', data); };
export var getWorldcheckDocument = function (iss, source) { return axios.get('/file_user/worldcheck', { params: { iss_user: iss }, cancelToken: source.token }); };
export var getRiskByIss = function (iss, source) { return axios.get("/risk/".concat(iss), { cancelToken: source.token }); };
export var createRiskByIss = function (iss, data) { return axios.post('/risk', __assign(__assign({}, data), { iss_user: iss })); };
export var updateRiskByIss = function (iss, data) { return axios.put("/risk/".concat(iss), __assign(__assign({}, data), { iss_user: iss })); };
export var updateWorldCheckByIss = function (iss, data) { return axios.put("/world_check/".concat(iss), data); };
export var createWorldCheckByIss = function (iss, data) { return axios.post('/world_check', __assign(__assign({}, data), { iss_user: iss })); };
