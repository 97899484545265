import { keysToCamel } from 'utils/converter';
export var isCall = function (value) { return !!value
    && typeof value === 'object'
    && 'duration' in value
    && 'fromNumber' in value
    && 'toNumber' in value
    && 'fullName' in value
    && 'record' in value
    && 'userIss' in value; };
export var toCalls = function (value) {
    var newCalls = keysToCamel(value);
    if (!Array.isArray(newCalls)) {
        return [];
    }
    if (!newCalls.every(isCall)) {
        return [];
    }
    return newCalls;
};
