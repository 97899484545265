var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { keysToCamel, keysToSnake } from 'utils/converter';
export var RiskLevel;
(function (RiskLevel) {
    RiskLevel["LOW"] = "low";
    RiskLevel["MEDIUM"] = "medium";
    RiskLevel["HIGH"] = "high";
})(RiskLevel || (RiskLevel = {}));
/*
 * issUser has been added to allow the use of
 * userAdaptor on the response of /lead/rejected -> lead
 */
export var isBUser = function (data) { return !!data && typeof data === 'object' && ('iss' in data || 'issUser' in data); };
export var areBUsers = function (data) { return !!data
    && Array.isArray(data)
    && data.every(isBUser); };
export function usersAdaptor(data) {
    if (!areBUsers(data))
        return [];
    return data.map(function (user) { return (__assign(__assign({}, keysToCamel(user)), { selected: false })); });
}
export function userAdaptor(data) {
    if (!isBUser(data)) {
        throw new Error('Invalid user data');
    }
    else {
        return keysToCamel(data);
    }
}
export function userBackendAdaptor(data) {
    var newUser = keysToSnake(data);
    var EDITABLE_BOOLEAN_FIELDS = ['pep', 'us_resident'];
    EDITABLE_BOOLEAN_FIELDS.forEach(function (field) {
        if (typeof newUser[field] !== 'boolean') {
            newUser[field] = (newUser[field] === 'true');
        }
    });
    return newUser;
}
var isRisk = function (data) { return !!data && typeof data === 'object' && 'level' in data && 'reason' in data; };
export var toRisk = function (data) {
    var initialRisk = {
        level: RiskLevel.LOW,
        reason: 'N/A',
    };
    if (isRisk(data)) {
        return __assign(__assign({}, initialRisk), data);
    }
    return initialRisk;
};
