import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAutoDeleteTime, getToasts } from 'services/store/features/toasts/selectors';
import { removeToast } from 'services/store/features/toasts/actions';
import Toasts from './Toasts';
var mapStateToProps = function (state) { return ({
    list: getToasts(state),
    autoDeleteTime: getAutoDeleteTime(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    actions: bindActionCreators({ removeToast: removeToast }, dispatch),
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
