import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import styles from './TablePagination.module.css';
export default function TablePagination(_a) {
    var page = _a.page, resultsPerPage = _a.resultsPerPage, total = _a.total, onNext = _a.onNext, onPrevious = _a.onPrevious, onPageClick = _a.onPageClick, onTotalRowsSelect = _a.onTotalRowsSelect;
    var maxPage = function () { return Math.ceil(total / resultsPerPage); };
    var handleResultPerPage = function (e) { return onTotalRowsSelect(Number.parseInt(e.target.value, 10)); };
    return (_jsxs("div", { className: styles.pagination, children: [_jsx("p", { className: styles.results, children: "Showing ".concat(((page - 1) * resultsPerPage) + 1, "-").concat((page * resultsPerPage < total) ? page * resultsPerPage : total, " of ").concat(total, " customers") }), _jsxs("select", { className: styles.selector, onChange: handleResultPerPage, value: resultsPerPage, children: [_jsx("option", { children: "10" }), _jsx("option", { children: "20" }), _jsx("option", { children: "50" })] }), _jsxs("div", { className: styles.navigation, children: [_jsx("button", { type: "button", className: styles.button, onClick: onPrevious, disabled: page - 1 <= 0, children: _jsx(KeyboardArrowLeft, {}) }), (page - 1 > 0)
                        && (_jsx("button", { type: "button", className: styles.button, onClick: function () { return onPageClick(page - 1); }, children: page - 1 })), _jsx("button", { type: "button", className: "".concat(styles.button, " ").concat(styles.active), children: page }), (page + 1 <= maxPage())
                        && (_jsx("button", { type: "button", className: styles.button, onClick: function () { return onPageClick(page + 1); }, children: page + 1 })), (page + 2 <= maxPage())
                        && (_jsxs(_Fragment, { children: [_jsx("p", { children: "..." }), _jsx("button", { type: "button", className: styles.button, onClick: function () { return onPageClick(maxPage()); }, children: maxPage() })] })), _jsx("button", { type: "button", className: styles.button, onClick: onNext, disabled: page + 1 > maxPage(), children: _jsx(KeyboardArrowRight, {}) })] })] }));
}
