// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MkyFImsXDXQLhbVHdYJH {
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-bottom: 16px;
}

.XZnxgCTQrGZDFMXuDOKD {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.evVk2romhdrwEtCXyGk0 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.RWAVJB7SFRT4s8BrmlL7 {
  font-size: 16px !important;
  margin-right: 4px;
}

.a2ZtzKix2z_b58NLg4U6 {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}

.UDAbPAjQAU1i79eTHyhX {
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataViewer/DataViewerLayout/DataViewerLayout.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,gCAAgC;EAChC,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-gray, color-purple from colors;\n\n.header {\n  align-items: center;\n  border-bottom: 1px solid #e5e5e5;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 16px;\n  margin-top: 16px;\n  padding-bottom: 16px;\n}\n\n.row {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n}\n\n.button {\n  color: color-text-subtle;\n}\n\n.icon {\n  font-size: 16px !important;\n  margin-right: 4px;\n}\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  margin: 0;\n}\n\n.expand {\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"header": `MkyFImsXDXQLhbVHdYJH`,
	"row": `XZnxgCTQrGZDFMXuDOKD`,
	"button": `evVk2romhdrwEtCXyGk0`,
	"icon": `RWAVJB7SFRT4s8BrmlL7`,
	"title": `a2ZtzKix2z_b58NLg4U6`,
	"expand": `UDAbPAjQAU1i79eTHyhX`
};
export default ___CSS_LOADER_EXPORT___;
