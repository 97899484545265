var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default function flattenObject(obj, root) {
    if (typeof obj === 'object'
        && !Array.isArray(obj)
        && obj !== null) {
        var keys = Object.keys(obj);
        return keys.reduce(function (acc, curr) {
            var _a;
            if (typeof obj[curr] === 'object'
                && !Array.isArray(obj[curr])
                && obj[curr] !== null) {
                return __assign(__assign({}, acc), flattenObject(obj[curr], "".concat((root !== '' ? "".concat(root, ".") : '')).concat(curr)));
            }
            return __assign(__assign({}, acc), (_a = {}, _a["".concat((root !== '' ? "".concat(root, ".") : '')).concat(curr)] = obj[curr], _a));
        }, {});
    }
    return {};
}
export var getObjectFromSubkeys = function (subKeys, obj, data, root) {
    var _a, _b;
    if (subKeys.length === 1) {
        return _a = {},
            _a[subKeys[0]] = data[root],
            _a;
    }
    return _b = {},
        _b[subKeys[0]] = __assign(__assign({}, obj[subKeys[0]]), getObjectFromSubkeys(subKeys.slice(1), obj[subKeys[0]], data, root)),
        _b;
};
export function unflattenObject(obj) {
    if (typeof obj === 'object'
        && !Array.isArray(obj)
        && obj !== null) {
        var keys = Object.keys(obj);
        return keys.reduce(function (acc, curr) {
            var _a;
            var subKeys = curr.split('.');
            if (subKeys.length > 1) {
                var newObj = getObjectFromSubkeys(subKeys, acc, obj, curr);
                return __assign(__assign({}, acc), newObj);
            }
            return __assign(__assign({}, acc), (_a = {}, _a[curr] = obj[curr], _a));
        }, {});
    }
    return {};
}
