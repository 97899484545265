// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bpDyGCv3coqQ2QpN014w {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.vJC4VuKKonLp1vnCyDp8 {
  font-weight: 500;
}

.U1YH1Q7coJQnpS3CfukN {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  transition: 0.3s;
}

.U1YH1Q7coJQnpS3CfukN .PnvGyhWtxlPNdn7u7HoB {
  font-size: 16px;
}

.U1YH1Q7coJQnpS3CfukN:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.z9TnCfDTPZllWDmoSXok {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  font-size: 16px;
  transition: 0.3s;
}

.z9TnCfDTPZllWDmoSXok:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
}

.c2tdKV2NSJq4FTWuRByT {
  margin: 4px 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationSummary/ValidationSummaryValue/ValidationSummaryValue.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mDAAwB;EACxB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,mDAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,eAAe;AACjB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple, color-purple-dark from colors;\n\n.row {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n}\n\n.label {\n  font-weight: 500;\n}\n\n.edit {\n  color: color-text-subtle;\n  transition: 0.3s;\n}\n\n.edit .icon {\n  font-size: 16px;\n}\n\n.edit:hover {\n  color: color-purple;\n}\n\n.save {\n  color: color-purple;\n  font-size: 16px;\n  transition: 0.3s;\n}\n\n.save:hover {\n  color: color-purple-dark;\n}\n\n.value {\n  margin: 4px 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]}`,
	"row": `bpDyGCv3coqQ2QpN014w`,
	"label": `vJC4VuKKonLp1vnCyDp8`,
	"edit": `U1YH1Q7coJQnpS3CfukN`,
	"icon": `PnvGyhWtxlPNdn7u7HoB`,
	"save": `z9TnCfDTPZllWDmoSXok`,
	"value": `c2tdKV2NSJq4FTWuRByT`
};
export default ___CSS_LOADER_EXPORT___;
