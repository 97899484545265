var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAPI from 'hooks/useAPI';
import { getUserAnswersByIss, updateUserAnswersByIss } from 'services/network/api/users';
import * as statuses from 'services/store/states';
import { addToast } from 'services/store/features/toasts/actions';
import ValidationSummaryValue from '../ValidationSummaryValue';
import styles from './ValidationSummaryClient.module.css';
import { logError } from 'utils/logs';
export default function ValidationSummaryClient(_a) {
    var _this = this;
    var iss = _a.iss;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var dispatch = useDispatch();
    var _c = useAPI({
        request: function (source) { return getUserAnswersByIss(source, iss); },
        adaptor: function (currData) {
            var isUserAnswers = function (value) { return !!value && typeof value === 'object' && 'type' in value; };
            if (isUserAnswers(currData)) {
                return { type: currData === null || currData === void 0 ? void 0 : currData.type };
            }
            return { type: '' };
        },
    }), request = _c.status, data = _c.data, setData = _c.setData;
    var save = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus(statuses.LOADING);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateUserAnswersByIss(iss, data)];
                case 2:
                    _a.sent();
                    setStatus(statuses.IDLE);
                    dispatch(addToast({
                        type: 'success',
                        title: 'Client category updated successfully',
                        description: '',
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setStatus(statuses.IDLE);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Client category update failed. Please, try again',
                        description: "".concat(err_1),
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(ValidationSummaryValue, { label: "Client category", value: (status === statuses.LOADING || request === statuses.LOADING)
            ? (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black" }), _jsx("span", { children: "Updating..." })] })) : (_jsxs("p", { className: styles.type, "data-testid": "client-type", children: [_jsx("span", { children: "Type :" }), (data === null || data === void 0 ? void 0 : data.type) || 'Not specified'] })), name: "client-category", onSave: save, children: _jsx("form", { className: styles.form, children: _jsxs("label", { htmlFor: "client-category", children: ["Type", _jsxs("select", { className: styles.select, id: "client-category", value: (data === null || data === void 0 ? void 0 : data.type) || 'retail', onChange: function (e) { return setData({ type: e.target.value }); }, children: [_jsx("option", { value: "retail", children: "Retail" }), _jsx("option", { value: "professional", children: "Professional" }), _jsx("option", { value: "eligible counterparty", children: "Eligible counterparty" })] })] }) }) }));
}
