var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from '../axiosClient';
export var getNextProfile = function (source) { return axios.get('/lead/rejected', { cancelToken: source.token }); };
export var updateProfile = function (id, data) { return axios.post('/lead/rejected', __assign(__assign({}, data), { iss_user: id })); };
export var getActions = function (source) { return axios.get('/care/actions', { cancelToken: source.token }); };
