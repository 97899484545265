import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from 'components/library/Button';
import { toHuman } from 'utils/converter';
import styles from './Comment.module.css';
import replyStyles from '../Reply/Reply.module.css';
export default function Comment(_a) {
    var comment = _a.comment, onEdit = _a.onEdit, onDelete = _a.onDelete, iss = _a.iss;
    var _b = useState(false), isEdit = _b[0], setIsEdit = _b[1];
    var _c = useState(comment.text), editedComment = _c[0], setEditedComment = _c[1];
    var edit = function () { return setIsEdit(true); };
    var saveEdition = function () {
        if (comment.id) {
            onEdit(comment.id, editedComment);
            setIsEdit(false);
        }
    };
    var deleteComment = function () {
        if (comment.id) {
            onDelete(comment.id);
        }
    };
    var handleEdition = function (e) { return (setEditedComment(e.target.value)); };
    return (_jsxs("div", { className: styles.comment, "data-testid": comment.type, children: [_jsxs("div", { className: styles.content, children: [_jsxs("div", { className: styles.group, children: [_jsx("div", { className: styles.logo, children: comment.writer.name[0] }), _jsx("div", { children: _jsxs("p", { className: styles.author, children: [comment.writer.name, _jsx("span", { className: styles.visibility, children: comment.isVisibleByUser ? 'Visible by user' : ' AFS Only' }), (comment.type)
                                            && (_jsx("span", { className: styles.type, children: toHuman(comment.type) }))] }) })] }), _jsx("p", { className: styles.date, children: comment.date })] }), (isEdit)
                ? (_jsx("input", { type: "text", value: editedComment, onChange: handleEdition, className: "".concat(styles.document, " ").concat(replyStyles.input) }))
                : (_jsxs("p", { className: styles.text, children: [_jsx("span", { className: styles.bold, children: "".concat(comment.title, " : ") }), comment.text] })), ((comment.writer.iss.toString() === iss) && comment.id)
                && (_jsxs("div", { className: styles.tools, children: [_jsx(Button, { onClick: isEdit ? saveEdition : edit, className: styles.edit, variant: "ghost", children: (isEdit)
                                ? 'Save edit'
                                : 'Edit' }), _jsx("div", { className: styles.circle }), _jsx(Button, { onClick: deleteComment, className: styles.delete, variant: "ghost", type: "custom", children: "Delete" })] }))] }));
}
