var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import filterStyles from '../FiltersStatus/FiltersStatus.module.css';
export default function FilterCategory() {
    var _a, _b, _c, _d, _e;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var updateSelection = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                category: (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected.category || []), true), [e.target.value], false) : (selected.category || []).filter(function (value) { return value !== e.target.value; }),
            },
        });
    };
    return (_jsxs("div", { className: filterStyles.dropdown, children: [_jsxs("label", { htmlFor: "category-pep", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "category-pep", value: "pep", checked: ((_a = selected.category) === null || _a === void 0 ? void 0 : _a.includes('pep')) || false, onChange: updateSelection }), "Politically Exposed Person"] }), _jsxs("label", { htmlFor: "category-us", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "category-us", value: "us_resident", checked: ((_b = selected.category) === null || _b === void 0 ? void 0 : _b.includes('us_resident')) || false, onChange: updateSelection }), "US Reportable Person"] }), _jsxs("label", { htmlFor: "category-retail", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "category-retail", value: "retail", checked: ((_c = selected.category) === null || _c === void 0 ? void 0 : _c.includes('retail')) || false, onChange: updateSelection }), "Retail"] }), _jsxs("label", { htmlFor: "professional", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "category-professional", value: "professional", checked: ((_d = selected.category) === null || _d === void 0 ? void 0 : _d.includes('professional')) || false, onChange: updateSelection }), "Professional"] }), _jsxs("label", { htmlFor: "category-eligible-counterparty", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "category-eligible-counterparty", value: "eligible counterparty", checked: ((_e = selected.category) === null || _e === void 0 ? void 0 : _e.includes('eligible counterparty')) || false, onChange: updateSelection }), "Eligible counterparty"] })] }));
}
