var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Error } from '@mui/icons-material';
import Button from 'components/library/Button';
import { uploadFileUser } from 'services/network/api/users';
import * as statuses from 'services/store/states';
import styles from './CustomerDocumentAdd.module.css';
import { logError } from 'utils/logs';
export default function CustomerDocumentAdd(_a) {
    var _this = this;
    var actions = _a.actions;
    var _b = useState(false), isModalOpen = _b[0], setIsModalOpen = _b[1];
    var _c = useState(statuses.IDLE), status = _c[0], setStatus = _c[1];
    var iss = useParams().iss;
    var upload = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var file, type, formData, err_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    e.preventDefault();
                    if (!iss)
                        return [2 /*return*/];
                    file = (_a = e.target.file) === null || _a === void 0 ? void 0 : _a.files;
                    type = (_b = e.target.type) === null || _b === void 0 ? void 0 : _b.value;
                    if (!(file[0] && type)) return [3 /*break*/, 4];
                    setStatus(statuses.LOADING);
                    formData = new FormData();
                    formData.append('file', file[0]);
                    formData.append('name', (_c = file[0]) === null || _c === void 0 ? void 0 : _c.name);
                    formData.append('type', type);
                    formData.append('iss_user', iss);
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, uploadFileUser(formData)];
                case 2:
                    _d.sent();
                    if (type === 'home_certificate')
                        actions.fetchProofOfAddress();
                    if (type === 'id_card_recto' || type === 'id_card_verso' || type === 'passport')
                        actions.fetchIdentity();
                    setStatus(statuses.SUCCESS);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _d.sent();
                    logError(err_1);
                    setStatus(statuses.FAIL);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { className: styles['add-document'], onClick: function () {
                    setIsModalOpen(true);
                    setStatus(statuses.IDLE);
                }, children: "Add document" }), isModalOpen
                && (status !== statuses.SUCCESS)
                && (_jsx("div", { className: styles.background, children: _jsxs("div", { className: styles.modal, children: [_jsx("h1", { className: styles.title, children: "Add document" }), (status === statuses.FAIL)
                                && (_jsxs("p", { className: "bounce-in ".concat(styles.error), children: [_jsx(Error, { className: styles['error-icon'] }), "An error occurred while uploading the file, please try again."] })), _jsxs("form", { onSubmit: upload, className: styles.form, children: [_jsxs("label", { htmlFor: "file", children: ["File", _jsx("input", { type: "file", id: "file" })] }), _jsxs("label", { htmlFor: "type", children: ["Type", _jsxs("select", { id: "type", children: [_jsx("option", { value: "home_certificate", children: "Home certificate" }), _jsx("option", { value: "id_card_recto", children: "Identity card - recto" }), _jsx("option", { value: "id_card_verso", children: "Identity card - verso" }), _jsx("option", { value: "passport", children: "Passport" }), _jsx("option", { value: "worldcheck", children: "Worldcheck" })] })] }), _jsxs("div", { className: styles.row, children: [_jsx(Button, { variant: "ghost", onClick: function () { return setIsModalOpen(false); }, disabled: status === statuses.LOADING, children: "Cancel" }), _jsx(Button, { buttonType: "submit", disabled: status === statuses.LOADING, children: (status === statuses.LOADING)
                                                    ? _jsx("span", { className: "spinner" })
                                                    : 'Upload' })] })] })] }) })), isModalOpen
                && (status === statuses.SUCCESS)
                && (_jsx("div", { className: styles.background, children: _jsxs("div", { className: "".concat(styles.modal, " ").concat(styles.success), children: [_jsx("div", { className: styles.icon, children: "\u2714\uFE0F" }), _jsx("h1", { className: styles.title, children: "Document added" }), _jsx(Button, { onClick: function () { return setIsModalOpen(false); }, children: "Close" })] }) }))] }));
}
