import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TunnelLayoutBreadcrumb from './TunnelLayoutBreadcrumb';
import TunnelLayoutHeader from './TunnelLayoutHeader';
import styles from './TunnelLayout.module.css';
export default function TunnelLayout(_a) {
    var data = _a.data, step = _a.step, steps = _a.steps, children = _a.children;
    return (_jsxs("section", { className: styles.section, children: [(data.type === 'entity' && data.entity)
                && (_jsx(TunnelLayoutHeader, { name: data.entity.general.legalName
                        || data.entity.general.societyName
                        || "".concat(data.entity.user.firstName, " ").concat(data.entity.user.lastName), type: "Legal entity", date: data.entity.user.acceptConditions, iss: data.entity.user.iss, link: "/search/entity/".concat(data.entity.general.siren) })), (data.type === 'user' && data.user)
                && (_jsx(TunnelLayoutHeader, { name: "".concat(data.user.firstName, " ").concat(data.user.lastName), type: "Individual investor", date: data.user.acceptConditions, iss: data.user.iss, link: "/search/customer/".concat(data.user.iss) })), _jsxs("main", { className: styles.main, children: [_jsx(TunnelLayoutBreadcrumb, { steps: steps, currentStep: step }), children] })] }));
}
