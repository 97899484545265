// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container */

.OrbXaC7DzKa4gfw9YkAg {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

/* Information */

.YOP_YIHHQicV35mYst3i {
  font-weight: 500;
  margin: 0;
}

.vUJEEggbaT96yYlX5OW7 {
  margin-top: 4px;
}

/* Application status */

.RA03SYcI6uhcgjfPPAbM {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  height: 42px;
  margin-top: 4px;
  min-width: 200px;
  padding-left: 4px;
  width: 100%;
}

.RA03SYcI6uhcgjfPPAbM:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

/* Activities */

.mjrAeXC2rPBcFAV4L3P0 {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationSummary/ValidationSummary.module.css"],"names":[],"mappings":"AAGA,cAAc;;AAEd;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA,gBAAgB;;AAEhB;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA,uBAAuB;;AAEvB;EACE,yBAAyB;EACzB,kBAAkB;EAClB,+BAA+B;EAC/B,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA,eAAe;;AAEf;EACE,qBAAqB;AACvB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple from colors;\n\n/* Container */\n\n.summary {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding-top: 1rem;\n}\n\n/* Information */\n\n.label {\n  font-weight: 500;\n  margin: 0;\n}\n\n.value {\n  margin-top: 4px;\n}\n\n/* Application status */\n\n.status-input {\n  border: 1px solid #e5e5e5;\n  border-radius: 5px;\n  font-family: Roboto, sans-serif;\n  height: 42px;\n  margin-top: 4px;\n  min-width: 200px;\n  padding-left: 4px;\n  width: 100%;\n}\n\n.status-input:hover {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n/* Activities */\n\n.activity {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"summary": `OrbXaC7DzKa4gfw9YkAg`,
	"label": `YOP_YIHHQicV35mYst3i`,
	"value": `vUJEEggbaT96yYlX5OW7`,
	"status-input": `RA03SYcI6uhcgjfPPAbM`,
	"activity": `mjrAeXC2rPBcFAV4L3P0`
};
export default ___CSS_LOADER_EXPORT___;
