import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { FilterAlt } from '@mui/icons-material';
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import useOutsideClickAlert from 'hooks/useOutsideClick';
import FiltersSelected from './FiltersSelected';
import FiltersOptions from './FiltersOptions';
import Filter from './Filter';
import styles from './Filters.module.css';
export default function Filters(_a) {
    var children = _a.children;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(null), selected = _c[0], setSelected = _c[1];
    var filtersSelection = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var ref = React.useRef(null);
    useOutsideClickAlert(ref, function () { return setIsOpen(false); });
    var getOptions = function () {
        if (!children)
            return null;
        var options = children.find(function (child) { return (child.type === Filter)
            && (child.props.name === selected); });
        return options || null;
    };
    var getFilters = function () {
        if (!children)
            return [];
        return children
            .filter(function (child) { return child.type === Filter; })
            .map(function (child) { return ({ name: child.props.name, icon: child.props.icon }); });
    };
    var clearAll = function () {
        if (!dispatch)
            return;
        dispatch({ type: 'CLEAR' });
    };
    var showClearAll = filtersSelection
        && (Object.values(filtersSelection)
            .filter(function (activeFilters) { return (activeFilters.length > 0); }).length > 0);
    return (_jsxs("div", { className: styles['filter-group'], ref: ref, children: [filtersSelection && Object.entries(filtersSelection).map(function (_a) {
                var name = _a[0], selection = _a[1];
                return (_jsx(FiltersSelected, { label: name, selections: selection, onOpen: function () {
                        setSelected(name);
                        setIsOpen(true);
                    } }, name));
            }), _jsx("button", { type: "button", "aria-label": "Select filters", className: styles.button, onClick: function () { return setIsOpen(!isOpen); }, children: _jsx(FilterAlt, { className: styles.icon }) }), showClearAll
                && (_jsx("button", { type: "button", "aria-label": "Clear filters", className: styles.clear, onClick: clearAll, "data-testid": "filters-clear", children: "Clear filters" })), isOpen
                && (_jsxs("div", { className: styles.dropdown, children: [_jsx(FiltersOptions, { filters: getFilters(), selected: selected || '', onChange: setSelected }), selected && getOptions()] }))] }));
}
