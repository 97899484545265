// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mCKhV5q9_R5Yb1MjEhgD {
  background: white;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  box-shadow: 0 2px 1px rgb(0 0 0 / 10%);
  width: max-content;
}

.vPBdncEratAW1fN79UHj {
  border-bottom: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.vPBdncEratAW1fN79UHj p {
  margin: 0;
}

.hiIq7AsAT_FvVbWIR_Mf {
  align-items: center;
  background: white;
  border: 0;
  display: flex;
  flex-direction: row;
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  text-transform: capitalize;
  transition: 0.3s;
  width: 100%;
}

.hiIq7AsAT_FvVbWIR_Mf:hover,
.q3l6RDyFuY5x1KU5whpr {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  cursor: pointer;
}

.hiIq7AsAT_FvVbWIR_Mf svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 1rem !important;
  transition: 0.3s;
}

.q3l6RDyFuY5x1KU5whpr svg,
.hiIq7AsAT_FvVbWIR_Mf:hover svg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/FiltersOptions/FiltersOptions.module.css"],"names":[],"mappings":"AAGA;EACE,iBAAiB;EACjB,8DAA4B;EAC5B,sBAAsB;EACtB,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,qEAAmC;EACnC,+BAA+B;EAC/B,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,+BAA+B;EAC/B,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,0BAA0B;EAC1B,gBAAgB;EAChB,WAAW;AACb;;AAEA;;EAEE,8DAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,mDAAwB;EACxB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;;EAEE,mDAAmB;AACrB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray, color-text-subtle, color-purple, color-purple-bg from colors;\n\n.filters {\n  background: white;\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  box-shadow: 0 2px 1px rgb(0 0 0 / 10%);\n  width: max-content;\n}\n\n.filters-heading {\n  border-bottom: 1px solid color-gray;\n  font-family: Roboto, sans-serif;\n  font-size: 0.9rem;\n  font-weight: 500;\n  padding: 0.5rem 1rem;\n}\n\n.filters-heading p {\n  margin: 0;\n}\n\n.option {\n  align-items: center;\n  background: white;\n  border: 0;\n  display: flex;\n  flex-direction: row;\n  font-family: Roboto, sans-serif;\n  font-size: 0.875rem;\n  font-weight: 400;\n  gap: 0.5rem;\n  padding: 0.5rem 1rem;\n  text-transform: capitalize;\n  transition: 0.3s;\n  width: 100%;\n}\n\n.option:hover,\n.option-selected {\n  background-color: color-purple-bg;\n  cursor: pointer;\n}\n\n.option svg {\n  color: color-text-subtle;\n  font-size: 1rem !important;\n  transition: 0.3s;\n}\n\n.option-selected svg,\n.option:hover svg {\n  color: color-purple;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"filters": `mCKhV5q9_R5Yb1MjEhgD`,
	"filters-heading": `vPBdncEratAW1fN79UHj`,
	"option": `hiIq7AsAT_FvVbWIR_Mf`,
	"option-selected": `q3l6RDyFuY5x1KU5whpr`
};
export default ___CSS_LOADER_EXPORT___;
