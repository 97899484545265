import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, useNavigate, Outlet, Navigate, } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import verifyToken from 'services/store/features/roles/actions';
import Search from 'views/Search';
import Customer from 'views/Customer';
import Navigation from 'components/Navigation';
import Entity from 'views/Entity';
import Tunnel from 'views/Tunnel';
import LoadingSpinner from 'components/library/LoadingSpinner';
import CareTunnel from 'views/CareTunnel';
import { ApmRoutes } from '@elastic/apm-rum-react';
var ROUTES = [
    {
        path: '/',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: function () { return _jsx(Navigate, { to: "/search", replace: true }); },
    },
    {
        path: '/search',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: Search,
    },
    {
        path: '/search/customer/:iss',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: Customer,
    },
    {
        path: '/search/entity/:siren/:iss',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: Entity,
    },
    {
        path: '/tunnel',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: Tunnel,
    },
    {
        path: '/care',
        auth: true,
        roles: [],
        authorizedRole: [],
        routes: [],
        component: CareTunnel,
    },
];
function ProtectedRoute(_a) {
    var status = _a.status, roles = _a.roles, authorizedRole = _a.authorizedRole;
    var _b = useState(false), showRoute = _b[0], setShowRoute = _b[1];
    var navigate = useNavigate();
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(verifyToken());
    }, []);
    useEffect(function () {
    }, [authorizedRole]);
    useEffect(function () {
        if (roles && roles.length > 0 && status === 'SUCCEEDED') {
            // eslint-disable-next-line no-restricted-syntax
            for (var _i = 0, roles_1 = roles; _i < roles_1.length; _i++) {
                var role = roles_1[_i];
                if (!authorizedRole.includes(role)) {
                    if (window.history.length === 1) {
                        document.location.assign("https://".concat((process.env.REACT_APP_DEBUG || window.location.origin.includes('dev-')) ? 'dev-' : '', "auth.ayomi.fr"));
                    }
                    else {
                        navigate(-1);
                    }
                }
                else {
                    setShowRoute(true);
                }
            }
        }
        else {
            setShowRoute(true);
        }
    }, [status]);
    if (status === 'SUCCEEDED' && showRoute) {
        return (_jsx(Outlet, {}));
    }
    return _jsx(LoadingSpinner, {});
}
var mapStateToProps = function (state) { return ({
    authorizedRole: state.roles.roles,
    status: state.roles.status,
}); };
var ConnectedProtectedRoute = connect(mapStateToProps)(ProtectedRoute);
export default function RouteConfig() {
    return (_jsx(Router, { children: _jsxs("div", { className: "main", children: [_jsx(Navigation, {}), _jsx("div", { className: "content", children: _jsx(ApmRoutes, { children: _jsx(Route, { element: _jsx(ConnectedProtectedRoute, { roles: [] }), children: ROUTES.map(function (route) { return (_jsx(Route, { path: route.path, element: _jsx(route.component, {}) }, route.path)); }) }) }) })] }) }));
}
