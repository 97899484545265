var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var getAppropriatenessResult = function (score, experienceTrading) {
    if (experienceTrading === 'none')
        return 'rejected';
    if (score >= 8)
        return 'pass';
    return 'low';
};
export var getSuitabilityResult = function (score, levelOfRisk) {
    if (levelOfRisk === 'none')
        return 'rejected';
    if (score >= 13)
        return 'pass';
    return 'low';
};
export var getAppropriatenessAnswers = function (data) {
    if (!data)
        return {};
    var experienceTrading = data.experienceTrading, goalInvestmentByAyomi = data.goalInvestmentByAyomi, paidUpShare = data.paidUpShare, tradingSizeByYear = data.tradingSizeByYear, transferableSecurities = data.transferableSecurities, appropriatenessScore = data.appropriatenessScore;
    return {
        experienceTrading: experienceTrading,
        goalInvestmentByAyomi: goalInvestmentByAyomi,
        paidUpShare: paidUpShare,
        tradingSizeByYear: tradingSizeByYear,
        transferableSecurities: transferableSecurities,
        appropriatenessScore: appropriatenessScore,
    };
};
export var getSuitabilityAnswers = function (data) {
    var purposeOfInvestment = data.purposeOfInvestment, experienceTrading = data.experienceTrading, goalInvestmentByAyomi = data.goalInvestmentByAyomi, paidUpShare = data.paidUpShare, tradingSizeByYear = data.tradingSizeByYear, transferableSecurities = data.transferableSecurities, created = data.created, updated = data.updated, issUser = data.issUser, appropriatenessScore = data.appropriatenessScore, suitability = __rest(data, ["purposeOfInvestment", "experienceTrading", "goalInvestmentByAyomi", "paidUpShare", "tradingSizeByYear", "transferableSecurities", "created", "updated", "issUser", "appropriatenessScore"]);
    return suitability;
};
