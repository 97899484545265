var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KYC_STATUS } from 'constants/kyc';
import { updateUserByIss } from 'services/network/api/users';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import { updateEntityBySiren } from 'services/network/api/entity';
import useAPI from 'hooks/useAPI';
import { getHistory, sendPipeAction } from 'services/network/api/pipe';
import { activityAdaptor, backendPipeActionAdaptor } from 'services/network/adaptor/pipe';
import TunnelSummaryActivities from 'views/Tunnel/TunnelVerification/TunnelSummary/TunnelSummaryActivities';
import Loading from '../../library/Loading';
import ValidationSummaryValue from './ValidationSummaryValue';
import ValidationSummaryRisk from './ValidationSummaryRisk';
import ValidationSummaryClient from './ValidationSummaryClient';
import ValidationSummaryWorldCheck from './ValidationSummaryWorldCheck';
import styles from './ValidationSummary.module.css';
import { logError } from 'utils/logs';
export default function ValidationSummary(_a) {
    var _this = this;
    var _b;
    var clientId = _a.clientId, entityId = _a.entityId, submission = _a.submission, kycStatus = _a.kycStatus, worldCheck = _a.worldCheck;
    var _c = useState({
        risk: 'low',
        reason: 'others',
        details: '',
        status: kycStatus,
        comment: '',
    }), summary = _c[0], setSummary = _c[1];
    var dispatch = useDispatch();
    var siren = useParams().siren;
    var _d = useAPI({
        request: function (source) { return getHistory(clientId, entityId, source); },
        adaptor: activityAdaptor,
    }), historyStatus = _d.status, data = _d.data;
    useEffect(function () {
        setSummary(function (state) { return (__assign(__assign({}, state), { status: kycStatus })); });
    }, [kycStatus]);
    var handleChange = function (e) { return setSummary(function (state) {
        var _a;
        return (__assign(__assign({}, state), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    }); };
    var onChange = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 7, , 8]);
                    if (!siren) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateEntityBySiren(siren, {
                            status: summary.status,
                            iss_user: Number.parseInt(clientId, 10),
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateUserByIss(clientId, { status: summary.status })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (!(summary.status === 'rejected' || summary.status === 'validated')) return [3 /*break*/, 6];
                    return [4 /*yield*/, sendPipeAction(backendPipeActionAdaptor({
                            action: (summary.status === 'validated') ? 'validated_kyc' : 'rejected_kyc',
                            idEntity: (siren && entityId) ? Number.parseInt(entityId, 10) : undefined,
                            iss: Number.parseInt(clientId, 10),
                        }))];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Impossible to update status',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: styles.summary, children: [_jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Client identification" }), _jsx("p", { className: styles.value, children: clientId })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Application submission date" }), _jsx("p", { className: styles.value, children: (submission === null || submission === void 0 ? void 0 : submission.toString()) || 'N/A' })] }), _jsxs("div", { children: [_jsx("p", { className: styles.label, children: "Application validation date" }), _jsx(Loading, { status: historyStatus, errorFallback: _jsx("p", { className: styles.value, children: "N/A" }), children: _jsx("p", { className: styles.value, children: ((_b = data === null || data === void 0 ? void 0 : data.find(function (currHistory) { return currHistory.action === 'validated_kyc'; })) === null || _b === void 0 ? void 0 : _b.updated)
                                || 'N/A' }) })] }), _jsx(ValidationSummaryRisk, { iss: clientId }), _jsx(ValidationSummaryClient, { iss: clientId }), _jsx(ValidationSummaryValue, { label: "Application status", value: KYC_STATUS[summary.status], name: "status", onSave: onChange, children: _jsx("select", { className: styles['status-input'], name: "status", onChange: handleChange, children: Object.keys(KYC_STATUS).map(function (status) { return (_jsx("option", { value: status, children: KYC_STATUS[status] }, status)); }) }) }), _jsx(ValidationSummaryWorldCheck, { clientId: clientId, worldCheck: worldCheck }), _jsx(TunnelSummaryActivities, { activities: data || [], className: "".concat(styles.label, " ").concat(styles.activity) })] }));
}
