import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'services/store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
var Init = function () {
    var _a = useState(process.env.NODE_ENV !== 'development'), isReady = _a[0], setIsReady = _a[1];
    useEffect(function () {
        if (process.env.NODE_ENV === 'development') {
            import('./mocks/browser')
                .then(function (_a) {
                var worker = _a.worker;
                return worker.start({
                    onUnhandledRequest: function (req, print) {
                        var excludedRoutes = [
                            'hotjar.com',
                            'youtube.com',
                            'hot-update',
                            'app.min.js',
                            'manifest.json',
                            'hotjar-560365.js',
                            '/api/media/public/landing_page',
                            '/log/',
                            '/rum/intake/v2/rum/events',
                            'content/injected',
                            '/static/media/',
                        ];
                        var isExcluded = excludedRoutes.some(function (route) {
                            return req.url.origin.includes(route) ||
                                req.url.pathname.includes(route) ||
                                req.url.pathname.endsWith('.png');
                        });
                        if (isExcluded) {
                            return;
                        }
                        print.warning();
                    },
                    serviceWorker: {
                        url: '/mockServiceWorker.js'
                    }
                });
            })
                .then(function () { return setIsReady(true); })
                .catch(function (err) {
                console.error('Failed to start the worker:', err);
                setIsReady(true); // or handle the error state differently
            });
        }
    }, []);
    if (!isReady) {
        return _jsx("div", { children: "Loading..." }); // Placeholder for loading state
    }
    return (_jsx(Provider, { store: store, children: _jsx(React.StrictMode, { children: _jsx(App, {}) }) }));
};
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(Init, {}));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
