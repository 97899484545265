// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PHp0sIn_gpQUHErQFMkM {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.I8WjirxCfDqHewLltAu3 {
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}

.ATTlTMAeiFfcL89Si2o3 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.zaRzmzzl2lTChaijE7Ca {
  font-style: italic;
  margin-top: 0;
}

.Rkm_KZFGEpRK3TNNOW6W {
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-weight: 400;
  height: 32px;
  margin-bottom: 8px;
  transition: 0.3s;
  width: 100%;
}

.Rkm_KZFGEpRK3TNNOW6W:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.M2x88FZ58UPZQFFCHLBt {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  margin-right: 8px;
}

.Rkm_KZFGEpRK3TNNOW6W:hover .M2x88FZ58UPZQFFCHLBt {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.VtAKTGbLOj2JcDSpEtPp .ATTlTMAeiFfcL89Si2o3 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}
`, "",{"version":3,"sources":["webpack://./src/views/Entity/EntityDocuments/EntityDocuments.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,8DAAiC;EACjC,mDAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,mDAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple, color-purple-bg, color-error from colors;\n\n.header {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.title {\n  font-weight: 500;\n  margin: 0;\n  text-transform: capitalize;\n}\n\n.icon {\n  color: color-text-subtle;\n}\n\n.empty {\n  font-style: italic;\n  margin-top: 0;\n}\n\n.file {\n  align-items: center;\n  border-radius: 5px;\n  display: flex;\n  flex-direction: row;\n  font-weight: 400;\n  height: 32px;\n  margin-bottom: 8px;\n  transition: 0.3s;\n  width: 100%;\n}\n\n.file:hover {\n  background-color: color-purple-bg;\n  color: color-purple;\n}\n\n.image {\n  color: color-text-subtle;\n  margin-right: 8px;\n}\n\n.file:hover .image {\n  color: color-purple;\n}\n\n.active .icon {\n  color: color-error;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"header": `PHp0sIn_gpQUHErQFMkM`,
	"title": `I8WjirxCfDqHewLltAu3`,
	"icon": `ATTlTMAeiFfcL89Si2o3`,
	"empty": `zaRzmzzl2lTChaijE7Ca`,
	"file": `Rkm_KZFGEpRK3TNNOW6W`,
	"image": `M2x88FZ58UPZQFFCHLBt`,
	"active": `VtAKTGbLOj2JcDSpEtPp`
};
export default ___CSS_LOADER_EXPORT___;
