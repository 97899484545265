// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x8VAakgHFEAy7YWGHxIT {
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.MtuVyqWFKgDCJqrlLNuP {
  margin: 0;
}

.NEkUDRdGjbc_E4gDvPHm {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  height: 42px;
  padding: 4px;
  transition: 0.3s;
}

.NEkUDRdGjbc_E4gDvPHm:hover,
.NEkUDRdGjbc_E4gDvPHm:focus {
  outline: none;
}

.NEkUDRdGjbc_E4gDvPHm:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

.HJIu8UtIgrUhV0vw4a1k {
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  font-weight: 400;
  height: 42px;
  justify-content: center;
  transition: 0.3s;
  width: 42px;
}

.aHO7EOiaftPas6DV_DqB {
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.HJIu8UtIgrUhV0vw4a1k:disabled {
  background-color: #e5e5e5;
  color: #bababa;
}

.HJIu8UtIgrUhV0vw4a1k:disabled:hover {
  border: none;
}

.HJIu8UtIgrUhV0vw4a1k.Wd_9AizuLQVbCsB25oJj {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/TablePagination/TablePagination.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,8DAA8B;EAC9B,YAAY;AACd","sourcesContent":["@value colors: \"../../library/style/colors.css\";\n@value color-purple from colors;\n\n.pagination {\n  align-items: center;\n  column-gap: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.results {\n  margin: 0;\n}\n\n.selector {\n  border: 1px solid #e5e5e5;\n  border-radius: 5px;\n  height: 42px;\n  padding: 4px;\n  transition: 0.3s;\n}\n\n.selector:hover,\n.selector:focus {\n  outline: none;\n}\n\n.selector:hover {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n.button {\n  align-items: center;\n  border: 1px solid #e5e5e5;\n  border-radius: 5px;\n  display: flex;\n  font-weight: 400;\n  height: 42px;\n  justify-content: center;\n  transition: 0.3s;\n  width: 42px;\n}\n\n.navigation {\n  align-items: center;\n  column-gap: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.button:disabled {\n  background-color: #e5e5e5;\n  color: #bababa;\n}\n\n.button:disabled:hover {\n  border: none;\n}\n\n.button.active {\n  background-color: color-purple;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../../library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"pagination": `x8VAakgHFEAy7YWGHxIT`,
	"results": `MtuVyqWFKgDCJqrlLNuP`,
	"selector": `NEkUDRdGjbc_E4gDvPHm`,
	"button": `HJIu8UtIgrUhV0vw4a1k`,
	"navigation": `aHO7EOiaftPas6DV_DqB`,
	"active": `Wd_9AizuLQVbCsB25oJj`
};
export default ___CSS_LOADER_EXPORT___;
