export var selectComments = function (comments) { return ({
    closing: (comments === null || comments === void 0 ? void 0 : comments.closing) || '',
    cofi: (comments === null || comments === void 0 ? void 0 : comments.cofi) || '',
    legal: (comments === null || comments === void 0 ? void 0 : comments.legal) || '',
    market: (comments === null || comments === void 0 ? void 0 : comments.market) || '',
    ri: (comments === null || comments === void 0 ? void 0 : comments.ri) || '',
    sales: (comments === null || comments === void 0 ? void 0 : comments.sales) || '',
}); };
export var selectEndDate = function (dates) { return ({
    expectedEndDate: (dates === null || dates === void 0 ? void 0 : dates.expectedEndDate) || '',
    realEndDate: (dates === null || dates === void 0 ? void 0 : dates.realEndDate) || '',
}); };
