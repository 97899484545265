import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavLink } from 'react-router-dom';
import { Search, Support, VerifiedUser } from '@mui/icons-material';
import styles from './Navigation.module.css';
export default function Navigation() {
    var getLinkClass = function (_a) {
        var isActive = _a.isActive;
        if (isActive)
            return styles.active;
        return styles.link;
    };
    return (_jsx("div", { className: styles.container, children: _jsxs("nav", { className: "container ".concat(styles.navigation), children: [_jsx("p", { className: styles.logo, children: "AFS" }), _jsxs(NavLink, { to: "/search", className: getLinkClass, children: [_jsx(Search, { className: styles.icon }), "Search"] }), _jsxs(NavLink, { to: "/tunnel", className: getLinkClass, children: [_jsx(VerifiedUser, { className: styles.icon }), "Verification Tunnel"] }), _jsxs(NavLink, { to: "/care", className: getLinkClass, children: [_jsx(Support, { className: styles.icon }), "Customer Care"] })] }) }));
}
