// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BqxZ_u6bo5Sg8wBhYgtR {
  position: relative;
}

.ZrhUUHfigswEADUk2FMg {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  left: 0.5rem;
  position: absolute;
  top: 0.75rem;
}

.BqxZ_u6bo5Sg8wBhYgtR input {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  padding: 1rem 1rem 1rem 2.5rem;
  width: 100%;
}

.ubfmThcdG1qOBYeG2NMg {
  border-top: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  display: flex;
  flex-direction: column;
  max-height: 16rem;
  min-width: 10rem;
  overflow-y: scroll;
  padding-top: 0.5rem;
  width: 100%;
}

.O_FOjHSaucfphFq7ttFW {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 1rem;
  transition: 0.3s;
}

.rpx988vesqQ3nSFz0A9P {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: max-content;
}

.O_FOjHSaucfphFq7ttFW:hover {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  cursor: pointer;
}

.PwYZxtsGHDtpjjIu1J3g {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  text-align: center;
  white-space: nowrap;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/FilterCountry/FilterCountry.module.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,mDAAwB;EACxB,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,YAAY;EACZ,sBAAsB;EACtB,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,kEAAgC;EAChC,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,8DAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,mDAAwB;EACxB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple-bg, color-text-subtle, color-gray from colors;\n\n.searchbar {\n  position: relative;\n}\n\n.icon {\n  color: color-text-subtle;\n  left: 0.5rem;\n  position: absolute;\n  top: 0.75rem;\n}\n\n.searchbar input {\n  background-color: transparent;\n  border: none;\n  box-sizing: border-box;\n  display: flex;\n  padding: 1rem 1rem 1rem 2.5rem;\n  width: 100%;\n}\n\n.list {\n  border-top: 1px solid color-gray;\n  display: flex;\n  flex-direction: column;\n  max-height: 16rem;\n  min-width: 10rem;\n  overflow-y: scroll;\n  padding-top: 0.5rem;\n  width: 100%;\n}\n\n.country {\n  display: flex;\n  flex-direction: row;\n  gap: 1rem;\n  padding: 0.5rem 1rem;\n  transition: 0.3s;\n}\n\n.country-name {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  width: max-content;\n}\n\n.country:hover {\n  background-color: color-purple-bg;\n  cursor: pointer;\n}\n\n.no-result {\n  color: color-text-subtle;\n  font-size: 0.875rem;\n  padding: 0.5rem 1rem;\n  text-align: center;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"searchbar": `BqxZ_u6bo5Sg8wBhYgtR`,
	"icon": `ZrhUUHfigswEADUk2FMg`,
	"list": `ubfmThcdG1qOBYeG2NMg`,
	"country": `O_FOjHSaucfphFq7ttFW`,
	"country-name": `rpx988vesqQ3nSFz0A9P`,
	"no-result": `PwYZxtsGHDtpjjIu1J3g`
};
export default ___CSS_LOADER_EXPORT___;
