var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import filtersStyles from 'components/Filters/FiltersStatus/FiltersStatus.module.css';
import styles from './FilterTest.module.css';
export default function FilterTest(_a) {
    var _b, _c;
    var name = _a.name;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var updateSelection = function (e) {
        var _a;
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: (_a = {},
                _a[name] = (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected[name] || []), true), [e.target.value], false) : (selected[name] || []).filter(function (value) { return value !== e.target.value; }),
                _a),
        });
    };
    return (_jsxs("div", { className: filtersStyles.dropdown, children: [_jsxs("label", { htmlFor: "test-pass", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", name: "pass", value: "pass", id: "test-pass", checked: ((_b = selected[name]) === null || _b === void 0 ? void 0 : _b.includes('pass')) || false, onChange: updateSelection, "aria-labelledby": "pass" }), _jsx("span", { className: styles.pass, children: "PASS" })] }), _jsxs("label", { htmlFor: "test-fail", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", name: "fail", value: "fail", id: "test-fail", checked: ((_c = selected[name]) === null || _c === void 0 ? void 0 : _c.includes('fail')) || false, onChange: updateSelection, "aria-label": "fail" }), _jsx("span", { className: styles.fail, children: "FAIL" })] })] }));
}
