var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { useEffect, useState } from 'react';
import * as statuses from 'services/store/states';
import { userAdaptor } from 'services/network/adaptor/user';
import getErrorMessage from 'utils/error';
import { getUserByIss } from 'services/network/api/users';
import { getCommentsByQuery } from 'services/network/api/comments';
import { commentAdaptor } from 'services/network/adaptor/comment';
import { logError } from 'utils/logs';
export default function useUser(_a) {
    var _this = this;
    var iss = _a.iss;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var _c = useState(statuses.IDLE), statusComment = _c[0], setStatusComment = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var _e = useState(), user = _e[0], setUser = _e[1];
    var _f = useState([]), comments = _f[0], setComments = _f[1];
    useEffect(function () {
        var cancelToken = axios.CancelToken;
        var source = cancelToken.source();
        var fetchUserData = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus(statuses.LOADING);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getUserByIss(iss)];
                    case 2:
                        data = (_a.sent()).data;
                        setUser(userAdaptor(data));
                        setStatus(statuses.SUCCESS);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        logError(err_1);
                        setError(getErrorMessage(err_1));
                        setStatus(statuses.FAIL);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var fetchUserComment = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatusComment(statuses.LOADING);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, getCommentsByQuery({ iss: iss })];
                    case 2:
                        data = (_a.sent()).data;
                        setComments(data.map(function (comment) { return commentAdaptor(comment); }));
                        setStatusComment(statuses.SUCCESS);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        logError(err_2);
                        setStatusComment(statuses.FAIL);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (status === statuses.IDLE) {
            fetchUserData();
            fetchUserComment();
        }
        return function () { return source.cancel('axios request cancelled'); };
    }, [iss]);
    var getStatus = function () {
        if (status === statuses.IDLE && statusComment === statuses.IDLE)
            return statuses.IDLE;
        if (status === statuses.LOADING || statusComment === statuses.LOADING)
            return statuses.LOADING;
        if (status === statuses.FAIL || statusComment === statuses.FAIL)
            return statuses.FAIL;
        return statuses.SUCCESS;
    };
    return {
        status: getStatus(),
        user: user,
        comments: comments,
        error: error,
    };
}
