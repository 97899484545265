// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Fxm1JPmv_LxkPVDxAnUh {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f_mODPDr6zEs3qUBkAkC,
.lmFfmALS1vOfdFVwuK_E {
  width: 49%;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelVerificationEntity/TunnelVerificationEntity.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.document,\n.entity {\n  width: 49%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Fxm1JPmv_LxkPVDxAnUh`,
	"document": `f_mODPDr6zEs3qUBkAkC`,
	"entity": `lmFfmALS1vOfdFVwuK_E`
};
export default ___CSS_LOADER_EXPORT___;
