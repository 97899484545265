// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xwArEMObse4QlTmgxDJ4 {
  padding: 0;
}

._P4K52vxl26x2qWQY2XY {
  font-weight: 500;
  margin-right: 4px;
}

.GO9CFDlHw9hucuIcptNE,
.W38XRBzJiECaj3pgCQeS {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-light"]};
  border-radius: 4px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-dark"]};
  font-weight: 500;
  margin-left: 8px;
  padding: 2px 16px;
  text-transform: capitalize;
}

.vTgrBTotIYyMrfC9KTQN,
.HsX1upQqmYc0doC_3eK_ {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]};
  border-radius: 4px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]};
  font-weight: 500;
  margin-left: 8px;
  padding: 2px 16px;
  text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerTests/CustomerTests.module.css"],"names":[],"mappings":"AAGA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,8DAAqC;EACrC,kBAAkB;EAClB,mDAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;;EAEE,8DAAmC;EACnC,kBAAkB;EAClB,mDAAqB;EACrB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;AAC5B","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-success-light, color-success-dark, color-error-light, color-red-dark from colors;\n\n.show {\n  padding: 0;\n}\n\n.label {\n  font-weight: 500;\n  margin-right: 4px;\n}\n\n.pass,\n.low {\n  background-color: color-success-light;\n  border-radius: 4px;\n  color: color-success-dark;\n  font-weight: 500;\n  margin-left: 8px;\n  padding: 2px 16px;\n  text-transform: capitalize;\n}\n\n.fail,\n.rejected {\n  background-color: color-error-light;\n  border-radius: 4px;\n  color: color-red-dark;\n  font-weight: 500;\n  margin-left: 8px;\n  padding: 2px 16px;\n  text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-success-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-light"]}`,
	"color-success-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success-dark"]}`,
	"color-error-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]}`,
	"color-red-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red-dark"]}`,
	"show": `xwArEMObse4QlTmgxDJ4`,
	"label": `_P4K52vxl26x2qWQY2XY`,
	"pass": `GO9CFDlHw9hucuIcptNE`,
	"low": `W38XRBzJiECaj3pgCQeS`,
	"fail": `vTgrBTotIYyMrfC9KTQN`,
	"rejected": `HsX1upQqmYc0doC_3eK_`
};
export default ___CSS_LOADER_EXPORT___;
