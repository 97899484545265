var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Visibility } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import useAPI from 'hooks/useAPI';
import getErrorMessage from 'utils/error';
import * as statuses from 'services/store/states';
import { identityDocumentAdaptor, proofOfAddressAdaptor, toIdentityDocumentBackend, } from 'services/network/adaptor/documents';
import { getUserIdentityFileByIss, getUserProofOfAddress, updateUpdateProofOfAddressByIss, updateUserIdentityFileByIss, } from 'services/network/api/users';
import { getCommentsAsObject } from 'services/network/adaptor/comment';
import { addToast } from 'services/store/features/toasts/actions';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import DataViewerLabel from 'components/DataViewer/DataViewerLabel';
import Loading from 'components/library/Loading';
import Button from 'components/library/Button';
import CommentForm from 'components/CommentForm';
import ImgPlaceholder from 'components/library/ImgPlaceholder';
import fetchMedia from 'utils/fetchMedia';
import styles from './CustomerDocuments.module.css';
import CustomerDocumentAdd from './CustomerDocumentAdd';
import { logError } from 'utils/logs';
export default function CustomerDocuments(_a) {
    var iss = _a.iss, _b = _a.isExpandable, isExpandable = _b === void 0 ? true : _b, _c = _a.comments, comments = _c === void 0 ? [] : _c, handleCreate = _a.handleCreate;
    var _d = useState({ isOpen: false, field: '' }), _e = _d[0], isOpen = _e.isOpen, field = _e.field, setIsOpen = _d[1];
    var formattedComments = useMemo(function () { return getCommentsAsObject(comments); }, [comments]);
    var dispatch = useDispatch();
    var _f = useAPI({
        request: function (source) { return getUserIdentityFileByIss(source, iss); },
        adaptor: identityDocumentAdaptor,
    }), status = _f.status, identity = _f.data, setIdentity = _f.setData, fetchIdentity = _f.fetchData;
    var _g = useAPI({
        request: function (source) { return getUserProofOfAddress(source, iss); },
        adaptor: proofOfAddressAdaptor,
    }), proofOfAddressStatus = _g.status, proofOfAddress = _g.data, setProofOfAddress = _g.setData, fetchProofOfAddress = _g.fetchData;
    var handleComment = function (option, title, comment, visibility, commentStatus, type) {
        handleCreate(option, title, comment, visibility, commentStatus, type);
        setIsOpen({ isOpen: false, field: '' });
    };
    var updateUserDocument = function () {
        try {
            updateUserIdentityFileByIss(iss, toIdentityDocumentBackend(identity));
            updateUpdateProofOfAddressByIss(iss, proofOfAddress);
        }
        catch (err) {
            logError(err);
            dispatch(addToast({
                type: 'error',
                title: 'Impossible to save document information',
                description: getErrorMessage(err),
            }));
        }
    };
    var handleSave = function () {
        updateUserDocument();
        setIsOpen({ isOpen: false, field: '' });
    };
    var handleIdentityChange = function (e) {
        setIdentity(function (curr) {
            var _a;
            return (__assign(__assign({}, curr), (_a = {}, _a[e.target.name] = e.target.value, _a)));
        });
    };
    var handleProofOfAddressChange = function (e) {
        setProofOfAddress(function (curr) {
            var _a;
            return (__assign(__assign({}, curr), (_a = {}, _a[e.target.name] = e.target.value, _a)));
        });
    };
    var getPill = function () {
        var date = identity === null || identity === void 0 ? void 0 : identity.expirationDate;
        if (!date)
            return null;
        if (new Date(date) < new Date()) {
            return _jsx("p", { className: styles.error, children: "Expired" });
        }
        return null;
    };
    var getImagePlaceholder = function (type) {
        var _a;
        var document = (_a = identity === null || identity === void 0 ? void 0 : identity.files) === null || _a === void 0 ? void 0 : _a.find(function (doc) { return doc.type === type; });
        return (_jsx(ImgPlaceholder, { src: "".concat(document === null || document === void 0 ? void 0 : document.path, "/").concat(document === null || document === void 0 ? void 0 : document.name), alt: document === null || document === void 0 ? void 0 : document.type, className: styles.card }));
    };
    var openDocument = function (type) {
        var _a, _b;
        var document = (_b = (_a = ((type === 'home_certificate') ? proofOfAddress : identity)) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.find(function (doc) { return doc.type === type; });
        if (document) {
            fetchMedia(function (url) { return window.open(url); }, null, "/media".concat(document === null || document === void 0 ? void 0 : document.path, "/").concat(document === null || document === void 0 ? void 0 : document.name));
        }
    };
    var openIdRectoDocument = function () { return openDocument('id_card_recto'); };
    var openIdVersoDocument = function () { return openDocument('id_card_verso'); };
    var openPassportDocument = function () { return openDocument('passport'); };
    var openHomeCertificate = function () { return openDocument('home_certificate'); };
    var getIdCardRecto = function () {
        var _a, _b;
        var document = (_a = identity === null || identity === void 0 ? void 0 : identity.files) === null || _a === void 0 ? void 0 : _a.find(function (doc) { return (doc.type === 'id_card_recto'); });
        if (!document)
            return null;
        return !(document.name.includes('pdf'))
            ? (getImagePlaceholder('id_card_recto'))
            : (_jsxs(Button, { onClick: openIdRectoDocument, variant: "ghost", className: styles.button, disabled: !((_b = identity === null || identity === void 0 ? void 0 : identity.files) === null || _b === void 0 ? void 0 : _b.find(function (doc) { return doc.type === 'id_card_recto'; })), children: [_jsx(Visibility, {}), "See document"] }));
    };
    var getIdCardVerso = function () {
        var _a, _b;
        var document = (_a = identity === null || identity === void 0 ? void 0 : identity.files) === null || _a === void 0 ? void 0 : _a.find(function (doc) { return (doc.type === 'id_card_verso'); });
        if (!document)
            return null;
        return !(document.name.includes('pdf'))
            ? (getImagePlaceholder('id_card_verso'))
            : (_jsxs(Button, { onClick: openIdVersoDocument, variant: "ghost", className: styles.button, disabled: !((_b = identity === null || identity === void 0 ? void 0 : identity.files) === null || _b === void 0 ? void 0 : _b.find(function (doc) { return doc.type === 'id_card_verso'; })), children: [_jsx(Visibility, {}), "See document"] }));
    };
    var getPassport = function () {
        var _a, _b;
        var document = (_a = identity === null || identity === void 0 ? void 0 : identity.files) === null || _a === void 0 ? void 0 : _a.find(function (doc) { return (doc.type === 'passport'); });
        if (!document)
            return null;
        return !(document.name.includes('pdf'))
            ? (getImagePlaceholder('passport'))
            : (_jsxs(Button, { onClick: openPassportDocument, variant: "ghost", className: styles.button, disabled: !((_b = identity === null || identity === void 0 ? void 0 : identity.files) === null || _b === void 0 ? void 0 : _b.find(function (doc) { return doc.type === 'passport'; })), children: [_jsx(Visibility, {}), "See document"] }));
    };
    return (_jsxs(_Fragment, { children: [(isOpen)
                && (_jsx(CommentForm, { field: field, options: {
                        'user.identity': 'General information - Identity paper',
                        'user.proofOfAddress': 'General information - Proof of Address',
                    }, close: function () { return setIsOpen({ isOpen: false, field: '' }); }, submit: handleComment })), _jsx(DataViewerLayout, { label: "Documents", status: statuses.SUCCESS, save: (isExpandable && status === statuses.SUCCESS) ? handleSave : undefined, isExpandable: isExpandable, children: (function (isEdit) {
                    var _a, _b, _c;
                    return (_jsxs(_Fragment, { children: [_jsx(CustomerDocumentAdd, { actions: { fetchIdentity: fetchIdentity, fetchProofOfAddress: fetchProofOfAddress } }), _jsx(Loading, { status: status, errorFallback: _jsx("p", { children: "Identity card not found" }), children: _jsxs(_Fragment, { children: [_jsx(DataViewerLabel, { label: "Identity Card", comment: (_a = formattedComments['user.identity']) === null || _a === void 0 ? void 0 : _a.text, onClick: function () { return setIsOpen(function (state) { return ({
                                                isOpen: !state.isOpen,
                                                field: 'user.identity',
                                            }); }); }, children: getPill() }), _jsxs("div", { className: styles.row, children: [getIdCardRecto(), getIdCardVerso(), getPassport()] }), _jsxs("label", { className: styles.label, htmlFor: "expirationDate", children: ["Expiration date", _jsx("input", { type: "date", name: "expirationDate", className: styles.input, value: identity === null || identity === void 0 ? void 0 : identity.expirationDate, onChange: handleIdentityChange, disabled: !isEdit })] }), _jsxs("label", { className: styles.label, htmlFor: "identityNumber", children: ["Identity card no.", _jsx("input", { type: "text", name: "identityNumber", className: styles.input, value: identity === null || identity === void 0 ? void 0 : identity.identityNumber, onChange: handleIdentityChange, disabled: !isEdit })] })] }) }), _jsx(Loading, { status: proofOfAddressStatus, errorFallback: _jsx("p", { children: "Proof of address not found" }), children: _jsxs(_Fragment, { children: [_jsx(DataViewerLabel, { label: "Proof of address", comment: (_b = formattedComments['user.proofOfAddress']) === null || _b === void 0 ? void 0 : _b.text, onClick: function () { return setIsOpen(function (state) { return ({
                                                isOpen: !state.isOpen,
                                                field: 'user.proofOfAddress',
                                            }); }); } }), _jsxs(Button, { onClick: openHomeCertificate, variant: "ghost", className: styles.button, disabled: !((_c = proofOfAddress === null || proofOfAddress === void 0 ? void 0 : proofOfAddress.files) === null || _c === void 0 ? void 0 : _c.find(function (doc) { return doc.type === 'home_certificate'; })), children: [_jsx(Visibility, {}), "See document"] }), _jsxs("label", { className: styles.label, htmlFor: "emissionDate", children: ["Emission date", _jsx("input", { type: "date", name: "emissionDate", className: styles.input, value: proofOfAddress === null || proofOfAddress === void 0 ? void 0 : proofOfAddress.emissionDate, onChange: handleProofOfAddressChange, disabled: !isEdit })] }), _jsxs("label", { className: styles.label, htmlFor: "documentType", children: ["Type", _jsxs("select", { className: styles.input, name: "documentType", value: proofOfAddress === null || proofOfAddress === void 0 ? void 0 : proofOfAddress.documentType, onChange: handleProofOfAddressChange, disabled: !isEdit, children: [_jsx("option", { children: "Utility bill" }), _jsx("option", { children: "House deed" }), _jsx("option", { children: "Bank statement" })] })] })] }) })] }));
                }) })] }));
}
