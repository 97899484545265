// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FX7ZNXsvhXYDxKOSV5sg {
  align-items: center;
  background-color: rgb(255 255 255 / 50%);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.tFQtaIfbdCqDMLDMChT6 {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 25px rgb(0 0 0 / 5%);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.chPATYjUvYni2QHP8EfG {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.ShZdbNNavsQgAVbEkFdL {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 5px;
  font-family: Roboto, sans-serif;
  margin-bottom: 16px;
  margin-top: 8px;
  min-height: 42px;
}

.Qqlkt5j1TpqphWAF8rVA {
  margin: 8px auto 0;
}
`, "",{"version":3,"sources":["webpack://./src/views/CareTunnel/CareTunnelActions/CareTunnelActions.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,QAAQ;EACR,MAAM;EACN,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;EACtC,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,0DAAwB;EACxB,kBAAkB;EAClB,+BAA+B;EAC/B,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray from colors;\n\n.background {\n  align-items: center;\n  background-color: rgb(255 255 255 / 50%);\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100%;\n}\n\n.modal {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 4px 25px rgb(0 0 0 / 5%);\n  display: flex;\n  flex-direction: column;\n  padding: 1rem;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.select {\n  border-color: color-gray;\n  border-radius: 5px;\n  font-family: Roboto, sans-serif;\n  margin-bottom: 16px;\n  margin-top: 8px;\n  min-height: 42px;\n}\n\n.cancel {\n  margin: 8px auto 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"background": `FX7ZNXsvhXYDxKOSV5sg`,
	"modal": `tFQtaIfbdCqDMLDMChT6`,
	"title": `chPATYjUvYni2QHP8EfG`,
	"select": `ShZdbNNavsQgAVbEkFdL`,
	"cancel": `Qqlkt5j1TpqphWAF8rVA`
};
export default ___CSS_LOADER_EXPORT___;
