import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import filterStyles from 'components/Filters/FiltersStatus/FiltersStatus.module.css';
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
export default function FilterAmountInvested() {
    var _a, _b, _c;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var onCheck = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                amount_invested: e.target.checked
                    ? [e.target.value]
                    : [],
            },
        });
    };
    return (_jsxs("div", { className: filterStyles.dropdown, children: [_jsxs("label", { htmlFor: "lessThan", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "lessThan", value: "lessThan", checked: ((_a = selected.amount_invested) === null || _a === void 0 ? void 0 : _a.includes('lessThan')) || false, onChange: onCheck }), "Less than 5 000\u20AC"] }), _jsxs("label", { htmlFor: "between", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "between", value: "between", checked: ((_b = selected.amount_invested) === null || _b === void 0 ? void 0 : _b.includes('between')) || false, onChange: onCheck }), "5 000\u20AC - 10 000\u20AC"] }), _jsxs("label", { htmlFor: "moreThan", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "moreThan", value: "moreThan", checked: ((_c = selected.amount_invested) === null || _c === void 0 ? void 0 : _c.includes('moreThan')) || false, onChange: onCheck }), "More than 10 000\u20AC"] })] }));
}
