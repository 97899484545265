import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import CommentForm from 'components/CommentForm';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import { USER_INPUTS } from 'constants/kyc';
import getGeneralInformation, { getKey, getValue } from 'utils/customer';
import flattenObject from 'utils/flattenObject';
export default function EntityUser(_a) {
    var user = _a.user, status = _a.status, _b = _a.comments, comments = _b === void 0 ? [] : _b, onCreate = _a.onCreate, onChange = _a.onChange, onSave = _a.onSave;
    var _c = useState({ isOpen: false, field: '' }), _d = _c[0], isOpen = _d.isOpen, field = _d.field, setIsOpen = _c[1];
    var close = function () { return setIsOpen({ isOpen: false, field: '' }); };
    var open = function (key) { return setIsOpen({ isOpen: true, field: key || '' }); };
    var handleSubmit = function (option, title, comment, visibility, commentStatus, type) {
        onCreate(option, title, comment, visibility, commentStatus, type);
        close();
    };
    return (_jsxs(_Fragment, { children: [(isOpen)
                && (_jsx(CommentForm, { field: field, options: USER_INPUTS, close: close, submit: handleSubmit })), _jsx(DataViewerLayout, { label: "Authorized representative", status: status, save: onSave, children: function (isEdit) { return (_jsx(DataViewerSimple, { data: getGeneralInformation(flattenObject(user, '')), comments: comments, adaptor: getValue, keyAdaptor: getKey, commentPrefix: "user", isEdit: isEdit, onChange: onChange, onComment: open })); } })] }));
}
