import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import CallRecord from './CallRecord';
import ValidationComments from './ValidationComments';
import ValidationNavigation from './ValidationNavigation';
import ValidationSummary from './ValidationSummary';
import ValidationCustomerCare from './ValidationCustomerCare';
import { NavigationTabs } from './ValidationNavigation/ValidationNavigation';
import styles from './Validation.module.css';
import ValidationProjects from './ValidationProjects';
export default function Validation(_a) {
    var _b;
    var clientId = _a.clientId, email = _a.email, entityId = _a.entityId, submission = _a.submission, worldCheck = _a.worldCheck, status = _a.status, comments = _a.comments, onCreate = _a.onCreate, onEdit = _a.onEdit, onDelete = _a.onDelete, markAsResolved = _a.markAsResolved, phone = _a.phone;
    var _c = useState(NavigationTabs.summary), tab = _c[0], setTab = _c[1];
    var ValidationTabs = Object.freeze((_b = {},
        _b[NavigationTabs.summary] = _jsx(ValidationSummary, { clientId: clientId, entityId: entityId, submission: submission, kycStatus: status, worldCheck: worldCheck }),
        _b[NavigationTabs.comments] = _jsx(ValidationComments, { comments: comments, onCreate: onCreate, onEdit: onEdit, onDelete: onDelete, markAsResolved: markAsResolved }),
        _b[NavigationTabs.callRecord] = (phone) ? _jsx(CallRecord, { phone: phone }) : _jsx("p", { children: "No Call found" }),
        _b[NavigationTabs.customerCare] = _jsx(ValidationCustomerCare, { email: email }),
        _b[NavigationTabs.projects] = _jsx(ValidationProjects, { email: email }),
        _b));
    var handleTabChange = function (e) {
        if (e.currentTarget.dataset.navigation) {
            setTab(e.currentTarget.dataset.navigation);
        }
    };
    return (_jsxs("div", { className: styles.validation, children: [_jsx(ValidationNavigation, { active: tab, onChange: handleTabChange }), ValidationTabs[tab]] }));
}
