import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorRounded } from '@mui/icons-material';
import useAPI from 'hooks/useAPI';
import { getCommentByUser } from 'services/network/api/customerCare';
import * as statuses from 'services/store/states';
import styles from './ValidationCustomerCareProject.module.css';
export default function ValidationCustomerCareProjectComment(_a) {
    var id = _a.id;
    var _b = useAPI({
        request: function (source) { return getCommentByUser(id, source); },
        cached: "customerCare-comments-".concat(id),
    }), status = _b.status, data = _b.data;
    return (_jsxs("div", { className: styles.container, children: [status === statuses.LOADING
                && (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black ".concat(styles.spinner) }), "Loading customer care comments..."] })), status === statuses.FAIL
                && (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, { className: styles.icon }), "Error occurred while loading customer care comments"] })), status === statuses.SUCCESS
                && data.length === 0
                && _jsx("p", { children: "No comment found" }), status === statuses.SUCCESS
                && data.length > 0
                && (data.map(function (item) { return (_jsxs("div", { className: styles.comment, "data-testid": "customer-care-comment", children: [_jsxs("div", { className: styles.header, children: [_jsxs("div", { className: styles.info, children: [_jsx("p", { className: styles.writer, children: item.idUser }), _jsx("p", { className: "".concat(styles.status, " ").concat(styles[item.status]), children: item.status }), _jsx("p", { className: item.resolved ? styles.resolved : styles.task, children: item.resolved ? 'Resolved' : 'Not resolved' })] }), _jsx("p", { className: styles.date, children: item.updated || item.created })] }), _jsxs("p", { className: styles.content, children: [_jsx("span", { className: styles.title, children: item.title }), item.text] })] })); }))] }));
}
