import { jsx as _jsx } from "react/jsx-runtime";
import styles from 'components/Table/TableRows/TableRows.module.css';
export default function Status(_a) {
    var state = _a.state;
    if (state === 'incomplete') {
        return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.rejected), children: "Incomplete" }));
    }
    if (state === 'rejected') {
        return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.rejected), children: "Rejected" }));
    }
    if (state === 'pending') {
        return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.pending), children: "Pending" }));
    }
    return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.approved), children: "Approved" }));
}
