var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { useEffect, useState } from 'react';
import { debounce } from '@mui/material';
import { useFiltersState } from 'contexts/FiltersProvider';
import * as statuses from 'services/store/states';
import { usersAdaptor } from 'services/network/adaptor/user';
import getErrorMessage from 'utils/error';
import { keysToCamel } from 'utils/converter';
import { fetchEntity, fetchUser } from 'utils/kycFiltersBuilder';
var isResponse = function (data) { return !!data && typeof data === 'object' && 'items' in data && 'total' in data; };
export default function useKycs(_a) {
    var _this = this;
    var type = _a.type, filters = _a.filters;
    var _b = useState(true), firstFetch = _b[0], setFirstFetch = _b[1];
    var _c = useState(statuses.IDLE), status = _c[0], setStatus = _c[1];
    var _d = useState([]), kycs = _d[0], setKycs = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(window.localStorage.getItem('page') ? Number.parseInt(window.localStorage.getItem('page'), 10) : 1), page = _f[0], setPage = _f[1];
    var _g = useState(10), resultsPerPage = _g[0], setResultsPerPage = _g[1];
    var _h = useState(0), total = _h[0], setTotal = _h[1];
    var _j = useState(1), maxPage = _j[0], setMaxPage = _j[1];
    var selected = useFiltersState().selected;
    /* Filtered request */
    var onSuccess = function (nbPage) {
        if (nbPage === void 0) { nbPage = 1; }
        return function (data) {
            if (!isResponse(data))
                return;
            setKycs((type === 'user' ? usersAdaptor : keysToCamel)(data.items));
            setTotal(data.total);
            setPage(nbPage);
            setMaxPage(Math.ceil(data.total / resultsPerPage));
            setStatus(statuses.SUCCESS);
            window.localStorage.setItem('page', nbPage.toString());
            setFirstFetch(false);
        };
    };
    var onError = function (err) {
        setError(getErrorMessage(err));
        setStatus(statuses.FAIL);
    };
    useEffect(function () {
        setStatus(statuses.LOADING);
        var cancelToken = axios.CancelToken;
        var source = cancelToken.source();
        var lastPage = window.localStorage.getItem('page') ? parseInt(window.localStorage.getItem('page'), 10) : 1;
        var debouncedFetchUserData = debounce(function () {
            fetchUser(filters, selected, source, resultsPerPage, firstFetch ? lastPage : 1, onSuccess(firstFetch ? lastPage : 1), onError);
        }, 500);
        var debouncedFetchEntityData = debounce(function () { return fetchEntity(filters, selected, source, resultsPerPage, firstFetch ? lastPage : 1, onSuccess(firstFetch ? lastPage : 1), onError); }, 500);
        if (type === 'user') {
            debouncedFetchUserData();
        }
        else if (type === 'entity') {
            debouncedFetchEntityData();
        }
        return function () { return source.cancel('axios request cancelled'); };
    }, [type, filters, selected, resultsPerPage]);
    /* Pagination */
    var setPageNumber = function (pageNumber) { return __awaiter(_this, void 0, void 0, function () {
        var cancelToken, source;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    cancelToken = axios.CancelToken;
                    source = cancelToken.source();
                    if (!(type === 'user')) return [3 /*break*/, 2];
                    setStatus(statuses.LOADING);
                    return [4 /*yield*/, fetchUser(filters, selected, source, resultsPerPage, pageNumber, onSuccess(pageNumber), onError)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, fetchEntity(filters, selected, source, resultsPerPage, pageNumber, onSuccess(pageNumber), onError)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setPage(pageNumber);
                    window.localStorage.setItem('page', pageNumber.toString());
                    return [2 /*return*/];
            }
        });
    }); };
    var nextPage = function () {
        if (maxPage && page < maxPage) {
            var newPage = page + 1;
            setPageNumber(newPage);
            setPage(newPage);
        }
    };
    var previousPage = function () {
        if (page > 1) {
            var newPage = page - 1;
            setPageNumber(newPage);
        }
    };
    var goPage = function (pageNumber) {
        if (pageNumber <= maxPage) {
            setPageNumber(pageNumber);
        }
    };
    return {
        status: status,
        data: kycs,
        error: error,
        page: page,
        resultsPerPage: resultsPerPage,
        total: total,
        nextPage: nextPage,
        previousPage: previousPage,
        goPage: goPage,
        setResultsPerPage: setResultsPerPage,
        updateData: setKycs,
    };
}
