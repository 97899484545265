import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import filterStyles from 'components/Filters/FiltersStatus/FiltersStatus.module.css';
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
export default function FilterAmountRaised() {
    var _a, _b, _c, _d, _e;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var onCheck = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                amount_raised: e.target.checked
                    ? [e.target.value]
                    : [],
            },
        });
    };
    return (_jsxs("div", { className: filterStyles.dropdown, children: [_jsxs("label", { htmlFor: "lessThan", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "lessThan", value: "lessThan", checked: ((_a = selected.amount_raised) === null || _a === void 0 ? void 0 : _a.includes('lessThan')) || false, onChange: onCheck }), "Less than 50 000\u20AC"] }), _jsxs("label", { htmlFor: "between_50k_100k", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "between_50k_100k", value: "between_50k_100k", checked: ((_b = selected.amount_raised) === null || _b === void 0 ? void 0 : _b.includes('between_50k_100k')) || false, onChange: onCheck }), "50 000\u20AC - 100 000\u20AC"] }), _jsxs("label", { htmlFor: "between_100k_500k", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "between_100k_500k", value: "between_100k_500k", checked: ((_c = selected.amount_raised) === null || _c === void 0 ? void 0 : _c.includes('between_100k_500k')) || false, onChange: onCheck }), "100 000 - 500 000\u20AC"] }), _jsxs("label", { htmlFor: "between_500k_1m", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "between_500k_1m", value: "between_500k_1m", checked: ((_d = selected.amount_raised) === null || _d === void 0 ? void 0 : _d.includes('between_500k_1m')) || false, onChange: onCheck }), "500 000 - 1 000 000\u20AC"] }), _jsxs("label", { htmlFor: "moreThan", className: filterStyles.option, children: [_jsx("input", { type: "checkbox", id: "moreThan", value: "moreThan", checked: ((_e = selected.amount_raised) === null || _e === void 0 ? void 0 : _e.includes('moreThan')) || false, onChange: onCheck }), "More than 1 000 000\u20AC"] })] }));
}
