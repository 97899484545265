import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import styles from './SearchTabs.module.css';
export default function SearchTabs(_a) {
    var children = _a.children;
    var _b = useState(window.localStorage.getItem('search-tab') || 'user'), activeTab = _b[0], setActiveTab = _b[1];
    var selectUser = function () {
        setActiveTab('user');
        window.localStorage.setItem('search-tab', 'user');
    };
    var selectEntity = function () {
        setActiveTab('entity');
        window.localStorage.setItem('search-tab', 'entity');
    };
    return (_jsxs(_Fragment, { children: [_jsx("nav", { className: styles.navigation, children: _jsxs("div", { className: styles.container, children: [_jsx("button", { type: "button", onClick: selectUser, className: "".concat(styles.button, " ").concat((activeTab === 'user') ? styles.active : ''), children: "Individual investors" }), _jsx("button", { type: "button", onClick: selectEntity, className: "".concat(styles.button, " ").concat((activeTab === 'entity') ? styles.active : ''), children: "Legal entity" })] }) }), children(activeTab)] }));
}
