import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close } from '@mui/icons-material';
import styles from './FiltersSelected.module.css';
import withKeyboardSupport from 'utils/withKeyboardSupport';
import { useFiltersDispatch } from 'contexts/FiltersProvider';
export default function FiltersSelected(_a) {
    var label = _a.label, selections = _a.selections, onOpen = _a.onOpen;
    var dispatch = useFiltersDispatch();
    var clear = function (e) {
        var _a;
        e.stopPropagation();
        if (!dispatch)
            return;
        dispatch({ type: 'UPDATE', payload: (_a = {}, _a[label] = [], _a) });
    };
    if (selections.length === 0)
        return null;
    return (_jsxs("div", { role: "button", tabIndex: -1, className: styles.selected, onClick: onOpen, onKeyDown: withKeyboardSupport(onOpen), "data-testid": "filters-selected-".concat(label), children: [_jsxs("span", { children: [_jsx("span", { className: styles.filter, children: "".concat(label[0].toUpperCase()).concat(label.slice(1, label.length), " is") }), _jsx("span", { className: styles.selection, children: selections === null || selections === void 0 ? void 0 : selections.join(', ') })] }), _jsx("button", { type: "button", className: styles.clear, "data-testid": "filters-remove-".concat(label), onClick: clear, children: _jsx(Close, { className: styles['clear-icon'] }) })] }));
}
