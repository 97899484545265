// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B_J4tThdB45l2grEzDa_ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Z15qnsBRgJOkPJVyuchx {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}

.llEl5ICVTGq5JYrzZ9vw {
  margin-bottom: 48px;
  width: 50%;
}

.OGkYZKHjbWUZEi6lexT_ {
  column-gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.S0QphEzGXUNiky0Kqjnb {
  background-color: #f3f5ff;
  border-radius: 5px;
  margin-bottom: 16px;
  margin-top: 0;
  padding: 16px;
  width: max-content;
}

.S0QphEzGXUNiky0Kqjnb .Z15qnsBRgJOkPJVyuchx {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.MHewpQAOLk9KrYFtvO8D {
  align-self: center;
  font-size: 16px;
  margin-right: 8px;
}

.coWm9I0_X1PdFI1gw8iw {
  margin-bottom: 0;
  margin-top: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelSummary/TunnelSummary.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.comments {\n  margin-bottom: 48px;\n  width: 50%;\n}\n\n.footer {\n  column-gap: 8px;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.validation {\n  background-color: #f3f5ff;\n  border-radius: 5px;\n  margin-bottom: 16px;\n  margin-top: 0;\n  padding: 16px;\n  width: max-content;\n}\n\n.validation .title {\n  display: flex;\n  font-size: 16px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.icon {\n  align-self: center;\n  font-size: 16px;\n  margin-right: 8px;\n}\n\n.description {\n  margin-bottom: 0;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `B_J4tThdB45l2grEzDa_`,
	"title": `Z15qnsBRgJOkPJVyuchx`,
	"comments": `llEl5ICVTGq5JYrzZ9vw`,
	"footer": `OGkYZKHjbWUZEi6lexT_`,
	"validation": `S0QphEzGXUNiky0Kqjnb`,
	"icon": `MHewpQAOLk9KrYFtvO8D`,
	"description": `coWm9I0_X1PdFI1gw8iw`
};
export default ___CSS_LOADER_EXPORT___;
