// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vpSUjdAwxaojXfpqUiyR {
  margin-top: 16px;
  width: 40%;
}

.b0Uj5B80VohSGtHXqggQ {
  border-bottom: 1px solid #e5e5e5;
}

.pUN6YB_59mECinq60lYn {
  box-sizing: content-box;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 26px;
  padding: 0 16px 16px;
  transition: 0.3s;
}

.pUN6YB_59mECinq60lYn:hover,
.aoYOBnELywUnfkvFQgww {
  border-bottom: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/Validation.module.css"],"names":[],"mappings":"AAGA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,uBAAuB;EACvB,mDAAwB;EACxB,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;;EAEE,qEAAqC;EACrC,mDAAmB;AACrB","sourcesContent":["@value colors: \"../library/style/colors.css\";\n@value color-text-subtle, color-purple from colors;\n\n.validation {\n  margin-top: 16px;\n  width: 40%;\n}\n\n.navigation {\n  border-bottom: 1px solid #e5e5e5;\n}\n\n.button {\n  box-sizing: content-box;\n  color: color-text-subtle;\n  font-family: Roboto, sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  height: 26px;\n  padding: 0 16px 16px;\n  transition: 0.3s;\n}\n\n.button:hover,\n.active {\n  border-bottom: 1px solid color-purple;\n  color: color-purple;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"validation": `vpSUjdAwxaojXfpqUiyR`,
	"navigation": `b0Uj5B80VohSGtHXqggQ`,
	"button": `pUN6YB_59mECinq60lYn`,
	"active": `aoYOBnELywUnfkvFQgww`
};
export default ___CSS_LOADER_EXPORT___;
