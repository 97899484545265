// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BDHwTBCrHhG7PnY3v6id {
  margin: auto;
  max-width: 1440px;
  padding-top: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Entity/Entity.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".section {\n  margin: auto;\n  max-width: 1440px;\n  padding-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `BDHwTBCrHhG7PnY3v6id`
};
export default ___CSS_LOADER_EXPORT___;
