import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { ErrorRounded, ExpandLess, ExpandMore } from '@mui/icons-material';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import useAPI from 'hooks/useAPI';
import flattenObject from 'utils/flattenObject';
import { getProject } from 'services/network/api/customerCare';
import { selectComments, selectEndDate } from 'services/network/api/project';
import * as statuses from 'services/store/states';
import ValidationProjectDetailsInvestments from './ValidationProjectDetailsInvestments';
import styles from './ValidationProjectDetails.module.css';
export default function ValidationProjectDetails(_a) {
    var _b, _c;
    var projectId = _a.projectId, projectName = _a.projectName, email = _a.email;
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var _e = useAPI({
        request: function (source) { return getProject(projectId, email, source); },
        cached: "project-".concat(projectId, "-").concat(email),
    }), status = _e.status, data = _e.data;
    var comments = selectComments((_b = data === null || data === void 0 ? void 0 : data.project) === null || _b === void 0 ? void 0 : _b.comments);
    var endDate = selectEndDate((_c = data === null || data === void 0 ? void 0 : data.project) === null || _c === void 0 ? void 0 : _c.importantDate);
    return (_jsxs(_Fragment, { children: [_jsxs("button", { type: "button", className: styles['project-expand'], onClick: function () { return setIsOpen(function (curr) { return !curr; }); }, children: [_jsxs("span", { children: [_jsx("span", { children: projectName }), _jsx("span", { className: styles['project-id'], children: "#".concat(projectId) })] }), (isOpen)
                        ? _jsx(ExpandLess, {})
                        : _jsx(ExpandMore, {})] }), (isOpen)
                && (status === statuses.LOADING)
                && (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black ".concat(styles.spinner) }), _jsx("span", { children: "Loading project data..." })] })), (isOpen)
                && (status === statuses.FAIL)
                && (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, { className: styles['error-icon'] }), _jsx("span", { children: "Failed to load project data" })] })), (isOpen)
                && (status === statuses.SUCCESS)
                && (_jsxs("div", { className: styles['project-details'], children: [_jsx("p", { className: styles.title, children: " Comments " }), _jsx(DataViewerSimple, { data: flattenObject(comments, ''), isEdit: false }), _jsx("p", { className: styles.title, children: " End date " }), _jsx(DataViewerSimple, { data: flattenObject(endDate, ''), isEdit: false }), _jsx("p", { className: styles.title, children: "Investments" }), _jsx(ValidationProjectDetailsInvestments, { projectId: projectId })] }))] }));
}
