import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Loading from 'components/library/Loading';
import useAPI from 'hooks/useAPI';
import { keysToCamel } from 'utils/converter';
import { getAppropriatenessAnswers, getAppropriatenessResult } from 'services/controller/userTest';
import answers from 'services/network/adaptor/CustomerTestAnswers';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import { getUserAnswersByIss } from 'services/network/api/users';
import styles from './TunnelVerificationAppropriateness.module.css';
export default function TunnelVerificationAppropriateness(_a) {
    var user = _a.user;
    var _b = useAPI({
        request: function (source) { return getUserAnswersByIss(source, user.iss); },
        adaptor: function (res) { return getAppropriatenessAnswers(keysToCamel(res)); },
        cached: "userAnswer".concat(user.iss),
    }), status = _b.status, data = _b.data;
    return (_jsx(Loading, { status: status, children: _jsxs(_Fragment, { children: [_jsx("p", { className: styles.title, children: "Appropriateness" }), _jsxs("p", { className: styles.results, children: [_jsx("span", { children: "Test result :" }), data === null || data === void 0 ? void 0 : data.appropriatenessScore] }), _jsxs("p", { className: styles.results, children: [_jsx("span", { children: "Risk warning :" }), _jsx("span", { className: "".concat(styles.pill, " ").concat(styles[getAppropriatenessResult(data === null || data === void 0 ? void 0 : data.appropriatenessScore, data === null || data === void 0 ? void 0 : data.experienceTrading)]), children: getAppropriatenessResult(data === null || data === void 0 ? void 0 : data.appropriatenessScore, data === null || data === void 0 ? void 0 : data.experienceTrading) })] }), (data)
                    && (_jsx(DataViewerSimple, { data: data, adaptor: function (key, value) { return (
                        // @ts-ignore
                        answers.appropriateness[key] !== undefined
                            // @ts-ignore
                            && answers.appropriateness[key][value])
                            || value.toString(); }, isEdit: false }))] }) }));
}
