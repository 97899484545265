import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import CommentForm from 'components/CommentForm';
import { USER_INPUTS } from 'constants/kyc';
import { getValue } from 'utils/customer';
export default function EntityGeneral(_a) {
    var status = _a.status, entity = _a.entity, onSave = _a.onSave, onChange = _a.onChange, _b = _a.isExpandable, isExpandable = _b === void 0 ? true : _b, _c = _a.comments, comments = _c === void 0 ? [] : _c, onCreate = _a.onCreate;
    var _d = useState({ isOpen: false, field: '' }), _e = _d[0], isOpen = _e.isOpen, field = _e.field, setIsOpen = _d[1];
    var handleComment = function (option, title, comment, visibility, commentStatus, type) {
        onCreate(option, title, comment, visibility, commentStatus, type);
        setIsOpen({ isOpen: false, field: '' });
    };
    var openCommentForm = function (newField) { return setIsOpen({ isOpen: true, field: newField || '' }); };
    var closeCommentForm = function () { return setIsOpen({ isOpen: false, field: '' }); };
    return (_jsxs(_Fragment, { children: [(isOpen) && (_jsx(CommentForm, { field: field, options: USER_INPUTS, close: closeCommentForm, submit: handleComment })), _jsx(DataViewerLayout, { label: "Company informations", status: status, save: onSave, isExpandable: isExpandable, children: function (isEdit) { return (_jsx(DataViewerSimple, { data: entity, isEdit: isEdit, adaptor: getValue, onChange: onChange, comments: comments, commentPrefix: "entity", onComment: openCommentForm })); } })] }));
}
