var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Edit, ErrorRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import useAPI from 'hooks/useAPI';
import { RiskLevel, toRisk } from 'services/network/adaptor/user';
import { createRiskByIss, getRiskByIss, updateRiskByIss } from 'services/network/api/users';
import * as statuses from 'services/store/states';
import { addToast } from 'services/store/features/toasts/actions';
import styles from './ValidationSummaryRisk.module.css';
import { logError } from 'utils/logs';
export default function ValidationSummaryRisk(_a) {
    var _this = this;
    var iss = _a.iss;
    var _b = useState(statuses.IDLE), status = _b[0], setStatus = _b[1];
    var _c = useState(false), isEdit = _c[0], setIsEdit = _c[1];
    var dispatch = useDispatch();
    var _d = useAPI({
        request: function (source) { return getRiskByIss(iss, source); },
        adaptor: toRisk,
    }), request = _d.status, data = _d.data, error = _d.error, setData = _d.setData;
    var saveRisk = function () { return __awaiter(_this, void 0, void 0, function () {
        var newRisk, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, , 6]);
                    setStatus(statuses.LOADING);
                    newRisk = {
                        level: (data === null || data === void 0 ? void 0 : data.level) || RiskLevel.HIGH,
                        reason: (data === null || data === void 0 ? void 0 : data.reason) || '',
                    };
                    if (!(error === null || error === void 0 ? void 0 : error.includes('404'))) return [3 /*break*/, 2];
                    return [4 /*yield*/, createRiskByIss(iss, newRisk)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateRiskByIss(iss, newRisk)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setData(newRisk);
                    setStatus(statuses.SUCCESS);
                    setIsEdit(false);
                    dispatch(addToast({
                        type: 'success',
                        title: 'Risk categorization updated successfully',
                        description: '',
                    }));
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _a.sent();
                    logError(err_1);
                    setStatus(statuses.FAIL);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Risk categorization update failed. Please, try again',
                        description: "".concat(err_1),
                    }));
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", { className: styles.risk, children: [_jsxs("div", { className: styles.row, children: [_jsx("p", { className: styles.title, children: "Risk categorization" }), _jsxs("button", { type: "button", onClick: isEdit ? saveRisk : function () { return setIsEdit(!isEdit); }, className: styles.edit, disabled: status === statuses.LOADING, "data-testid": !isEdit ? 'edit-risk' : 'save-risk', children: [(status !== statuses.LOADING)
                                && (!isEdit
                                    ? _jsx(Edit, { className: styles.icon })
                                    : 'Save'), (status === statuses.LOADING)
                                && (_jsx("span", { className: "spinner black" }))] })] }), (isEdit)
                ? (_jsxs("form", { className: styles.form, children: [_jsxs("label", { htmlFor: "risk-level", className: styles.label, children: ["Level of risk", _jsxs("select", { id: "risk-level", value: (data === null || data === void 0 ? void 0 : data.level) || RiskLevel.HIGH, onChange: function (e) { return setData(__assign(__assign({}, data), { level: e.target.value })); }, children: [_jsx("option", { value: RiskLevel.HIGH, children: RiskLevel.HIGH }), _jsx("option", { value: RiskLevel.MEDIUM, children: RiskLevel.MEDIUM }), _jsx("option", { value: RiskLevel.LOW, children: RiskLevel.LOW })] })] }), _jsxs("label", { htmlFor: "risk-reason", className: styles.label, children: ["Comment", _jsx("input", { type: "text", id: "risk-reason", value: (data === null || data === void 0 ? void 0 : data.reason) || '', onChange: function (e) { return setData(__assign(__assign({}, data), { reason: e.target.value })); } })] })] }))
                : (_jsxs(_Fragment, { children: [(status === statuses.IDLE && request === statuses.FAIL)
                            && (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, { className: styles.icon }), (error === null || error === void 0 ? void 0 : error.includes('404'))
                                        ? 'Risk categorization not found'
                                        : 'Failed to load risk categorization'] })), (status === statuses.IDLE && request === statuses.LOADING)
                            && (_jsxs("p", { children: [_jsx("span", { className: "spinner black" }), "Loading risk categorization..."] })), (status === statuses.SUCCESS || request === statuses.SUCCESS)
                            && (_jsxs("div", { className: styles.content, children: [_jsxs("p", { className: styles.value, children: ["Level of Risk :", _jsx("span", { children: (data === null || data === void 0 ? void 0 : data.level) || 'N/A' })] }), _jsxs("p", { className: styles.value, children: ["Comment :", _jsx("span", { children: (data === null || data === void 0 ? void 0 : data.reason) || 'N/A' })] })] }))] }))] }));
}
