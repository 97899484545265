import { toHuman } from './converter';
import flattenObject from './flattenObject';
var hideFields = [
    'isConfig', 'idAddress', 'idAddressBirth', 'idContactAddress', 'iss',
];
export default function getGeneralInformation(data) {
    return Object.fromEntries(Object
        .entries(flattenObject(data, ''))
        .filter(function (_a) {
        var key = _a[0];
        return !(key.includes('.id')
            || key.includes('answers.')
            || key.includes('addressBirth.')
            || key.includes('risk.')
            || key.includes('worldCheck.')
            || hideFields.includes(key));
    }));
}
export var getValue = function (key, value) {
    if ((value === null || value === void 0 ? void 0 : value.toString()) === 'true')
        return 'Yes';
    if ((value === null || value === void 0 ? void 0 : value.toString()) === 'false')
        return 'No';
    if ((value === null || value === void 0 ? void 0 : value.toString()) === 'p2p')
        return 'Corporate issuer';
    return value;
};
export var getKey = function (key) {
    if (key === 'pep')
        return 'PEP';
    if (key === 'marketing')
        return 'Accept to receive marketing content';
    if (key === 'usResident')
        return 'Is US resident';
    if (key === 'expired')
        return 'Are identity documents expired';
    return toHuman(key);
};
