// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zzYqFTToLM0Z5_xHP1nX {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  column-gap: 4px;
  display: flex;
  margin-bottom: 16px;
}

.T2GT3por8vPjLyviBkO6 {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  column-gap: 4px;
  display: flex;
}

.tajJc9U_Tk_r1nLyR_TF {
  font-weight: 500;
  margin: 0 4px;
}

.HRsiYQFgwRV2eBWD5HrR p {
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelLayout/TunnelLayoutBreadcrumb/TunnelLayoutBreadcrumb.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,mDAAwB;EACxB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,mDAAwB;EACxB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n.row {\n  align-items: center;\n  color: color-text-subtle;\n  column-gap: 4px;\n  display: flex;\n  margin-bottom: 16px;\n}\n\n.step {\n  align-items: center;\n  color: color-text-subtle;\n  column-gap: 4px;\n  display: flex;\n}\n\n.status {\n  font-weight: 500;\n  margin: 0 4px;\n}\n\n.active p {\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"row": `zzYqFTToLM0Z5_xHP1nX`,
	"step": `T2GT3por8vPjLyviBkO6`,
	"status": `tajJc9U_Tk_r1nLyR_TF`,
	"active": `HRsiYQFgwRV2eBWD5HrR`
};
export default ___CSS_LOADER_EXPORT___;
