var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { Error } from '@mui/icons-material';
import { FILE_ENTITY, USER_INPUTS } from 'constants/kyc';
import CommentForm from 'components/CommentForm';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import ButtonTooltip from 'components/ButtonTooltip';
import useAPI from 'hooks/useAPI';
import { getFileEntity, updateFileEntity } from 'services/network/api/entity';
import * as statuses from 'services/store/states';
import { fileEntityBackendAdaptor } from 'services/network/adaptor/entity';
import { toHuman } from 'utils/converter';
import { useDispatch } from 'react-redux';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import { getCommentsAsObject } from 'services/network/adaptor/comment';
import styles from './EntityDocuments.module.css';
import EntityDocumentsFile from './EntityDocumentsFile';
import { logError } from 'utils/logs';
export default function EntityDocuments(_a) {
    var _this = this;
    var iss = _a.iss, siren = _a.siren, comments = _a.comments, onCreate = _a.onCreate, _b = _a.isExpandable, isExpandable = _b === void 0 ? true : _b, _c = _a.isEditable, isEditable = _c === void 0 ? true : _c;
    var formattedComments = useMemo(function () { return getCommentsAsObject(comments); }, [comments]);
    var _d = useState({ isOpen: false, field: '' }), _e = _d[0], isOpen = _e.isOpen, field = _e.field, setIsOpen = _d[1];
    var _f = useState([]), documentsToUpload = _f[0], setDocumentsToUpload = _f[1];
    var dispatch = useDispatch();
    var _g = useAPI({
        request: function (source) { return getFileEntity(source, siren, iss); },
    }), status = _g.status, data = _g.data, error = _g.error, setData = _g.setData;
    var close = function () { return setIsOpen({ isOpen: false, field: '' }); };
    var open = function (key) { return setIsOpen({ isOpen: true, field: key }); };
    var openDocument = function (e) { return window.open(data.filter(function (doc) { return doc.type === e.currentTarget.dataset.type; })[0].url); };
    var handleSubmit = function (option, title, text, isVisible, commentStatus, type) {
        onCreate(option, title, text, isVisible, commentStatus, type);
        close();
    };
    var handleFile = function (e) {
        setDocumentsToUpload(function (docs) {
            var _a, _b, _c;
            if (((_a = e.target) === null || _a === void 0 ? void 0 : _a.files) && ((_b = e.target) === null || _b === void 0 ? void 0 : _b.files[0])) {
                var newDocs = docs.filter(function (doc) { return doc.type !== e.target.name; });
                var newFile = {
                    file: e.target.files[0],
                    siren: siren,
                    iss: iss,
                    type: e.target.name,
                    name: (_c = e.target.files[0]) === null || _c === void 0 ? void 0 : _c.name,
                };
                return __spreadArray(__spreadArray([], newDocs, true), [newFile], false);
            }
            return docs;
        });
    };
    var handleSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var types_1, uploadPromises, responses_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    types_1 = documentsToUpload.map(function (document) { return document.type; });
                    uploadPromises = documentsToUpload.map(function (document) { return (updateFileEntity(fileEntityBackendAdaptor(document))); });
                    return [4 /*yield*/, Promise.all(uploadPromises)];
                case 1:
                    responses_1 = _a.sent();
                    setDocumentsToUpload([]);
                    setData(function (documentList) {
                        var newDocumentList = documentList.filter(function (document) { return (!types_1.includes(document.type)); });
                        var uploadedDocuments = responses_1.map(function (response) { return response.data; });
                        return __spreadArray(__spreadArray([], newDocumentList, true), uploadedDocuments, true);
                    });
                    dispatch(addToast({
                        type: 'success',
                        title: 'File upload succeeded',
                        description: 'The file has been updated',
                    }));
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'File upload failed',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (status === statuses.IDLE || status === statuses.LOADING) {
        return (_jsx("p", { children: "Loading..." }));
    }
    if (status === statuses.FAIL) {
        return _jsx("p", { children: error });
    }
    return (_jsxs(_Fragment, { children: [(isOpen) && (_jsx(CommentForm, { field: field, options: USER_INPUTS, close: close, submit: handleSubmit })), _jsx(DataViewerLayout, { label: "Company documents", status: "SUCCESS", save: (isEditable) ? handleSave : undefined, isExpandable: isExpandable, children: function (isEdit) { return (FILE_ENTITY.map(function (type) {
                    var _a;
                    return (_jsxs("div", { children: [_jsxs("div", { className: styles.header, children: [_jsx("p", { className: styles.title, children: toHuman(type) }), _jsx(ButtonTooltip, { onClick: function () { return open("entity.".concat(type)); }, value: (_a = formattedComments["entity.".concat(type)]) === null || _a === void 0 ? void 0 : _a.text, className: (formattedComments["entity.".concat(type)]) ? styles.active : '', children: _jsx(Error, { className: styles.icon }) })] }), (isEdit)
                                ? (_jsx("input", { type: "file", name: type, onChange: handleFile }))
                                : (_jsx(EntityDocumentsFile, { document: data.filter(function (doc) { return doc.type === type; })[0], openDocument: openDocument }))] }, type));
                })); } })] }));
}
