import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorRounded } from '@mui/icons-material';
import useAPI from 'hooks/useAPI';
import { searchUserByEmail } from 'services/network/api/customerCare';
import * as statuses from 'services/store/states';
import styles from './ValidationCustomerCare.module.css';
import ValidationCustomerCareProject from './ValidationCustomerCareProject';
export default function ValidationCustomerCare(_a) {
    var email = _a.email;
    var _b = useAPI({
        request: function (source) { return searchUserByEmail(email, source); },
        cached: "customerCare-".concat(email),
    }), status = _b.status, data = _b.data;
    return (_jsxs("div", { "data-testid": "customer-care", children: [status === statuses.LOADING
                && (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black ".concat(styles.spinner) }), _jsx("span", { children: "Loading customer care data..." })] })), status === statuses.FAIL
                && (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, { className: styles.icon }), "Error occurred while loading customer care data"] })), status === statuses.SUCCESS
                && data.length === 0
                && _jsx("p", { children: "No data found" }), status === statuses.SUCCESS
                && data.length > 0
                && (data.map(function (item) {
                    var _a;
                    return (_jsx(ValidationCustomerCareProject, { user: (_a = item.iss) === null || _a === void 0 ? void 0 : _a.toString(), project: "".concat(item.idAyomi), projectName: item.project.name }, item.iss));
                }))] }));
}
