import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from 'react-router-dom';
import Validation from 'components/Validation';
import Loading from 'components/library/Loading';
import useUser from 'hooks/useUser';
import TunnelVerificationComments from '../Tunnel/TunnelVerification/TunnelVerificationComments/TunnelVerificationComments';
import CustomerLayout from './CustomerLayout';
import CustomerGeneral from './CustomerGeneral';
import CustomerDocuments from './CustomerDocuments';
import CustomerTests from './CustomerTests';
import CustomerInvestments from './CustomerInvestments';
import styles from './Customer.module.css';
export default function Customer(_a) {
    var userName = _a.userName, connectedUserIss = _a.connectedUserIss;
    var iss = useParams().iss;
    if (iss) {
        var _b = useUser({ iss: iss }), status_1 = _b.status, user_1 = _b.user, comments = _b.comments;
        return (_jsx(Loading, { status: status_1, children: (user_1 && comments)
                ? (_jsx(CustomerLayout, { user: user_1, children: _jsx(TunnelVerificationComments, { connectedUserIss: connectedUserIss, iss: iss, userName: userName, comments: comments, children: function (newComments, onCreate, onDelete, onEdit, markAsResolved) { return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.info, children: [_jsx(CustomerInvestments, { iss: iss }), _jsx(CustomerGeneral, { user: user_1, comments: newComments, handleCreate: onCreate }), _jsx(CustomerDocuments, { iss: iss, comments: newComments, handleCreate: onCreate }), _jsx(CustomerTests, { iss: iss })] }), _jsx(Validation, { clientId: iss, email: (user_1 === null || user_1 === void 0 ? void 0 : user_1.email) || '', status: user_1 === null || user_1 === void 0 ? void 0 : user_1.status, phone: (user_1 === null || user_1 === void 0 ? void 0 : user_1.number) || '', worldCheck: user_1.worldCheck, submission: user_1.acceptConditions, comments: newComments, onCreate: onCreate, onDelete: onDelete, markAsResolved: markAsResolved, onEdit: onEdit })] })); } }) }))
                : _jsx("p", { children: " User not found " }) }));
    }
    return _jsx("p", { children: " Please provide iss " });
}
