// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DhxCoaLAQhxLnpinQSeJ {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.p9Uam17_OXKnGFmsXmuA {
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  height: 42px;
  margin-right: 4px;
  padding-left: 8px;
  width: calc(100% - 36px);
}

.p9Uam17_OXKnGFmsXmuA:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

.CKQToDJxe4zaHGhlKJ7t {
  align-items: center;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-left: 36px;
  margin-top: 8px;
  padding: 8px 16px;
}

.h94IpDZfcDpmZmDJrjXN {
  font-weight: 500;
  margin: 0 8px 0 0;
}

.RZC1LYsRi6cOxm1k2tfB + .RZC1LYsRi6cOxm1k2tfB {
  margin-left: 8px;
}

.PWs3WOXhzIXMODGe7FWk {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationComments/Reply/Reply.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8DAA4B;EAC5B,kBAAkB;EAClB,sBAAsB;EACtB,+BAA+B;EAC/B,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,8DAAiC;EACjC,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":["@value colors: \"../../../library/style/colors.css\";\n@value color-gray, color-error-dark, color-error-light, color-purple-bg, color-purple, color-text-subtle from colors;\n\n.comment {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n}\n\n.input {\n  border: 1px solid color-gray;\n  border-radius: 5px;\n  box-sizing: border-box;\n  font-family: Roboto, sans-serif;\n  height: 42px;\n  margin-right: 4px;\n  padding-left: 8px;\n  width: calc(100% - 36px);\n}\n\n.input:hover {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n.options {\n  align-items: center;\n  background-color: color-purple-bg;\n  border-radius: 5px;\n  display: flex;\n  flex-direction: row;\n  margin-left: 36px;\n  margin-top: 8px;\n  padding: 8px 16px;\n}\n\n.title {\n  font-weight: 500;\n  margin: 0 8px 0 0;\n}\n\n.radio + .radio {\n  margin-left: 8px;\n}\n\n.footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../../../library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-error-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-dark"]}`,
	"color-error-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"comment": `DhxCoaLAQhxLnpinQSeJ`,
	"input": `p9Uam17_OXKnGFmsXmuA`,
	"options": `CKQToDJxe4zaHGhlKJ7t`,
	"title": `h94IpDZfcDpmZmDJrjXN`,
	"radio": `RZC1LYsRi6cOxm1k2tfB`,
	"footer": `PWs3WOXhzIXMODGe7FWk`
};
export default ___CSS_LOADER_EXPORT___;
