import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useAPI from 'hooks/useAPI';
import Loading from 'components/library/Loading';
import { getFileEntity } from 'services/network/api/entity';
import { getUserMissingDocuments } from 'services/controller/userDocuments';
import { getEntityMissingDocuments } from 'services/controller/entityDocuments';
import { toHuman } from 'utils/converter';
import styles from 'components/DataViewer/DataViewerSimple/DataViewerSimple.module.css';
import loading from 'assets/loading.png';
import tunnelStyles from './TunnelDocuments.module.css';
import { getUserFileByIss } from 'services/network/api/users';
export default function TunnelDocuments(_a) {
    var type = _a.type, iss = _a.iss, siren = _a.siren, _b = _a.country, country = _b === void 0 ? '' : _b;
    var _c = useAPI({
        request: function (source) { return ((type === 'user' || !siren)
            ? getUserFileByIss(source, iss)
            : getFileEntity(source, siren, iss)); },
    }), data = _c.data, status = _c.status;
    return (_jsxs("div", { className: styles.answer, children: [_jsx("p", { className: styles.label, children: "Document missing" }), _jsx(Loading, { status: status, fallback: _jsx("img", { src: loading, className: tunnelStyles.loading, alt: "loading" }), children: _jsx("p", { className: styles.data, children: toHuman((type === 'user')
                        ? getUserMissingDocuments(data).join(', ')
                        : getEntityMissingDocuments(country, data).join(', ')) || 'None' }) })] }));
}
