import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Business, Cake, Email, Gavel, OpenInNew, Person, Phone, Tag, } from '@mui/icons-material';
import Button from 'components/library/Button';
import styles from './CareTunnelUser.module.css';
export default function CareTunnelUser(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var user = _a.user, entity = _a.entity, phoneRef = _a.phoneRef, isCalling = _a.isCalling, onCall = _a.onCall, onCallEnd = _a.onCallEnd;
    var onOpen = function () {
        if (!entity) {
            window.open("/search/customer/".concat(user.iss), '_blank');
        }
        else {
            window.open("/search/entity/".concat(entity.general.siren, "/").concat(user.iss), '_blank');
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.header, children: [_jsxs("div", { children: [_jsx("p", { className: styles.title, children: "".concat(user.firstName, " ").concat(user.lastName, " ").concat(entity ? " | ".concat(entity.general.societyName) : '') }), _jsxs("div", { className: styles.grid, children: [_jsxs("p", { className: styles.contact, children: [_jsx(Person, { className: styles.icon }), _jsx("span", { children: user.nationality })] }), _jsxs("p", { className: styles.contact, children: [_jsx(Cake, { className: styles.icon }), _jsx("span", { children: user.birthday })] }), (user.pep)
                                        && (_jsxs("p", { className: styles.contact, children: [_jsx(Gavel, { className: styles.icon }), _jsx("span", { children: "Politicaly Exposed Person" })] })), _jsxs("p", { className: styles.contact, children: [_jsx(Email, { className: styles.icon }), _jsx("span", { children: user.email })] }), _jsxs("p", { className: styles.contact, children: [_jsx(Phone, { className: styles.icon }), _jsx("span", { ref: phoneRef, onClickCapture: onCall, children: user.number })] }), (entity)
                                        && (_jsxs(_Fragment, { children: [_jsxs("p", { className: styles.contact, children: [_jsx(Business, { className: styles.icon }), _jsx("span", { children: entity.general.societyName })] }), _jsxs("p", { className: styles.contact, children: [_jsx(Tag, { className: styles.icon }), _jsx("span", { children: entity.general.tag })] })] }))] })] }), _jsxs("div", { className: styles['button-group'], children: [_jsxs(Button, { variant: "ghost", onClick: onOpen, children: [_jsx(OpenInNew, { className: styles['button-icon'] }), "Open profile"] }), _jsx(Button, { onClick: onCall, children: "Launch the call" })] })] }), (isCalling)
                && (_jsxs("div", { className: styles.call, children: [_jsxs("p", { className: styles.text, children: [_jsx(Phone, { className: styles.icon }), "Call in progress"] }), _jsx(Button, { type: "primary", onClick: onCallEnd, children: "End of the call" })] })), _jsxs("div", { children: [_jsx("p", { className: styles.bold, children: "Reasons for rejection :" }), ((((_b = user.answers) === null || _b === void 0 ? void 0 : _b.suitabilityScore) || 0) < 9
                        || (((_c = user.answers) === null || _c === void 0 ? void 0 : _c.appropriatenessScore) || 0) < 6)
                        && (_jsxs("div", { className: styles.comment, children: [_jsx("p", { className: styles.title, children: "Low score test" }), _jsx("p", { children: "Suitability: ".concat(((_d = user.answers) === null || _d === void 0 ? void 0 : _d.suitabilityScore) || 0, " | Appropriateness: ").concat(((_e = user.answers) === null || _e === void 0 ? void 0 : _e.appropriatenessScore) || 0) })] })), (((((_f = user.answers) === null || _f === void 0 ? void 0 : _f.appropriatenessScore) || 0) < 6) && !user.riskAcknowledged)
                        && (_jsx("div", { className: styles.comment, children: _jsx("p", { className: styles.title, children: "No risk acknowledgment" }) })), (user.usResident)
                        && (_jsx("div", { className: styles.comment, children: _jsx("p", { className: styles.title, children: "US Resident" }) })), (((_g = user.answers) === null || _g === void 0 ? void 0 : _g.experienceTrading) === 'none')
                        && (_jsx("div", { className: styles.comment, children: _jsx("p", { className: styles.title, children: "Test answer : I have no knowledge in investing" }) })), (((_h = user.answers) === null || _h === void 0 ? void 0 : _h.levelOfRisk) === 'none')
                        && (_jsx("div", { className: styles.comment, children: _jsx("p", { className: styles.title, children: "Test answer : None of it. I can't accept to lose any money" }) })), (((_j = user.answers) === null || _j === void 0 ? void 0 : _j.employmentPosition) === 'unemployed')
                        && (_jsx("div", { className: styles.comment, children: _jsx("p", { className: styles.title, children: "Test answer : Unemployed" }) })), (_k = user.comments) === null || _k === void 0 ? void 0 : _k.map(function (comment) { return (_jsxs("div", { className: styles.comment, children: [_jsx("p", { className: styles.title, children: comment.title }), _jsx("p", { children: comment.text })] })); })] })] }));
}
