// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f8B3eUw9GTKiPRwigvTE {
  align-items: center;
  background-color: #f0f0f1;
  border-radius: 32px;
  display: flex;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.S6t2YZQfGX46J3DkBrv2 {
  font-size: 16px !important;
}

.Z2Je9Mxzu7tepueZ6kFw {
  font-size: 16px;
}

.f8B3eUw9GTKiPRwigvTE.NPPQQOneYICIe3ByoH6w {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.f8B3eUw9GTKiPRwigvTE.NPPQQOneYICIe3ByoH6w .S6t2YZQfGX46J3DkBrv2,
.f8B3eUw9GTKiPRwigvTE.NPPQQOneYICIe3ByoH6w .Z2Je9Mxzu7tepueZ6kFw {
  color: white;
}

.vRTEm4mK8Erjdjl0EFQM {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red"]};
  color: white;
}

.dqNd3GjAh7PIjdruOUNE {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success"]};
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelLayout/TunnelLayoutBreadcrumb/BreadcrumbStatus/BreadcrumbStatus.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,8DAA8B;AAChC;;AAEA;;EAEE,YAAY;AACd;;AAEA;EACE,8DAA2B;EAC3B,YAAY;AACd;;AAEA;EACE,8DAA+B;EAC/B,YAAY;AACd","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple, color-white, color-red, color-success from colors;\n\n.step {\n  align-items: center;\n  background-color: #f0f0f1;\n  border-radius: 32px;\n  display: flex;\n  font-weight: 500;\n  height: 32px;\n  justify-content: center;\n  width: 32px;\n}\n\n.icon {\n  font-size: 16px !important;\n}\n\n.number {\n  font-size: 16px;\n}\n\n.step.active {\n  background-color: color-purple;\n}\n\n.step.active .icon,\n.step.active .number {\n  color: white;\n}\n\n.error {\n  background-color: color-red;\n  color: white;\n}\n\n.success {\n  background-color: color-success;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-white": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-white"]}`,
	"color-red": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-red"]}`,
	"color-success": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-success"]}`,
	"step": `f8B3eUw9GTKiPRwigvTE`,
	"icon": `S6t2YZQfGX46J3DkBrv2`,
	"number": `Z2Je9Mxzu7tepueZ6kFw`,
	"active": `NPPQQOneYICIe3ByoH6w`,
	"error": `vRTEm4mK8Erjdjl0EFQM`,
	"success": `dqNd3GjAh7PIjdruOUNE`
};
export default ___CSS_LOADER_EXPORT___;
