import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import flattenObject from 'utils/flattenObject';
import styles from './CustomerInvestmentsReservation.module.css';
export default function CustomerInvestmentsReservation(_a) {
    var reservation = _a.reservation;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    return (_jsxs("div", { role: "button", tabIndex: -1, className: styles['reservation-group'], onClick: function () { return setIsOpen(function (curr) { return !curr; }); }, onKeyDown: function (e) {
            if (e.key === 'Enter')
                setIsOpen(true);
        }, children: [_jsxs("div", { className: styles.reservation, children: [_jsx("div", { children: _jsx("p", { className: styles.society, children: "Projet n\u00B0 ".concat(reservation.idProject) }) }), _jsxs("div", { className: styles.group, children: [_jsx("p", { className: styles.date, children: reservation.date }), _jsx("p", { className: "".concat(styles.status, " ").concat(styles[reservation.status]), children: reservation.status }), _jsxs("p", { className: styles.amount, children: [reservation.amount.toLocaleString(), "\u20AC"] }), (!isOpen)
                                ? _jsx(ExpandMore, {})
                                : _jsx(ExpandLess, {})] })] }), isOpen && (_jsxs("div", { className: styles['reservation-details'], children: [_jsx("p", { className: styles.subtitle, children: "Account details" }), _jsxs("div", { className: styles.grid, children: [_jsx("p", { className: styles.label, children: "Owner" }), _jsx("p", { className: styles.label, children: "Country" }), _jsx("p", { className: styles.label, children: "Payment method" }), _jsx("p", { className: styles.label, children: "Billing address" }), _jsx("p", { className: styles.value, children: reservation.owner }), _jsx("p", { className: styles.value, children: reservation.accountCountry }), _jsx("div", { className: styles.value, children: _jsx(DataViewerSimple, { data: flattenObject(reservation.paymentMethod, ''), isEdit: false }) }), _jsx("div", { className: styles.value, children: _jsx(DataViewerSimple, { data: flattenObject(reservation.billingAddress, ''), isEdit: false }) })] })] }))] }, reservation.id));
}
