var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import SearchTabFilters from './SearchTabFilters';
import SearchTabResults from './SearchTabResults';
import { initEndDate, initStartDate } from 'utils/initFilters';
/* const getEndDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}; */
export default function SearchTab(_a) {
    var type = _a.type;
    var _b = useState(window.localStorage.getItem('filters')
        ? __assign(__assign({}, JSON.parse(window.localStorage.getItem('filters'))), { updated__le: initEndDate(), updated__ge: initStartDate() }) : {}), filters = _b[0], setFilters = _b[1];
    var handleFilters = function (newFilters) {
        setFilters(newFilters);
        window.localStorage.setItem('filters', JSON.stringify(newFilters));
    };
    return (_jsxs(_Fragment, { children: [_jsx(SearchTabFilters, { type: type, filters: filters, onSubmit: handleFilters }), _jsx(SearchTabResults, { type: type, filters: filters })] }));
}
