var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useAPI from 'hooks/useAPI';
import { getCommentsByQuery } from 'services/network/api/comments';
import { commentAdaptor } from 'services/network/adaptor/comment';
import { entityBackendAdaptor } from 'services/network/adaptor/entity';
import * as statuses from 'services/store/states';
import { updateEntityBySiren } from 'services/network/api/entity';
import { addToast } from 'services/store/features/toasts/actions';
import Loading from 'components/library/Loading';
import Validation from 'components/Validation';
import useUser from 'hooks/useUser';
import { useParams } from 'react-router-dom';
import TunnelVerificationComments from '../../Tunnel/TunnelVerification/TunnelVerificationComments/TunnelVerificationComments';
import CustomerDocuments from '../../Customer/CustomerDocuments';
import EntityLayout from '../EntityLayout';
import EntityGeneral from '../EntityGeneral';
import EntityDocuments from '../EntityDocuments';
import EntityUser from '../EntityUser';
import CustomerInvestments from '../../Customer/CustomerInvestments';
import styles from './EntityResult.module.css';
import CustomerTests from '../../Customer/CustomerTests';
import { logError } from 'utils/logs';
export default function EntityResult(_a) {
    var _this = this;
    var _b;
    var connectedUserIss = _a.connectedUserIss, data = _a.data, error = _a.error, siren = _a.siren, userName = _a.userName, setData = _a.setData;
    var iss = useParams().iss;
    var _c = useState(statuses.IDLE), updateStatus = _c[0], setUpdateStatus = _c[1];
    var dispatch = useDispatch();
    var _d = useAPI({
        request: function () {
            var _a;
            return getCommentsByQuery({
                iss: iss || connectedUserIss,
                idEntity: (_a = data === null || data === void 0 ? void 0 : data.general) === null || _a === void 0 ? void 0 : _a.id,
            });
        },
        adaptor: function (list) { return list.map(function (comment) { return commentAdaptor(comment); }); },
    }), commentStatus = _d.status, comments = _d.data;
    var _e = useUser({ iss: data.user.iss }), status = _e.status, user = _e.user;
    var handleChange = function (e, key) {
        var _a = e.target.name.split('-'), key1 = _a[0], key2 = _a[1];
        if (!key2) {
            setData(function (curr) {
                var _a, _b;
                return (__assign(__assign({}, curr), (_a = {}, _a[key] = __assign(__assign({}, (curr[key])), (_b = {}, _b[e.target.name] = e.target.value, _b)), _a)));
            });
        }
        else {
            setData(function (curr) {
                var _a, _b, _c;
                return (__assign(__assign({}, curr), (_a = {}, _a[key] = __assign(__assign({}, curr[key]), (_b = {}, _b[key1] = __assign(__assign({}, curr[key][key1]), (_c = {}, _c[key2] = e.target.value, _c)), _b)), _a)));
            });
        }
    };
    var handleEntityGeneralChange = function (e) { return handleChange(e, 'general'); };
    var handleEntityUserChange = function (e) { return handleChange(e, 'user'); };
    var updateEntity = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUpdateStatus(statuses.LOADING);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateEntityBySiren(siren, entityBackendAdaptor(data))];
                case 2:
                    _a.sent();
                    setUpdateStatus(statuses.SUCCESS);
                    dispatch(addToast({
                        type: 'success',
                        title: 'Entity information has been updated',
                        description: 'Entity modification has been saved successfully',
                    }));
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    setUpdateStatus(statuses.FAIL);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Oops, unable to save the entity update',
                        description: 'Please retry later or contact Ayomi technical services',
                    }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Loading, { status: commentStatus, errorFallback: _jsx("p", { children: error }), children: ((data === null || data === void 0 ? void 0 : data.general) && (data === null || data === void 0 ? void 0 : data.user))
            ? (_jsx(EntityLayout, { entity: data === null || data === void 0 ? void 0 : data.general, children: _jsx(TunnelVerificationComments, { connectedUserIss: connectedUserIss, userName: userName, iss: data.user.iss, idEntity: (_b = data === null || data === void 0 ? void 0 : data.general) === null || _b === void 0 ? void 0 : _b.id, comments: comments || [], children: function (newComments, onCreate, onDelete, onEdit, markAsResolved) {
                        var _a, _b;
                        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.main, children: [_jsx(CustomerInvestments, { iss: data.user.iss.toString() }), _jsx(EntityGeneral, { status: updateStatus, entity: data.general, comments: newComments, onCreate: onCreate, onSave: updateEntity, onChange: handleEntityGeneralChange }), _jsx(EntityDocuments, { iss: data.user.iss.toString(), siren: siren, comments: newComments, onCreate: onCreate }), (data.user.iss && user && status === statuses.SUCCESS)
                                            && (_jsxs(_Fragment, { children: [_jsx(EntityUser, { user: user, status: updateStatus, comments: newComments, onCreate: onCreate, onSave: updateEntity, onChange: handleEntityUserChange }), _jsx(CustomerTests, { iss: data.user.iss })] })), _jsx(CustomerDocuments, { iss: data.user.iss, comments: newComments, handleCreate: onCreate })] }), (commentStatus === statuses.SUCCESS)
                                    && (_jsx(Validation, { clientId: data.user.iss.toString(), email: data.user.email || '', entityId: (_a = data === null || data === void 0 ? void 0 : data.general) === null || _a === void 0 ? void 0 : _a.id.toString(), phone: data.user.number || '', status: data.general.status || 'incomplete', submission: data.user.acceptConditions || '', worldCheck: ((_b = data.user) === null || _b === void 0 ? void 0 : _b.worldCheck) || null, comments: newComments, onCreate: onCreate, onDelete: onDelete, onEdit: onEdit, markAsResolved: markAsResolved }))] }));
                    } }) })) : (_jsx("p", { children: "Sorry, too much missing information. Impossible to display." })) }));
}
