// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yLUKcatjqxamZ1nIL27N {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr;
}

.rAk7sit20jpiqDkwF18w {
  justify-self: right;
}

.uXfRR1QmwsOgH71Jf7AD {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  text-transform: capitalize;
}

.BjY5pWBeHvrPuaZE0L1S {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.hQaTqTBBmx4hAtGIpl_9 .BjY5pWBeHvrPuaZE0L1S {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}

.XFTWxBEdAu7d97hcfZrc {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerGeneral/CustomerGeneral.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,mDAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-error from colors;\n\n.answer {\n  align-items: center;\n  display: grid;\n  grid-template-columns: 1fr 1fr 0.25fr;\n}\n\n.left {\n  justify-self: right;\n}\n\n.label {\n  color: color-text-subtle;\n  text-transform: capitalize;\n}\n\n.error {\n  color: color-text-subtle;\n}\n\n.active .error {\n  color: color-error;\n}\n\n.field {\n  border: 1px solid #e5e5e5;\n  border-radius: 4px;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"answer": `yLUKcatjqxamZ1nIL27N`,
	"left": `rAk7sit20jpiqDkwF18w`,
	"label": `uXfRR1QmwsOgH71Jf7AD`,
	"error": `BjY5pWBeHvrPuaZE0L1S`,
	"active": `hQaTqTBBmx4hAtGIpl_9`,
	"field": `XFTWxBEdAu7d97hcfZrc`
};
export default ___CSS_LOADER_EXPORT___;
