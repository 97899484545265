// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sZzKWVSuAgg6a1df89q7 {
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr 1fr 0.25fr;
}

.ZN1A1vaS3okwdHTCP7Mh {
  justify-self: right;
}

.mb90KSFDwWFRkTv68tZC {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  text-transform: capitalize;
}

.uyYtQJXaryXOMJwKorR_,
.mb90KSFDwWFRkTv68tZC {
  margin: 8px 0;
}

.smJAca27sCxKbzSQ1fpi {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.bOrXYI9B1ZITlkVjp7_A .smJAca27sCxKbzSQ1fpi {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}

.My7rsv7T1qv8HKnxLPhP {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
}

.OWZ4nR6pCQiEC0R62Ris {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  row-gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/DataViewer/DataViewerSimple/DataViewerSimple.module.css"],"names":[],"mappings":"AAGA;EACE,uBAAuB;EACvB,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mDAAwB;EACxB,0BAA0B;AAC5B;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,mDAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["@value colors: \"../../library/style/colors.css\";\n@value color-text-subtle, color-error from colors;\n\n.answer {\n  align-items: flex-start;\n  display: grid;\n  grid-template-columns: 1fr 1fr 0.25fr;\n}\n\n.left {\n  justify-self: right;\n}\n\n.label {\n  color: color-text-subtle;\n  text-transform: capitalize;\n}\n\n.data,\n.label {\n  margin: 8px 0;\n}\n\n.error {\n  color: color-text-subtle;\n}\n\n.active .error {\n  color: color-error;\n}\n\n.field {\n  border: 1px solid #e5e5e5;\n  border-radius: 4px;\n  padding: 8px;\n}\n\n.column {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n  row-gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../../library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"answer": `sZzKWVSuAgg6a1df89q7`,
	"left": `ZN1A1vaS3okwdHTCP7Mh`,
	"label": `mb90KSFDwWFRkTv68tZC`,
	"data": `uyYtQJXaryXOMJwKorR_`,
	"error": `smJAca27sCxKbzSQ1fpi`,
	"active": `bOrXYI9B1ZITlkVjp7_A`,
	"field": `My7rsv7T1qv8HKnxLPhP`,
	"column": `OWZ4nR6pCQiEC0R62Ris`
};
export default ___CSS_LOADER_EXPORT___;
