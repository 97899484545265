// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EV1wyeL1SnYnKLBaOHlH {
  border: 1px solid #e5e5e5;
  border-radius: 0 0 5px 5px;
  border-top: none;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 8px 16px;
  width: 100%;
}

.yTIG2trw58ez_V3qBCoT {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.25fr;
}

.yTIG2trw58ez_V3qBCoT div,
.yTIG2trw58ez_V3qBCoT p {
  color: #838383;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

.yTIG2trw58ez_V3qBCoT th:first-child {
  border-radius: 0 0 5px;
}

.Uut1Nt8D1qqT712TGiyM {
  margin-right: 4px;
}

.yTIG2trw58ez_V3qBCoT .a1yxHaDHPN6o45_1RYa6 {
  color: white;
}

.yTIG2trw58ez_V3qBCoT p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/TableTitle/TableTitle.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,0BAA0B;EAC1B,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,yDAAyD;AAC3D;;AAEA;;EAEE,cAAc;EACd,+BAA+B;EAC/B,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;AACX","sourcesContent":[".head {\n  border: 1px solid #e5e5e5;\n  border-radius: 0 0 5px 5px;\n  border-top: none;\n  box-sizing: border-box;\n  margin-bottom: 8px;\n  padding: 8px 16px;\n  width: 100%;\n}\n\n.row {\n  align-items: center;\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.25fr;\n}\n\n.row div,\n.row p {\n  color: #838383;\n  font-family: Roboto, sans-serif;\n  font-weight: 400;\n}\n\n.row th:first-child {\n  border-radius: 0 0 5px;\n}\n\n.checkbox {\n  margin-right: 4px;\n}\n\n.row .hide {\n  color: white;\n}\n\n.row p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `EV1wyeL1SnYnKLBaOHlH`,
	"row": `yTIG2trw58ez_V3qBCoT`,
	"checkbox": `Uut1Nt8D1qqT712TGiyM`,
	"hide": `a1yxHaDHPN6o45_1RYa6`
};
export default ___CSS_LOADER_EXPORT___;
