import { fromDateToInput, getStartDate } from './date';
export var initStartDate = function () {
    var timeRange = window.localStorage.getItem('timeRange') || '7';
    if (timeRange === 'all') {
        return undefined;
    }
    if (timeRange === 'custom') {
        var start = window.localStorage.getItem('startDate');
        if (start) {
            return fromDateToInput(new Date(start));
        }
        return fromDateToInput(getStartDate(new Date(), 7));
    }
    return fromDateToInput(getStartDate(new Date(), Number.parseInt(timeRange, 10)));
};
export var initEndDate = function () {
    var timeRange = window.localStorage.getItem('timeRange') || '7';
    if (timeRange === 'all') {
        return undefined;
    }
    if (timeRange === 'custom') {
        var end = window.localStorage.getItem('endDate');
        if (end) {
            return fromDateToInput(new Date(end));
        }
    }
    return fromDateToInput(new Date());
};
