var addLeadingZero = function (number) { return ((number < 10) ? "0".concat(number) : number.toString()); };
export var fromDateToInput = function (date) { return "".concat(date.getFullYear(), "-").concat(addLeadingZero(date.getMonth() + 1), "-").concat(addLeadingZero(date.getDate())); };
export var fromInputToDate = function (date) { return new Date(date); };
export var getStartDate = function (date, numberOfDays) {
    var startDate = new Date(date.getTime());
    startDate.setDate(date.getDate() - numberOfDays);
    return startDate;
};
var padTo2Digits = function (num) { return num.toString().padStart(2, '0'); };
export var formatDate = function (date) { return ("".concat([
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
].join('-'), " ").concat([
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
].join(':'))); };
