var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import TunnelSummary from '../TunnelSummary';
export default function TunnelSummaryUser(_a) {
    var data = _a.data, activities = _a.activities, status = _a.status, comments = _a.comments, onCreate = _a.onCreate, onDelete = _a.onDelete, onEdit = _a.onEdit, markAsResolved = _a.markAsResolved, onReview = _a.onReview, onReject = _a.onReject, onApprove = _a.onApprove;
    var summaryAdaptor = function (user) {
        var expired = user.expired, idAddress = user.idAddress, idAddressBirth = user.idAddressBirth, idContactAddress = user.idContactAddress, isConfig = user.isConfig, createdUser = user.created, updatedUser = user.updated, marketing = user.marketing, address = user.address, addressBirth = user.addressBirth, contactAddress = user.contactAddress, userSummary = __rest(user, ["expired", "idAddress", "idAddressBirth", "idContactAddress", "isConfig", "created", "updated", "marketing", "address", "addressBirth", "contactAddress"]);
        return userSummary;
    };
    return (_jsx(TunnelSummary, { status: status, activities: activities, comments: comments, onReview: onReview, onReject: onReject, onApprove: onApprove, onCreate: onCreate, onDelete: onDelete, onEdit: onEdit, markAsResolved: markAsResolved, children: _jsx(DataViewerSimple, { data: summaryAdaptor(data), isEdit: false }) }));
}
