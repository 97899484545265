// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uNtK6j0qW0XU0lEFz2O1 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 24px;
  font-weight: 500;
  margin: auto;
  max-width: 1440px;
  padding: 48px 24px 24px;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelVerification.module.css"],"names":[],"mappings":"AAGA;EACE,mDAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple, color-purple-dark, color-purple-light from colors;\n\n.empty {\n  color: color-text-subtle;\n  font-size: 24px;\n  font-weight: 500;\n  margin: auto;\n  max-width: 1440px;\n  padding: 48px 24px 24px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]}`,
	"color-purple-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-light"]}`,
	"empty": `uNtK6j0qW0XU0lEFz2O1`
};
export default ___CSS_LOADER_EXPORT___;
