var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from 'react';
import { updateProfile } from 'services/network/api/care';
import CareTunnelUser from '../CareTunnelUser';
import CareTunnelActions from '../CareTunnelActions';
import { createLog, sendPipeAction } from 'services/network/api/pipe';
import { logError } from 'utils/logs';
export default function CareTunnelLogic(_a) {
    var _this = this;
    var user = _a.user, entity = _a.entity, connectedUser = _a.connectedUser, onNext = _a.onNext;
    var _b = useState(false), isCalling = _b[0], setIsCalling = _b[1];
    var _c = useState(false), showActions = _c[0], setShowActions = _c[1];
    var _d = useState(undefined), ringover = _d[0], setRingover = _d[1];
    var target = useRef();
    useEffect(function () {
        if (target.current) {
            var callback = function (change) {
                var _a;
                var ringoverComponent = (_a = change.find(function (record) { return record.type === 'childList'; })) === null || _a === void 0 ? void 0 : _a.addedNodes[0];
                var number = ringoverComponent === null || ringoverComponent === void 0 ? void 0 : ringoverComponent.lastChild;
                if (number) {
                    setRingover(number);
                }
            };
            var observer_1 = new MutationObserver(callback);
            observer_1.observe(target.current, {
                attributes: true,
                characterData: true,
                childList: true,
                subtree: true,
            });
            return function () { return observer_1.disconnect(); };
        }
        return undefined;
    }, []);
    var handleClose = function () { return setShowActions(false); };
    var handleCall = function () {
        var _a;
        createLog({
            action: 'tunnel_care_call', idEntity: (_a = entity === null || entity === void 0 ? void 0 : entity.general) === null || _a === void 0 ? void 0 : _a.id, issUser: user.iss, issActor: connectedUser,
        });
        if (ringover) {
            ringover.click();
        }
        setIsCalling(true);
    };
    var handleCallEnd = function () { return setShowActions(true); };
    var handleSubmit = function (action) { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateProfile(user.iss, { action: action })];
                case 1:
                    _c.sent();
                    sendPipeAction({
                        action: 'rejected_care_tunnel',
                        iss_user: user.iss,
                        id_entity: (_a = entity === null || entity === void 0 ? void 0 : entity.general) === null || _a === void 0 ? void 0 : _a.id,
                    });
                    createLog({
                        action: "tunnel_care_call_end_".concat(action), idEntity: (_b = entity === null || entity === void 0 ? void 0 : entity.general) === null || _b === void 0 ? void 0 : _b.id, issUser: user.iss, issActor: connectedUser,
                    });
                    onNext();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _c.sent();
                    logError(err_1);
                    console.error(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [(showActions)
                && (_jsx(CareTunnelActions, { onSubmit: handleSubmit, onClose: handleClose })), _jsx(CareTunnelUser, { user: user, entity: entity, phoneRef: target, isCalling: isCalling, onCall: handleCall, onCallEnd: handleCallEnd })] }));
}
