import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Bookmark, DocumentScanner, Person, Public, Savings, ShoppingCart, Warning, } from '@mui/icons-material';
import Filter from '../Filter';
import FiltersStatus from '../FiltersStatus';
import FilterCategory from '../FilterCategory';
import FilterRisk from '../FilterRisk';
import FilterCountry from '../FilterCountry';
import FilterTest from '../FilterTest';
import FilterDocument from '../FilterDocument';
import Filters from '../Filters';
import FilterAmountRaised from '../FilterAmountRaised';
import FilterAmountInvested from '../FilterAmountInvested';
export default function FiltersGroup(_a) {
    var _b = _a.type, type = _b === void 0 ? 'user' : _b;
    return (_jsxs(Filters, { children: [_jsx(Filter, { name: "status", icon: _jsx(Bookmark, {}), children: _jsx(FiltersStatus, {}) }), _jsx(Filter, { name: "category", icon: _jsx(Person, {}), children: _jsx(FilterCategory, {}) }), _jsx(Filter, { name: "risk", icon: _jsx(Warning, {}), children: _jsx(FilterRisk, {}) }), _jsx(Filter, { name: "country", icon: _jsx(Public, {}), children: _jsx(FilterCountry, {}) }), _jsx(Filter, { name: "appropriateness", icon: _jsx(Bookmark, {}), children: _jsx(FilterTest, { name: "appropriateness" }) }), _jsx(Filter, { name: "suitability", icon: _jsx(Person, {}), children: _jsx(FilterTest, { name: "suitability" }) }), _jsx(Filter, { name: "document", icon: _jsx(DocumentScanner, {}), children: _jsx(FilterDocument, {}) }), (type === 'entity')
                ? (_jsx(Filter, { name: "amount_raised", icon: _jsx(Savings, {}), children: _jsx(FilterAmountRaised, {}) }))
                : _jsx("div", {}), _jsx(Filter, { name: "amount_invested", icon: _jsx(ShoppingCart, {}), children: _jsx(FilterAmountInvested, {}) })] }));
}
