var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import { CheckCircle } from '@mui/icons-material';
import Button from 'components/library/Button';
import { USER_INPUTS } from 'constants/kyc';
import CommentForm from '../../CommentForm';
import Comment from './Comment';
import styles from './ValidationComments.module.css';
var groupCommentsByFields = function (comments, selectedType) {
    if (!comments)
        return undefined;
    return comments
        .filter(function (comment) { return (selectedType === 'all' || comment.type === selectedType); })
        .reduce(function (acc, curr) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[curr.field] = __spreadArray(__spreadArray([], (acc[curr.field] || []), true), [
            curr,
        ], false), _a)));
    }, {});
};
export default function ValidationComments(_a) {
    var comments = _a.comments, iss = _a.iss, onEdit = _a.onEdit, onDelete = _a.onDelete, onCreate = _a.onCreate, markAsResolved = _a.markAsResolved;
    var _b = useState('all'), selectedType = _b[0], setSelectedType = _b[1];
    var formattedComments = useMemo(function () { return groupCommentsByFields(comments, selectedType); }, [comments, selectedType]);
    var _c = useState(false), isOpen = _c[0], setIsOpen = _c[1];
    var open = function () { return setIsOpen(true); };
    var close = function () { return setIsOpen(false); };
    var handleSubmit = function (field, title, text, isVisible, status, task) {
        onCreate(field, title, text, isVisible, status, task);
        close();
    };
    return (_jsxs(_Fragment, { children: [(isOpen)
                && (_jsx(CommentForm, { options: USER_INPUTS, close: close, submit: handleSubmit })), _jsxs("div", { className: styles.comments, "data-testid": "comments", children: [(comments.length > 0 || formattedComments)
                        && (_jsx("div", { className: styles.toolbar, children: _jsxs("label", { htmlFor: "type", children: ["Comments' type", _jsxs("select", { id: "type", className: styles.select, value: selectedType, onChange: function (e) { return setSelectedType(e.target.value); }, children: [_jsx("option", { value: "all", children: "All" }), _jsx("option", { value: "task", children: "Task" }), _jsx("option", { value: "follow_up", children: "Follow up" })] })] }) })), (comments.length === 0 || !formattedComments)
                        ? (_jsx("p", { className: styles['empty-message'], children: "There is no comments" }))
                        : Object.keys(formattedComments).map(function (key) { return (_jsxs("div", { children: [_jsxs("div", { className: styles.header, children: [_jsxs("p", { className: styles.label, children: [USER_INPUTS[key] || key, _jsx("span", { className: "".concat(styles.status, " ").concat(styles[formattedComments[key][0].status]), children: formattedComments[key][0].status })] }), ((formattedComments[key][0].status === 'error') && !formattedComments[key][0].isResolved)
                                            ? (_jsxs(Button, { variant: "ghost", id: formattedComments[key][0].id, className: styles['resolve-btn'], onClick: markAsResolved, children: [_jsx(CheckCircle, { className: styles.icon }), _jsx("span", { children: "Mark as resolved" })] }))
                                            : (_jsxs("p", { className: styles.resolved, children: [_jsx(CheckCircle, { className: styles.icon }), _jsx("span", { children: "Resolved" })] }))] }), formattedComments[key].map(function (comment) { return (_jsx(Comment, { comment: comment, iss: iss, onDelete: onDelete, onEdit: onEdit }, comment.id)); })] }, key)); }), _jsx(Button, { onClick: open, className: styles['add-comment'], children: "Add a comment" })] })] }));
}
