import { init as initApm } from '@elastic/apm-rum';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
var serviceVersion = require("../package.json").version;
var apm = initApm({
    active: true,
    serviceName: process.env.REACT_APP_NAME || 'localhost',
    serverUrl: "https://".concat(process.env.REACT_APP_DEBUG ? 'dev-' : '', "alert.ayomi.fr/rum"),
    serviceVersion: serviceVersion,
    environment: process.env.REACT_APP_DEBUG ? 'development' : 'production',
    disableInstrumentations: [],
    eventsLimit: 2000,
    transactionSampleRate: 1.0,
    ignoreTransactions: [],
});
FingerprintJS.load().then(function (fp) {
    fp.get().then(function (result) {
        var _a, _b, _c, _d;
        apm.addLabels({
            fingerprint: result.visitorId,
            plugins: Array.from(((_a = result.components.plugins) === null || _a === void 0 ? void 0 : _a.value) || []).map(function (plugin) { return plugin.name; }).join(', '),
            screenResolution: Array.from(((_b = result.components.screenResolution) === null || _b === void 0 ? void 0 : _b.value) || []).join('x'),
            languages: Array.from(((_c = result.components.languages) === null || _c === void 0 ? void 0 : _c.value) || []).join(', '),
            timezone: ((_d = result.components.timezone) === null || _d === void 0 ? void 0 : _d.value) || '',
            score: result.confidence.score,
        });
    });
});
export default apm;
