// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VBCK4D2sAe1hkhKih4QD {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
}

.d62cVWQTdYXT45F93HPh span {
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 4px;
}

.d62cVWQTdYXT45F93HPh {
  font-size: 18px;
  margin: 0 0 8px;
}

.p8Y3xzXkQHHjKiZ3UZtr {
  border-radius: 4px;
  padding: 2px 8px;
}

.kktzDLUE2QS2o5o8BKW_ {
  background-color: #ebfaed;
  color: #48a050;
}

.qU_YrcDzAqcQNka0dnPZ {
  background-color: #fadcdc;
  color: #931f1f;
}

.ohaXRRt3BrewjIrkH959 {
  background-color: #fdf1c5;
  color: #b6962b;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelVerificationAppropriateness/TunnelVerificationAppropriateness.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".title {\n  font-family: Roboto, sans-serif;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  margin-bottom: 8px;\n}\n\n.results span {\n  font-weight: 500;\n  margin-bottom: 0;\n  margin-right: 4px;\n}\n\n.results {\n  font-size: 18px;\n  margin: 0 0 8px;\n}\n\n.pill {\n  border-radius: 4px;\n  padding: 2px 8px;\n}\n\n.pass {\n  background-color: #ebfaed;\n  color: #48a050;\n}\n\n.fail {\n  background-color: #fadcdc;\n  color: #931f1f;\n}\n\n.low {\n  background-color: #fdf1c5;\n  color: #b6962b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `VBCK4D2sAe1hkhKih4QD`,
	"results": `d62cVWQTdYXT45F93HPh`,
	"pill": `p8Y3xzXkQHHjKiZ3UZtr`,
	"pass": `kktzDLUE2QS2o5o8BKW_`,
	"fail": `qU_YrcDzAqcQNka0dnPZ`,
	"low": `ohaXRRt3BrewjIrkH959`
};
export default ___CSS_LOADER_EXPORT___;
