// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qsh3x7Q6Bj5tuzfxSf6w {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  column-gap: 8px;
  display: flex;
  margin-bottom: 8px;
}

.azEKN2gxLLCQTqcSxnRn {
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 5px;
  box-sizing: border-box;
  height: 42px;
  outline: none;
  padding: 4px;
  transition: 0.3s;
}

.azEKN2gxLLCQTqcSxnRn:hover,
.azEKN2gxLLCQTqcSxnRn:focus {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

.azEKN2gxLLCQTqcSxnRn:focus {
  box-shadow: 0 0 0 2px rgb(0 51 255 / 25%);
}

.azEKN2gxLLCQTqcSxnRn[disabled] {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray-light"]};
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  opacity: 0.7;
}

.azEKN2gxLLCQTqcSxnRn[disabled]:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
}

.wHgNRwkvjQNthXu0vDLU {
  column-gap: 8px;
  padding: 0;
}

.I0ZSVuCIbhmAN1tZAhkL {
  column-gap: 16px;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  margin-bottom: 16px;
  row-gap: 16px;
}

.N1YrS8mMJRn31pPzvk0X {
  height: 250px;
  width: 500px;
}

.W7aMqYXPesXOxbj8QXtt {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]};
  border-radius: 4px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-dark"]};
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 8px;
  padding: 4px 8px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerDocuments/CustomerDocuments.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,mDAAwB;EACxB,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,8DAA4B;EAC5B,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;EAEE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,8DAAkC;EAClC,mDAAwB;EACxB,YAAY;AACd;;AAEA;EACE,0DAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,8DAAmC;EACnC,kBAAkB;EAClB,mDAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-gray, color-gray-light, color-purple, color-error-light, color-error-dark from colors;\n\n.label {\n  align-items: center;\n  color: color-text-subtle;\n  column-gap: 8px;\n  display: flex;\n  margin-bottom: 8px;\n}\n\n.input {\n  border: 1px solid color-gray;\n  border-radius: 5px;\n  box-sizing: border-box;\n  height: 42px;\n  outline: none;\n  padding: 4px;\n  transition: 0.3s;\n}\n\n.input:hover,\n.input:focus {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n.input:focus {\n  box-shadow: 0 0 0 2px rgb(0 51 255 / 25%);\n}\n\n.input[disabled] {\n  background-color: color-gray-light;\n  color: color-text-subtle;\n  opacity: 0.7;\n}\n\n.input[disabled]:hover {\n  border-color: color-gray;\n}\n\n.button {\n  column-gap: 8px;\n  padding: 0;\n}\n\n.row {\n  column-gap: 16px;\n  display: flex;\n  flex-flow: wrap;\n  flex-direction: column;\n  margin-bottom: 16px;\n  row-gap: 16px;\n}\n\n.card {\n  height: 250px;\n  width: 500px;\n}\n\n.error {\n  background-color: color-error-light;\n  border-radius: 4px;\n  color: color-error-dark;\n  font-size: 14px;\n  font-weight: 500;\n  margin: 0 0 0 8px;\n  padding: 4px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-gray-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray-light"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-error-light": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-light"]}`,
	"color-error-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error-dark"]}`,
	"label": `Qsh3x7Q6Bj5tuzfxSf6w`,
	"input": `azEKN2gxLLCQTqcSxnRn`,
	"button": `wHgNRwkvjQNthXu0vDLU`,
	"row": `I0ZSVuCIbhmAN1tZAhkL`,
	"card": `N1YrS8mMJRn31pPzvk0X`,
	"error": `W7aMqYXPesXOxbj8QXtt`
};
export default ___CSS_LOADER_EXPORT___;
