// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dqZMZQgka9bmfdfxJbhe {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.tmq7hfLuAdtdttnTA_ea {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}

.poi1Hx0xFVUq3kXrI3rL { margin: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationCustomerCare/ValidationCustomerCare.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,mDAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA,wBAAW,SAAS,EAAE","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-error from colors;\n\n.error {\n  align-items: center;\n  color: color-error;\n  display: flex;\n  flex-direction: row;\n  gap: 0.25rem;\n}\n\n.loading {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  gap: 0.25rem;\n}\n\n.spinner { margin: 0; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"error": `dqZMZQgka9bmfdfxJbhe`,
	"loading": `tmq7hfLuAdtdttnTA_ea`,
	"spinner": `poi1Hx0xFVUq3kXrI3rL`
};
export default ___CSS_LOADER_EXPORT___;
