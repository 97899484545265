import { keysToCamel } from 'utils/converter';
export function reservationsAdaptor(data) {
    var formattedData = keysToCamel(data);
    var amount = formattedData.reduce(function (acc, project) { return acc + (project.status === 'succeeded' ? (project.amount || 0) : 0); }, 0);
    return {
        total: amount,
        list: formattedData.map(function (project, key) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
            return ({
                id: key,
                amount: project.amount || 0,
                status: project.status,
                date: project.dateMoneyReceived,
                idProject: project.transaction.idProject,
                society: ((_a = project.company) === null || _a === void 0 ? void 0 : _a.societyName) || '',
                owner: "".concat((_b = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _b === void 0 ? void 0 : _b.name, " (").concat((_c = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _c === void 0 ? void 0 : _c.email, ")") || 'N/A',
                accountCountry: ((_d = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _d === void 0 ? void 0 : _d.country) || 'N/A',
                paymentMethod: {
                    type: project.paymentMethod,
                    bankCode: ((_e = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _e === void 0 ? void 0 : _e.bankCode) || 'N/A',
                    brancheCode: ((_f = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _f === void 0 ? void 0 : _f.branchCode) || 'N/A',
                    brand: ((_g = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _g === void 0 ? void 0 : _g.brand) || 'N/A',
                    last4: ((_h = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _h === void 0 ? void 0 : _h.last4) || 'N/A',
                    expMonth: ((_j = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _j === void 0 ? void 0 : _j.expMonth) || 'N/A',
                    expYear: ((_k = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _k === void 0 ? void 0 : _k.expYear) || 'N/A',
                },
                billingAddress: {
                    address: ((_m = (_l = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _l === void 0 ? void 0 : _l.billingAddress) === null || _m === void 0 ? void 0 : _m.address) || 'N/A',
                    city: ((_p = (_o = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _o === void 0 ? void 0 : _o.billingAddress) === null || _p === void 0 ? void 0 : _p.city) || 'N/A',
                    country: ((_r = (_q = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _q === void 0 ? void 0 : _q.billingAddress) === null || _r === void 0 ? void 0 : _r.country) || 'N/A',
                    zipCode: ((_t = (_s = project === null || project === void 0 ? void 0 : project.paymentMethodDetails) === null || _s === void 0 ? void 0 : _s.billingAddress) === null || _t === void 0 ? void 0 : _t.zipCode) || 'N/A',
                },
            });
        }),
    };
}
