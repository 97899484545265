export var AUTHORIZED_COUNTRY = ['BELGIUM', 'CYPRUS', 'FRANCE'];
export var DOCUMENTS_TABLE = {
    CY: {
        incorporationDocument: 'incorporation',
        shareholders: 'shareholders',
        directors: 'directors',
        registeredOffice: 'registeredOffice',
        memorandum: 'memorandum',
        board: 'board',
    },
    FR: {
        incorporationDocument: 'kbis',
        shareholders: 'operational_status',
        directors: false,
        registeredOffice: false,
        memorandum: false,
        board: 'board',
    },
    BE: {
        incorporationDocument: 'bce',
        shareholders: 'association',
        directors: false,
        registeredOffice: false,
        memorandum: false,
        board: 'board',
    },
};
/**
 * Return a list of missing document
 *
 * To be considered as complete, documents need to have, depending on the country
 *
 * @param {Document[]} documents
 * @return {string[]}
 */
export var getEntityMissingDocuments = function (country, data) {
    var documentsNeeded = Object
        .values(DOCUMENTS_TABLE[country.toUpperCase() || 'FR'])
        .filter(function (value) { return value !== false; });
    if (!data)
        return documentsNeeded;
    return documentsNeeded
        .filter(function (value) { return (data.find(function (doc) { return doc.type === value; }) !== undefined); });
};
