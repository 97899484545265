var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var USER_DOCUMENT_TYPES = ['home_certificate', 'passport', 'id_card_recto', 'id_card_verso'];
/**
 * Return a list of missing document
 *
 * To be considered as complete, documents need to have :
 * - 1 document with type home_certificate
 * - 1 document with the type passport or 2 documents with id_card_recto & id_card_verso
 *
 * @param {Document[]} documents
 * @return {string[]}
 */
// eslint-disable-next-line import/prefer-default-export
export var getUserMissingDocuments = function (documents) {
    if (!documents)
        return USER_DOCUMENT_TYPES;
    var documentsAvailable = documents.map(function (document) { return document.type; });
    var hasHomeCertificate = documentsAvailable.includes('home_certificate');
    var hasIdentity = documentsAvailable.includes('passport')
        || (documentsAvailable.includes('id_card_recto') && documentsAvailable.includes('id_card_verso'));
    if (hasHomeCertificate && hasIdentity)
        return [];
    return __spreadArray(__spreadArray([], (!hasHomeCertificate ? ['home_certificate'] : []), true), (!hasIdentity
        ? ['passport', 'id_card_recto', 'id_card_verso'].filter(function (document) { return !documentsAvailable.includes(document); })
        : []), true);
};
