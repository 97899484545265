var answers = {
    suitability: {
        title: 'Investor profile',
        description: 'As of last-to be compliant with laws on anti-money laundering and “know your client” principles please provide following information:\n\n Note: records provided by the customer are confidential and are not made public to third parties without the customer’s written consent, except for the statutory cases of governmental authorities, when the data must be disclosed to the competent authorities or persons. Your answers will not affect the terms or amount of investment opportunities offered.\n',
        purposeOfInvestment: {
            label: 'Purpose of investment :',
            long: 'Long term return (4)',
            short: 'Short term return (3)',
            additional: 'Additional revenues (2)',
            income: 'Income (1)',
        },
        plannedInvestmentPerMonth: {
            label: 'Planned investment (per year) :',
            less10k: '€0 - €9,999',
            less100k: '€10,000 - €99,999',
            more100k: '€100,000 ou plus',
        },
        occupation: {
            label: 'Occupation :',
            owner: 'Owner (4)',
            senior: 'Senior Management / Executive Management (3)',
            middle: 'Middle Management / Section Head (2)',
            line: 'Line Management / Assistant Manager (1)',
            staff: 'Staff (1)',
            other: 'Other (1)',
        },
        annualIncome: {
            label: 'Average annual income',
            none: 'Less than €50,000',
            less50k: '€50,001 - €100,000',
            less100k: '€100,001 - €500,000',
            less500k: '€500,001 - €1,000,000',
            less1m: 'More than €1,000,000',
        },
        source: {
            label: 'Main source of funds :',
            salary: 'Salary',
            freelancing: 'Income from freelancing',
            pension: 'Pension',
            scholarship: 'Scholarship',
            social: 'Social Benefits',
            business: 'Income from business (including dividends)',
            parental: 'Parental support',
            inheritance: 'Inheritance',
            sale: 'Income from sale of assets',
        },
        levelOfRisk: {
            label: 'What level of risk are you willing to accept in trading stocks/bonds?',
            all: 'All of it. I am a risk taker (4)',
            most: 'Most of it. I am happy to accept considerable risk (3)',
            some: 'Some of it. I am happy to accept a little risk (2)',
            none: "None of it. I can't accept to lose any money (Negative Target Market)",
        },
        levelOfEducation: {
            label: 'Education level',
            doctorate: 'Doctorate (4)',
            master: "Master's degree (3)",
            bachelor: "Bachelor's degree (2)",
            highschool: 'High school (1)',
            secondary: 'Secondary (0)',
            no: 'No education (Negative Target Market)',
        },
        employmentPosition: {
            label: 'Employment status',
            employed: 'Employed (4)',
            selfEmployed: 'Self employed (3)',
            student: 'Student (2)',
            pensioner: 'Pensioner (1)',
            unemployed: 'Unemployed (Negative Target Market)',
        },
        currentNetPortfolio: {
            label: 'What’s the current net worth of your investor portfolio?',
            less100k: 'Less than €100K',
            100: '€100k - €1 million',
            over: 'Over €1 million',
        },
        sectorOccupation: {
            banking: 'Financial services - Banking (4)',
            insurance: 'Financial services - Insurance (3)',
            accountancy: 'Business Consulting - Accountancy (2)',
            legal: '1',
        },
    },
    appropriateness: {
        title: 'Relevant knowledge & experience',
        note: 'Note: records provided by the customer are confidential and are not made public to third parties without the customer’s written consent, except for the statutory cases of governmental authorities, when the data must be disclosed to the competent authorities or persons. Your answers will not affect the terms or amount of investment opportunities offered.',
        finance: {
            label: 'Are you currently or have you previously been employed as a professional in the Financial Markets/Services industry?',
            yes: 'Yes',
            no: 'No',
        },
        goalInvestmentByAyomi: {
            label: 'What is your main goal when investing through the Ayomi investment platform?',
            diversify: 'I’m looking for an investment instrument to lower risks through diversifying my portfolio (4)',
            instrument: 'I’m looking for an investment instrument with exposure to transferrable securities without having any work to do for the company, nor an account to render. (3)',
            maximize: 'I’m looking to maximize the individual returns of my investments (2)',
            learn: 'Using Ayomi to learn about investing in general and the risks associated with it (1)',
        },
        experienceTrading: {
            label: 'What type of experience do you have that relates to trading?',
            experience: 'I have relevant education and/or professional experience (4)',
            trading: 'I have relevant trading experience (3)',
            seminar: 'I have attended trading seminars and/or webinars (2)',
            read: 'I regularly monitor market news and read educational material on trading (1)',
            none: 'I have no knowledge in investing (Negative Target Market)  ',
        },
        investmentType: {
            crowdfunding: 'I participated in a crowdfunding campaign (4)',
            trading: 'I invest through an online trading platform (3)',
            ownInvestmentAccount: 'I own an investment account (2)',
            companyOwner: 'I created my own company (1)',
            noInvestment: 'I made no investment (0)',
        },
        tradingSizeByYear: {
            label: 'Have you made any significant transactions in a relevant financial market in the last 12 months?',
            more10: 'More than 10 per quarter (2)',
            less10: 'Less than 10 per quarter (1)',
            none: '0',
        },
        transferableSecurities: {
            label: 'What are Transferable Securities?',
            true: 'Transferable securities are financial instruments that can be readily exchanged between two parties (1)',
            false: 'Transferable securities are an instrument of indebtedness of the bond issuer to the holders (0)',
        },
        paidUpShare: {
            label: 'What is paid-up value of shares?',
            true: 'The actual amount paid by the shareholder for one share (1)',
            false: 'The value at which the issuer will price the share (0)',
        },
    },
};
export default answers;
