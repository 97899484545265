// @ts-nocheck
import { useEffect, useRef } from 'react';
export default function useOutsideClickAlert(ref, handler, variable) {
    if (variable === void 0) { variable = null; }
    var callbackRef = useRef();
    callbackRef.current = handler;
    useEffect(function () {
        function handleClickOutside(event) {
            var _a;
            if (callbackRef.current && !((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
                callbackRef.current(event);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside, true);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [ref, variable]);
}
