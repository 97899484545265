// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RMYFLgPr0C5TFLvdH6ym {
  position: relative;
}

.F4bsZCF7rDR3hKCIcUAw {
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  bottom: 32px;
  padding: 4px 8px;
  position: absolute;
  right: 0;
  width: max-content;
  z-index: 10;
}

.F4bsZCF7rDR3hKCIcUAw p {
  margin: 0;
}

.QxbZo8UwhI7Uwkvdis35 {
  max-width: 320px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ButtonTooltip/ButtonTooltip.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".container {\n  position: relative;\n}\n\n.tooltip {\n  background-color: white;\n  border: 1px solid #e5e5e5;\n  border-radius: 5px;\n  bottom: 32px;\n  padding: 4px 8px;\n  position: absolute;\n  right: 0;\n  width: max-content;\n  z-index: 10;\n}\n\n.tooltip p {\n  margin: 0;\n}\n\n.content {\n  max-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RMYFLgPr0C5TFLvdH6ym`,
	"tooltip": `F4bsZCF7rDR3hKCIcUAw`,
	"content": `QxbZo8UwhI7Uwkvdis35`
};
export default ___CSS_LOADER_EXPORT___;
