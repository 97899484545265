// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RwECp6LkvAF_uU2rRjJ5 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.RwECp6LkvAF_uU2rRjJ5 > div:first-child {
  width: 47%;
}

.XDY58eoCPNn9SSoIGugP {
  width: 47%;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelVerificationUser/TunnelVerificationUser.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.row > div:first-child {\n  width: 47%;\n}\n\n.documents {\n  width: 47%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `RwECp6LkvAF_uU2rRjJ5`,
	"documents": `XDY58eoCPNn9SSoIGugP`
};
export default ___CSS_LOADER_EXPORT___;
