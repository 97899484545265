var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import styles from './FiltersStatus.module.css';
export default function FiltersStatus() {
    var _a, _b, _c, _d;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var updateSelection = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                status: (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected.status || []), true), [e.target.value], false) : (selected.status || []).filter(function (value) { return value !== e.target.value; }),
            },
        });
    };
    return (_jsxs("div", { className: styles.dropdown, children: [_jsxs("label", { htmlFor: "status-incomplete", className: styles.option, children: [_jsx("input", { type: "checkbox", id: "status-incomplete", value: "incomplete", checked: ((_a = selected.status) === null || _a === void 0 ? void 0 : _a.includes('incomplete')) || false, onChange: updateSelection }), "Incomplete"] }), _jsxs("label", { htmlFor: "status-pending", className: styles.option, children: [_jsx("input", { type: "checkbox", id: "status-pending", value: "pending", checked: ((_b = selected.status) === null || _b === void 0 ? void 0 : _b.includes('pending')) || false, onChange: updateSelection }), "Pending"] }), _jsxs("label", { htmlFor: "status-validated", className: styles.option, children: [_jsx("input", { type: "checkbox", id: "status-validated", value: "validated", checked: ((_c = selected.status) === null || _c === void 0 ? void 0 : _c.includes('validated')) || false, onChange: updateSelection }), "Validated"] }), _jsxs("label", { htmlFor: "status-rejected", className: styles.option, children: [_jsx("input", { type: "checkbox", id: "status-rejected", value: "rejected", checked: ((_d = selected.status) === null || _d === void 0 ? void 0 : _d.includes('rejected')) || false, onChange: updateSelection }), "Rejected"] })] }));
}
