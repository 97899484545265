// helpers constants
export var PERSON_CATEGORY = {
    pep: 'Politically Exposed Person',
    us_resident: 'US Reportable Person',
    // watchlist: 'Watch list person',
    // highRisk: 'High risk client',
};
export var TIME_RANGE = {
    1: 'Last day',
    7: 'Last 7 days',
    30: 'Last 30 days',
    custom: 'Custom',
    all: 'All time',
};
export var FILE_ENTITY = ['kbis', 'operational_status', 'association', 'bce', 'board', 'directors', 'incorporation', 'memorandum', 'registeredOffice', 'shareholders'];
export var RISK_LEVEL = {
    low: 'Low risk',
    normal: 'Normal risk',
    high: 'High risk',
};
export var RISK_REASONS = {
    pep: 'PEP\'s account',
    country: 'Clients from high-risk third countries',
    bank: 'Cross-frontier correspondent banking relationships with credit institutions-Clients from third countries',
    society: 'Any other client determined by the company itself to be classified as such',
    others: 'Others',
};
export var KYC_STATUS = {
    all: 'All',
    incomplete: 'Incomplete',
    pending: 'Pending back-office review',
    validated: 'Validated',
    rejected: 'Rejected',
};
export var USER_INPUTS = {
    'user.address.address': 'General information - Address',
    'user.address.city': 'General information - City',
    'user.address.country': 'General information - Country',
    'user.address.streetNumber': 'General information - Street Number',
    'user.address.zipCode': 'General information - Zip Code',
    'user.addressBirth.streetNumber': 'General information - Address birth - Street Number',
    'user.addressBirth.address': 'General information - Address birth - Address',
    'user.addressBirth.zipCode': 'General information - Address birth - Zip code',
    'user.addressBirth.city': 'General information - Address birth - City',
    'user.addressBirth.country': 'General information - Address birth - Country',
    'user.birthday': 'General information - Birthday',
    'user.email': 'General information - email',
    'user.firstName': 'General information - First name',
    'user.lastName': 'General information - Last name',
    'user.gender': 'General information - Gender',
    'user.title': 'General information - Title',
    'user.nationality': 'General information - Nationality',
    'user.number': 'General information - Phone number',
    'user.usResident': 'General information - US Resident',
    'user.pep': 'General information - PEP',
    'user.identity': 'Documents - Identity card',
    'user.proofOfAddress': 'Documents - Proof of address',
    'user.addressBirth': 'General information - Birthplace',
    'user.contactAddress': 'General information - Contact address',
    'entity.siren': 'Company information - Registration Number',
    'entity.legalName': 'Company information - Legal Name',
    'entity.societyName': 'Company information - Society name',
    'entity.activity': 'Company information - Activity',
    'entity.legalForm': 'Company information - Legal Form',
    'entity.startingExercise': 'Company information - Starting exercise',
    'entity.country': 'Company information - Country',
    'entity.city': 'Company information - City',
    'entity.zipCode': 'Company information - Zip code',
    'entity.address': 'Company information - Address',
    'entity.streetNumber': 'Company information - Street number',
    'entity.kbis': 'Company information - KBIS',
    'entity.operationalStatus': 'Company information - operational status',
    'entity.association': 'Company information - association',
    'entity.bce': 'Company information - Board of directors',
    'entity.directors': 'Company information - directors',
    'entity.incorporation': 'Company information - Certificate of incorporation',
    'entity.memorandum': 'Company information - memorandum',
    'entity.registeredOffice': 'Company information - registered office',
    'entity.shareholders': 'Company information - shareholders',
    'entity.companyNumber': 'Company information - Company number',
    'entity.companyEmail': 'Company information - Company email',
    'entity.incorporationForm': 'Company information - Incorporation form',
    'entity.leiStatus': 'Company information - LEI status',
    'entity.lei': 'Company information - LEI',
    'entity.financialInstitution': 'Company information - Financial institution',
    'entity.institutionType': 'Company information - Financial Institution - Type',
    'entity.GIIN': 'Company information - Financial Institution - GIIN',
    'entity.authority': 'Company information - Financial Institution - Authority',
    'entity.homeCountry': 'Company information - Financial Institution - Home country',
    'entity.license': 'Company information - Financial Institution - License',
    'entity.regulatoryStatus': 'Company information - Financial Institution - Regulatory status',
    'entity.regulatoryWebsite': 'Company information - Financial Institution - Regulatory website',
    'entity.companyGroup': 'Company information - Company group',
    'entity.companyGroupCountry': 'Company information - Company group - Country',
    'entity.parentCompanyGroup': 'Company information - Company group - Parent company group',
    'entity.subsidiaryCompanies': 'Company information - Company group - Subsidiary companies',
    'entity.UBO': 'Company information - UBO',
    'entity.otherLicense': 'Company information - Other license',
    'entity.otherLicenseName': 'Company information - Other license - Name',
    'entity.otherLicenseNumber': 'Company information - Other license - Number',
    'entity.otherLicenseAuthority': 'Company information - Other license - Authority',
    'entity.otherLicenseCountry': 'Company information - Other license - Country',
    'entity.website': 'Company information - Website',
};
