// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wQ6DVbA5H9nLZgcf_h3R {
  font-size: 24px;
  font-weight: 500;
  margin: 24px 0 18px;
}

.g4koi_76lNtEgckzuYJE {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.vvxlPwq8I4D6AGv_XDVb .wQ6DVbA5H9nLZgcf_h3R {
  font-size: 18px;
  margin: 0;
}

.vvxlPwq8I4D6AGv_XDVb .ttB5o96v5K3aR_Jdwgrm {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 16px;
  margin: 0;
}

.wdrW8MF_BBzV3lqA1cwE {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jBqxqUubEoopZPHNdUfT {
  align-items: center;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border-radius: 32px;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  display: flex;
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  justify-content: center;
  min-width: 32px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelSummary/TunnelSummaryActivities/TunnelSummaryActivities.module.css"],"names":[],"mappings":"AAGA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,mDAAwB;EACxB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,8DAAiC;EACjC,mBAAmB;EACnB,mDAAmB;EACnB,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple-bg, color-purple from colors;\n\n.title {\n  font-size: 24px;\n  font-weight: 500;\n  margin: 24px 0 18px;\n}\n\n.activity {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.group .title {\n  font-size: 18px;\n  margin: 0;\n}\n\n.group .description {\n  color: color-text-subtle;\n  font-size: 16px;\n  margin: 0;\n}\n\n.list {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.logo {\n  align-items: center;\n  background-color: color-purple-bg;\n  border-radius: 32px;\n  color: color-purple;\n  display: flex;\n  font-size: 16px;\n  font-weight: 500;\n  height: 32px;\n  justify-content: center;\n  min-width: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"title": `wQ6DVbA5H9nLZgcf_h3R`,
	"activity": `g4koi_76lNtEgckzuYJE`,
	"group": `vvxlPwq8I4D6AGv_XDVb`,
	"description": `ttB5o96v5K3aR_Jdwgrm`,
	"list": `wdrW8MF_BBzV3lqA1cwE`,
	"logo": `jBqxqUubEoopZPHNdUfT`
};
export default ___CSS_LOADER_EXPORT___;
