// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.udJwWQAHTQNgVfmp3HQA {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  column-gap: 0.5rem;
  display: flex;
  flex-direction: row;
}

.iUbahDqRFxvc0j_8c06U {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border-radius: 5px;
  padding: 1rem;
}

.MTHyfVdkh2PS57C2RihZ {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.Llf_TBUVddmyaEl1XVY0 {
  font-weight: 500;
  margin-left: 4px;
}

.xKDG4iJqLcdaV6c89IQL {
  border: none;
  border-top: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
}

.Hlc8ZMFcEI9_31o0DkzW {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerInvestments/CustomerInvestments.module.css"],"names":[],"mappings":"AAGA;EACE,mDAAwB;EACxB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,8DAAiC;EACjC,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kEAAgC;AAClC;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle, color-purple-bg, color-gray from colors;\n\n.loading {\n  color: color-text-subtle;\n  column-gap: 0.5rem;\n  display: flex;\n  flex-direction: row;\n}\n\n.overview {\n  background-color: color-purple-bg;\n  border-radius: 5px;\n  padding: 1rem;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 500;\n  margin: 0;\n}\n\n.amount {\n  font-weight: 500;\n  margin-left: 4px;\n}\n\n.border {\n  border: none;\n  border-top: 1px solid color-gray;\n}\n\n.total {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"loading": `udJwWQAHTQNgVfmp3HQA`,
	"overview": `iUbahDqRFxvc0j_8c06U`,
	"title": `MTHyfVdkh2PS57C2RihZ`,
	"amount": `Llf_TBUVddmyaEl1XVY0`,
	"border": `xKDG4iJqLcdaV6c89IQL`,
	"total": `Hlc8ZMFcEI9_31o0DkzW`
};
export default ___CSS_LOADER_EXPORT___;
