import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useAPI from 'hooks/useAPI';
import { reservationsAdaptor } from 'services/network/adaptor/payment';
import { getReservationByIss } from 'services/network/api/payment';
import * as statuses from 'services/store/states';
import styles from './CustomerInvestments.module.css';
import CustomerInvestmentsReservation from './CustomerInvestmentsReservation';
export default function CustomerInvestments(_a) {
    var iss = _a.iss;
    var reservations = useAPI({
        request: function (source) { return getReservationByIss(iss, source); },
        adaptor: reservationsAdaptor,
    });
    if (reservations.status === statuses.FAIL)
        return null;
    if (reservations.status === statuses.IDLE || reservations.status === statuses.LOADING) {
        return (_jsx("div", { children: _jsxs("p", { className: styles.loading, children: [_jsx("i", { className: "gg-spinner" }), "Search user's investment..."] }) }));
    }
    return (_jsxs("div", { className: styles.overview, children: [_jsxs("div", { className: styles.row, children: [_jsx("p", { className: styles.title, children: "Investments overview" }), _jsxs("p", { className: styles.total, children: ["Total amount invested :", _jsxs("span", { className: styles.amount, children: [reservations.data.total.toLocaleString(), "\u20AC"] })] })] }), _jsx("div", { children: reservations.data.list.map(function (reservation) { return (_jsx(CustomerInvestmentsReservation, { reservation: reservation }, reservation.id)); }) })] }));
}
