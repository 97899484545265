var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Event } from '@mui/icons-material';
import { debounce } from '@mui/material';
import { TIME_RANGE } from 'constants/kyc';
import Button from 'components/library/Button';
import Dropdown from 'components/library/Dropdown';
import FiltersGroup from 'components/Filters/FiltersGroup';
import { fromDateToInput, fromInputToDate, getStartDate } from 'utils/date';
import { initEndDate, initStartDate } from 'utils/initFilters';
import styles from './SearchTabFilters.module.css';
import Export from './Export';
export default function SearchTabFilters(_a) {
    var type = _a.type, filters = _a.filters, onSubmit = _a.onSubmit;
    var _b = useState(filters.q || ''), q = _b[0], setQuery = _b[1];
    var _c = useState(window.localStorage.getItem('timeRange') || '7'), timeRange = _c[0], setTimeRange = _c[1];
    var _d = useState(initStartDate()), start = _d[0], setStart = _d[1];
    var _e = useState(initEndDate()), end = _e[0], setEnd = _e[1];
    var debouncedSubmit = useCallback(debounce(function (newFilters) { return onSubmit(newFilters); }, 500), [onSubmit]);
    var handleQuery = function (e) { return setQuery(e.target.value); };
    var handleTimeRangeCheck = function (e) {
        var newTimeRange = e.target.name;
        var newFilters = { q: (q === '') ? undefined : q };
        setTimeRange(newTimeRange);
        if (newTimeRange !== 'custom' && newTimeRange !== 'all') {
            setStart(fromDateToInput(getStartDate(new Date(), Number.parseInt(newTimeRange, 10))));
            setEnd(fromDateToInput(getStartDate(new Date(), -1)));
            newFilters = __assign(__assign({}, newFilters), { updated__ge: getStartDate(new Date(), Number.parseInt(newTimeRange, 10)), updated__le: getStartDate(new Date(), -1) });
        }
        window.localStorage.setItem('timeRange', newTimeRange);
        debouncedSubmit(newFilters);
    };
    var handleSubmit = function () {
        debouncedSubmit(__assign({ q: (q === '') ? undefined : q }, ((timeRange !== 'all' && start && end)
            ? {
                updated__ge: fromInputToDate(start),
                updated__le: fromInputToDate(end),
            }
            : {})));
    };
    var handleCancel = function () {
        setQuery('');
        setStart(fromDateToInput(getStartDate(new Date(), 7)));
        setEnd(fromDateToInput(new Date()));
        onSubmit({
            q: undefined,
            updated__ge: getStartDate(new Date(), 7),
            updated__le: new Date(),
        });
        window.localStorage.setItem('timeRange', timeRange);
    };
    var handleStart = function (e) {
        setStart(e.target.value);
        debouncedSubmit(__assign({ q: (q === '') ? undefined : q }, ((timeRange !== 'all' && start && end)
            ? {
                updated__ge: fromInputToDate(e.target.value),
                updated__le: fromInputToDate(end),
            }
            : {})));
        window.localStorage.setItem('startDate', e.target.value);
    };
    var handleEnd = function (e) {
        setEnd(e.target.value);
        debouncedSubmit(__assign({ q: (q === '') ? undefined : q }, ((timeRange !== 'all' && start && end)
            ? {
                updated__ge: fromInputToDate(start),
                updated__le: fromInputToDate(e.target.value),
            }
            : {})));
        window.localStorage.setItem('endDate', e.target.value);
    };
    return (_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.toolbar, children: [_jsxs(Dropdown, { value: TIME_RANGE[timeRange], icon: _jsx(Event, { className: styles.icon }), children: [_jsx("p", { className: styles.categories, children: "Time range" }), _jsx("div", { className: styles.list, children: Object.keys(TIME_RANGE).map(function (day) { return (_jsxs("label", { htmlFor: day, className: styles.clickable, children: [_jsx("input", { type: "radio", id: day, name: day, checked: timeRange === day, onChange: handleTimeRangeCheck }), TIME_RANGE[day]] }, day)); }) }), (timeRange === 'custom')
                                && (_jsxs(_Fragment, { children: [_jsxs("label", { htmlFor: "start", className: styles.date, children: [_jsx("span", { children: "From" }), _jsx("input", { type: "date", name: "start", value: start, onChange: handleStart })] }), _jsxs("label", { htmlFor: "end", className: styles.date, children: [_jsx("span", { children: "To" }), _jsx("input", { type: "date", name: "end", value: end, onChange: handleEnd })] })] }))] }), _jsx("input", { type: "text", name: "q", placeholder: "Search a customer by email or Account ID", value: q, onChange: handleQuery, className: styles.searchbar }), (filters.q && filters.q !== '')
                        ? (_jsx(Button, { variant: "outline", onClick: handleCancel, className: styles.cancel, children: "Cancel" }))
                        : (_jsx(Button, { onClick: handleSubmit, className: styles.button, children: "Search" })), _jsx(FiltersGroup, { type: type })] }), _jsx(Export, { filters: __assign({ q: (q === '') ? undefined : q }, ((timeRange !== 'all' && start && end)
                    ? {
                        updated__ge: fromInputToDate(start),
                        updated__le: fromInputToDate(end),
                    }
                    : {})), type: type })] }));
}
