var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import Button from 'components/library/Button';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import { MINIMUM_APPROPRIATENESS_SCORE } from 'constants/testsScore';
import { getUserAnswersByIss } from 'services/network/api/users';
import { getSuitabilityResult } from 'services/controller/userTest';
import useAPI from 'hooks/useAPI';
import * as statuses from 'services/store/states';
import answers from 'services/network/adaptor/CustomerTestAnswers';
import styles from './CustomerTests.module.css';
export default function CustomerTests(_a) {
    var iss = _a.iss;
    var _b = useState(false), showAppropriateness = _b[0], setShowAppropriateness = _b[1];
    var _c = useState(false), showSuitability = _c[0], setShowSuitability = _c[1];
    var _d = useAPI({
        request: function (source) { return getUserAnswersByIss(source, iss); },
    }), status = _d.status, data = _d.data;
    var getData = function () {
        var experienceTrading = data.experienceTrading, goalInvestmentByAyomi = data.goalInvestmentByAyomi, paidUpShare = data.paidUpShare, tradingSizeByYear = data.tradingSizeByYear, transferableSecurities = data.transferableSecurities, created = data.created, updated = data.updated, issUser = data.issUser, appropriatenessScore = data.appropriatenessScore, suitabilityScore = data.suitabilityScore, riskAcknowledged = data.riskAcknowledged, suitability = __rest(data, ["experienceTrading", "goalInvestmentByAyomi", "paidUpShare", "tradingSizeByYear", "transferableSecurities", "created", "updated", "issUser", "appropriatenessScore", "suitabilityScore", "riskAcknowledged"]);
        var appropriateness = {
            experienceTrading: experienceTrading,
            goalInvestmentByAyomi: goalInvestmentByAyomi,
            paidUpShare: paidUpShare,
            tradingSizeByYear: tradingSizeByYear,
            transferableSecurities: transferableSecurities,
        };
        return [appropriateness, __assign(__assign({}, suitability), (riskAcknowledged ? { riskAcknowledged: riskAcknowledged } : {}))];
    };
    var getOccupationScore = function (value) {
        if (value === 'banking' || value === 'insurance')
            return 4;
        if (value === 'accountancy' || value === 'business')
            return 3;
        if (value === 'legal')
            return 2;
        return 1;
    };
    var getValue = function (key, value, type) {
        // @ts-ignore
        var valueToDisplay = (answers[type][key] !== undefined && answers[type][key][value])
            || (value === null || value === void 0 ? void 0 : value.toString());
        if ((valueToDisplay === null || valueToDisplay === void 0 ? void 0 : valueToDisplay.toString()) === 'true')
            return 'yes';
        if (key === 'sectorOccupation')
            return "".concat(value === null || value === void 0 ? void 0 : value.toString(), " ").concat((key === 'sectorOccupation') ? "(".concat(getOccupationScore(value), ")") : '');
        return valueToDisplay;
    };
    var getAppropriatenessValue = function (key, value) { return getValue(key, value, 'appropriateness'); };
    var getSuitabilityValue = function (key, value) { return getValue(key, value, 'suitability'); };
    if (status === statuses.LOADING) {
        return (_jsx("p", { children: "Chargement..." }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(DataViewerLayout, { label: "Appropriateness test", status: statuses.SUCCESS, pill: (((data === null || data === void 0 ? void 0 : data.appropriatenessScore) || 0) + 1) > MINIMUM_APPROPRIATENESS_SCORE
                    ? _jsx("p", { className: styles.pass, children: " Pass " })
                    : _jsx("p", { className: styles.fail, children: " Fail " }), children: (function () { return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [_jsx("span", { className: styles.label, children: "Test result :" }), _jsx("span", { children: ((data === null || data === void 0 ? void 0 : data.appropriatenessScore) || 0) })] }), _jsx(Button, { onClick: function () { return setShowAppropriateness(function (state) { return !state; }); }, variant: "ghost", className: styles.show, children: (showAppropriateness)
                                ? 'Hide answers'
                                : 'Show answers' }), (showAppropriateness)
                            && (_jsx(DataViewerSimple, { data: getData()[0], adaptor: getAppropriatenessValue, isEdit: false }))] })); }) }), _jsx(DataViewerLayout, { label: "Suitability test", pill: (_jsx("p", { className: styles[getSuitabilityResult(data === null || data === void 0 ? void 0 : data.suitabilityScore, data === null || data === void 0 ? void 0 : data.levelOfRisk)], children: getSuitabilityResult(data === null || data === void 0 ? void 0 : data.suitabilityScore, data === null || data === void 0 ? void 0 : data.levelOfRisk) })), status: statuses.SUCCESS, children: (function () { return (_jsxs(_Fragment, { children: [_jsxs("p", { children: [_jsx("span", { className: styles.label, children: "Test result :" }), _jsx("span", { children: ((data === null || data === void 0 ? void 0 : data.suitabilityScore) || 0) })] }), _jsx(Button, { onClick: function () { return setShowSuitability(function (state) { return !state; }); }, variant: "ghost", className: styles.show, children: (showSuitability)
                                ? 'Hide answers'
                                : 'Show answers' }), (showSuitability)
                            && (_jsx(DataViewerSimple, { data: getData()[1], isEdit: false, adaptor: getSuitabilityValue }))] })); }) })] }));
}
