import { jsx as _jsx } from "react/jsx-runtime";
import * as statuses from 'services/store/states';
import LoadingSpinner from '../LoadingSpinner';
export default function Loading(_a) {
    var status = _a.status, fallback = _a.fallback, errorFallback = _a.errorFallback, children = _a.children;
    if (status === statuses.SUCCESS && children) {
        return (_jsx("div", { className: "fade-in", children: children }));
    }
    if (status === statuses.LOADING) {
        if (fallback) {
            return fallback;
        }
        return _jsx(LoadingSpinner, {});
    }
    if (status === statuses.FAIL) {
        if (errorFallback) {
            return errorFallback;
        }
    }
    return null;
}
