// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Project expand */

.p3BAPAlMAOjVsRIbmbYt {
  align-items: center;
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

/* Loading state */

.EuILLr8ns5HPojedAvw4 {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

/* Error state */

.xpkQXbmOH09ne8uCQr7d {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

/* Project information */

.Q5N5RyA29GWG9bcJpKxA {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  margin-left: 0.5rem;
}

.W6zxUuZJl5JAuVLfxdnh {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.u8M4fw03AOgwmeMUQFke {
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationProjects/ValidationProjectDetails/ValidationProjectDetails.module.css"],"names":[],"mappings":"AAGA,mBAAmB;;AAEnB;EACE,mBAAmB;EACnB,8DAAiC;EACjC,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;AACb;;AAEA,kBAAkB;;AAElB;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA,gBAAgB;;AAEhB;EACE,mBAAmB;EACnB,mDAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA,wBAAwB;;AAExB;EACE,mDAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple-bg, color-text-subtle, color-error from colors;\n\n/* Project expand */\n\n.project-expand {\n  align-items: center;\n  background-color: color-purple-bg;\n  border-radius: 0.25rem;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-top: 0.5rem;\n  padding: 0.5rem 1rem;\n  width: 100%;\n}\n\n/* Loading state */\n\n.loading {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  margin-top: 0.5rem;\n}\n\n/* Error state */\n\n.error {\n  align-items: center;\n  color: color-error;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  margin-top: 0.5rem;\n}\n\n/* Project information */\n\n.project-id {\n  color: color-text-subtle;\n  margin-left: 0.5rem;\n}\n\n.project-details {\n  padding-left: 1rem;\n  padding-top: 0.5rem;\n}\n\n.title {\n  font-weight: 500;\n  margin-bottom: 0.5rem;\n  margin-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"project-expand": `p3BAPAlMAOjVsRIbmbYt`,
	"loading": `EuILLr8ns5HPojedAvw4`,
	"error": `xpkQXbmOH09ne8uCQr7d`,
	"project-id": `Q5N5RyA29GWG9bcJpKxA`,
	"project-details": `W6zxUuZJl5JAuVLfxdnh`,
	"title": `u8M4fw03AOgwmeMUQFke`
};
export default ___CSS_LOADER_EXPORT___;
