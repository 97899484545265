import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorRounded } from '@mui/icons-material';
import useAPI from 'hooks/useAPI';
import { getReservationsByProject } from 'services/network/api/payment';
import * as statuses from 'services/store/states';
import styles from './ValidationProjectDetailsInvestments.module.css';
export default function ValidationProjectDetailsInvestments(_a) {
    var projectId = _a.projectId;
    var _b = useAPI({
        request: function (source) { return getReservationsByProject(projectId, source); },
    }), status = _b.status, data = _b.data;
    return (_jsxs("div", { className: styles.investments, children: [(status === statuses.LOADING)
                && (_jsxs("p", { className: styles.loading, children: [_jsx("span", { className: "spinner black ".concat(styles.spinner) }), _jsx("span", { children: "Loading reservation..." })] })), (status === statuses.FAIL)
                && (_jsxs("p", { className: styles.error, children: [_jsx(ErrorRounded, { className: styles['error-icon'] }), _jsx("span", { children: "Failed to load reservation" })] })), (status === statuses.SUCCESS)
                && data.length === 0
                && _jsx("p", { className: styles.empty, children: " No reservation found " }), (status === statuses.SUCCESS)
                && data.length > 0
                && (data.map(function (reservation) { return (_jsxs("div", { className: styles.investor, children: [_jsx("div", { className: styles['investor-details'], children: _jsxs("div", { children: [_jsx("p", { className: styles.name, children: "".concat(reservation.person.firstName, " ").concat(reservation.person.lastName) }), _jsx("p", { className: styles.email, children: reservation.email })] }) }), _jsxs("div", { className: styles['investor-details'], children: [_jsx("span", { className: "".concat(styles['kyc-status'], " ").concat(styles[reservation.kycStatus]), children: reservation.kycStatus }), _jsx("p", { className: styles.amount, children: "".concat(reservation.amount, "\u20AC") })] })] }, reservation.email)); }))] }));
}
