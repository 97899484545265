import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import styles from './ValidationSummaryValue.module.css';
export default function ValidationSummaryValue(_a) {
    var label = _a.label, value = _a.value, name = _a.name, onSave = _a.onSave, children = _a.children;
    var _b = useState(false), isEdit = _b[0], setIsEdit = _b[1];
    var edit = function () { return setIsEdit(true); };
    var save = function () {
        onSave();
        setIsEdit(false);
    };
    return (_jsxs("div", { className: styles.input, children: [_jsxs("div", { className: styles.row, children: [_jsx("label", { htmlFor: name, className: styles.label, children: label }), (!isEdit)
                        ? (_jsx("button", { type: "button", onClick: edit, className: styles.edit, "data-testid": "edit", children: _jsx(Edit, { className: styles.icon }) }))
                        : (_jsx("button", { type: "button", onClick: save, className: styles.save, "data-testid": "save", children: "Save" }))] }), (!isEdit)
                ? (_jsx("div", { className: styles.value, children: value }))
                : children] }));
}
