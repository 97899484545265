var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { commentAdaptor, toBackendCommentAdaptor, } from 'services/network/adaptor/comment';
import { createComment, deleteCommentById, updateCommentById } from 'services/network/api/comments';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import { initialState, reducer } from 'components/Validation/ValidationComments/reducer';
import { logError } from 'utils/logs';
export default function TunnelVerificationComments(_a) {
    var _this = this;
    var connectedUserIss = _a.connectedUserIss, iss = _a.iss, idEntity = _a.idEntity, userName = _a.userName, comments = _a.comments, children = _a.children;
    if (!iss)
        return _jsx("p", { children: "No iss provided" });
    var _b = useReducer(reducer, initialState(comments)), state = _b[0], dispatch = _b[1];
    var globalDispatch = useDispatch();
    var markAsResolved = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var id, idAsInt_1, comment, newComment, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    id = (_b = (_a = e.currentTarget) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.id;
                    if (!id) return [3 /*break*/, 6];
                    idAsInt_1 = Number.parseInt(id, 10);
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 4, , 5]);
                    comment = state.comments.find(function (curr) { return curr.id === idAsInt_1; });
                    if (!comment) return [3 /*break*/, 3];
                    newComment = __assign(__assign({}, comment), { id: idAsInt_1, isResolved: true });
                    return [4 /*yield*/, updateCommentById(id, toBackendCommentAdaptor(newComment))];
                case 2:
                    _c.sent();
                    dispatch({ type: 'markAsResolved', payload: idAsInt_1 });
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_1 = _c.sent();
                    logError(err_1);
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to mark the issue as resolved',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to mark the issue as resolved',
                        description: 'Comment ID not found',
                    }));
                    _c.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var handleCreate = function (field, title, text, isVisibleByUser, status, type, callback) { return __awaiter(_this, void 0, void 0, function () {
        var newComment, response, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newComment = {
                        iss: Number.parseInt(iss.toString(), 10),
                        idEntity: idEntity,
                        field: field,
                        title: title,
                        text: text,
                        isVisibleByUser: isVisibleByUser,
                        status: status,
                        writer: {
                            name: userName,
                            iss: connectedUserIss,
                        },
                        isResolved: false,
                        type: type,
                    };
                    return [4 /*yield*/, createComment(toBackendCommentAdaptor(newComment))];
                case 1:
                    response = _a.sent();
                    dispatch({
                        type: 'addComment',
                        payload: commentAdaptor(response.data),
                    });
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    logError(err_2);
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Oops ! Comment publication failed. Try again.',
                        description: getErrorMessage(err_2),
                    }));
                    return [3 /*break*/, 3];
                case 3:
                    if (callback)
                        callback();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var err_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, deleteCommentById(id)];
                case 1:
                    _a.sent();
                    dispatch({ type: 'deleteComment', payload: id });
                    return [3 /*break*/, 3];
                case 2:
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to mark the issue as resolved',
                        description: 'Comment ID not found',
                    }));
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_3 = _a.sent();
                    logError(err_3);
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to delete the comment',
                        description: getErrorMessage(err_3),
                    }));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function (id, text) { return __awaiter(_this, void 0, void 0, function () {
        var commentToEdit, newComment, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    commentToEdit = state.comments.find(function (comment) { return comment.id === id; });
                    if (!commentToEdit) return [3 /*break*/, 2];
                    newComment = __assign(__assign({}, commentToEdit), { text: text });
                    return [4 /*yield*/, updateCommentById(id, toBackendCommentAdaptor(newComment))];
                case 1:
                    _a.sent();
                    dispatch({ type: 'updateComment', payload: { id: id, comment: newComment } });
                    return [3 /*break*/, 3];
                case 2:
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to mark the issue as resolved',
                        description: 'Comment ID not found',
                    }));
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_4 = _a.sent();
                    logError(err_4);
                    globalDispatch(addToast({
                        type: 'error',
                        title: 'Impossible to edit the comment',
                        description: getErrorMessage(err_4),
                    }));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(_Fragment, { children: children(state.comments, handleCreate, handleDelete, handleEdit, markAsResolved) }));
}
