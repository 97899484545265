var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Cancel, CheckCircle, Error, Info, } from '@mui/icons-material';
// eslint-disable-next-line no-unused-vars
var id = 0;
var toastPropertiesByType = function (type, color, icon) {
    var properties = {
        default: {
            icon: _jsx(Info, {}),
            color: '#4F67F3',
        },
        success: {
            icon: _jsx(CheckCircle, {}),
            color: '#00D287',
        },
        warning: {
            icon: _jsx(Error, {}),
            color: '#FBC506',
        },
        error: {
            icon: _jsx(Cancel, {}),
            color: '#F24C27',
        },
        custom: {
            icon: icon,
            color: color,
        },
    };
    return properties[type];
};
var toastFactory = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'default' : _b, title = _a.title, description = _a.description, color = _a.color, icon = _a.icon, _c = _a.autoDeleteTime, autoDeleteTime = _c === void 0 ? true : _c;
    id += 1;
    return __assign(__assign({}, toastPropertiesByType(type, color || '', icon)), { autoDeleteTime: autoDeleteTime, id: id, title: title, description: description });
};
export default toastFactory;
