var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { MINIMUM_APPROPRIATENESS_SCORE, MINIMUM_SUITABILITY_SCORE } from 'constants/testsScore';
import { getAllEntitiesFromPayment, getAllUsersFromPayment } from 'services/network/api/payment';
import { getAllUsers } from 'services/network/api/users';
import { getAllEntities } from 'services/network/api/entity';
import isEmail from './isEmail';
import { logError } from './logs';
export var getQuery = function (q, type) {
    var _a, _b;
    if (type === void 0) { type = 'user'; }
    var trimmedQuery = q === null || q === void 0 ? void 0 : q.trim();
    if (!trimmedQuery || trimmedQuery === '')
        return {};
    var isUser = type === 'user';
    if (isEmail(trimmedQuery))
        return _a = {}, _a[isUser ? 'email' : 'user__email'] = trimmedQuery, _a;
    return (_b = {}, _b[isUser ? 'iss' : 'user__iss'] = trimmedQuery, _b);
};
export var getCategories = function (selected, type) {
    var _a, _b, _c;
    var _d, _e, _f, _g, _h;
    if (type === void 0) { type = 'user'; }
    var isUser = type === 'user';
    var newCategories = {};
    if ((_d = selected.category) === null || _d === void 0 ? void 0 : _d.includes('pep')) {
        newCategories = __assign(__assign({}, newCategories), (_a = {}, _a[isUser ? 'pep' : 'user__pep'] = 1, _a));
    }
    if ((_e = selected.category) === null || _e === void 0 ? void 0 : _e.includes('us_resident')) {
        newCategories = __assign(__assign({}, newCategories), (_b = {}, _b[isUser ? 'us_resident' : 'user__us_resident'] = 1, _b));
    }
    if (((_f = selected.category) === null || _f === void 0 ? void 0 : _f.includes('retail'))
        || ((_g = selected.category) === null || _g === void 0 ? void 0 : _g.includes('individual'))
        || ((_h = selected.category) === null || _h === void 0 ? void 0 : _h.includes('eligible counterparty'))) {
        var userType = selected.category.filter(function (curr) { return curr !== 'pep' && curr !== 'us_resident'; });
        newCategories = __assign(__assign({}, newCategories), (_c = {}, _c[isUser ? 'answers__type__in_' : 'user__answers__type__in_'] = JSON.stringify(userType), _c));
    }
    return newCategories;
};
export var getDocument = function (selected, type) {
    var _a, _b;
    if (type === void 0) { type = 'user'; }
    if (!selected.document || selected.document.length === 0)
        return {};
    var isUser = type === 'user';
    if (selected.document.includes('valid')) {
        return _a = {}, _a[isUser ? 'expired' : 'user__expired'] = 0, _a;
    }
    if (selected.document.includes('expired')) {
        return _b = {}, _b[isUser ? 'expired' : 'user__expired'] = 1, _b;
    }
    return {};
};
export var getAmountInvested = function (selected) {
    if (!selected.amount_invested || selected.amount_invested.length === 0)
        return {};
    if (selected.amount_invested.includes('lessThan')) {
        return { amount_invested__lte: 5000 };
    }
    if (selected.amount_invested.includes('between')) {
        return { amount_invested__gt: 5000, amount_invested__lte: 10000 };
    }
    if (selected.amount_invested.includes('moreThan')) {
        return { amount_invested__gt: 10000 };
    }
    return {};
};
export var getAmountRaised = function (selected) {
    if (!selected.amount_raised || selected.amount_raised.length === 0)
        return {};
    if (selected.amount_raised.includes('lessThan')) {
        return { amount_raised__lte: 50000 };
    }
    if (selected.amount_raised.includes('between_50k_100k')) {
        return { amount_raised__gt: 50000, amount_raised__lte: 100000 };
    }
    if (selected.amount_raised.includes('between_100k_500k')) {
        return { amount_raised__gt: 100000, amount_raised__lte: 500000 };
    }
    if (selected.amount_raised.includes('between_500k_1m')) {
        return { amount_raised__gt: 500000, amount_raised__lte: 1000000 };
    }
    if (selected.amount_raised.includes('moreThan')) {
        return { amount_raised__gt: 1000000 };
    }
    return {};
};
export var buildFilters = function (filters, selected) {
    var q = filters.q, newStatus = filters.status, newFilters = __rest(filters, ["q", "status"]);
    return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, newFilters), getQuery(q)), getCategories(selected)), getDocument(selected)), { status__in_: (selected.status && (selected.status.length > 0))
            ? JSON.stringify(selected.status)
            : undefined, risk__level__in_: (selected.risk && (selected.risk.length > 0))
            ? JSON.stringify(selected.risk)
            : undefined, address__country__in_: (selected.country && (selected.country.length > 0))
            ? JSON.stringify(selected.country)
            : undefined, answers__appropriateness_score__gt: (selected.appropriateness
            && selected.appropriateness.includes('pass')
            && !selected.appropriateness.includes('fail')) ? MINIMUM_APPROPRIATENESS_SCORE : undefined, answers__appropriateness_score__le: (selected.appropriateness
            && selected.appropriateness.includes('fail')
            && !selected.appropriateness.includes('pass')) ? MINIMUM_APPROPRIATENESS_SCORE : undefined, answers__suitability_score__gt: (selected.suitability
            && selected.suitability.includes('pass')
            && !selected.suitability.includes('fail')) ? MINIMUM_SUITABILITY_SCORE : undefined, answers__suitability_score__le: (selected.suitability
            && selected.suitability.includes('fail')
            && !selected.suitability.includes('pass')) ? MINIMUM_SUITABILITY_SCORE : undefined }), getAmountInvested(selected)), getAmountRaised(selected)));
};
export var fetchUser = function (filters, selected, source, resultsPerPage, page, onSuccess, onError) {
    if (resultsPerPage === void 0) { resultsPerPage = 10; }
    if (page === void 0) { page = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var amountInvested, amountRaised, isPaymentService, data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    amountInvested = getAmountInvested(selected);
                    amountRaised = getAmountRaised(selected);
                    isPaymentService = Object.keys(amountInvested).length > 0
                        || Object.keys(amountRaised).length > 0;
                    return [4 /*yield*/, (isPaymentService
                            ? getAllUsersFromPayment
                            : getAllUsers)(source, page, resultsPerPage, buildFilters(filters, selected))];
                case 1:
                    data = (_a.sent()).data;
                    if (onSuccess)
                        onSuccess(data);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    logError(err_1);
                    if (onError)
                        onError(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var buildEntitiesFilters = function (filters, selected) {
    var categories = filters.categories, q = filters.q, newStatus = filters.status, newFilters = __rest(filters, ["categories", "q", "status"]);
    var amountInvested = getAmountInvested(selected);
    var amountRaised = getAmountRaised(selected);
    return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, newFilters), getQuery(q, 'entity')), getCategories(selected, 'entity')), getDocument(selected, 'entity')), amountInvested), amountRaised), { status__in_: (selected.status && (selected.status.length > 0))
            ? JSON.stringify(selected.status)
            : undefined, user__risk__level__in_: (selected.risk && (selected.risk.length > 0))
            ? JSON.stringify(selected.risk)
            : undefined, address__country__in_: (selected.country && (selected.country.length > 0))
            ? JSON.stringify(selected.country)
            : undefined, user__answers__appropriateness_score__gt: (selected.appropriateness
            && selected.appropriateness.includes('pass')
            && !selected.appropriateness.includes('fail')) ? MINIMUM_APPROPRIATENESS_SCORE : undefined, user__answers__appropriateness_score__lte: (selected.appropriateness
            && selected.appropriateness.includes('fail')
            && !selected.appropriateness.includes('pass')) ? MINIMUM_APPROPRIATENESS_SCORE : undefined, user__answers__suitability_score__gt: (selected.suitability
            && selected.suitability.includes('pass')
            && !selected.suitability.includes('fail')) ? MINIMUM_SUITABILITY_SCORE : undefined, user__answers__suitability_score__lte: (selected.suitability
            && selected.suitability.includes('fail')
            && !selected.suitability.includes('pass')) ? MINIMUM_SUITABILITY_SCORE : undefined }));
};
export var fetchEntity = function (filters, selected, source, resultsPerPage, page, onSuccess, onError) {
    if (resultsPerPage === void 0) { resultsPerPage = 10; }
    if (page === void 0) { page = 1; }
    return __awaiter(void 0, void 0, void 0, function () {
        var amountInvested, amountRaised, isPaymentService, data, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    amountInvested = getAmountInvested(selected);
                    amountRaised = getAmountRaised(selected);
                    isPaymentService = Object.keys(amountInvested).length > 0
                        || Object.keys(amountRaised).length > 0;
                    return [4 /*yield*/, (isPaymentService
                            ? getAllEntitiesFromPayment
                            : getAllEntities)(source, page, resultsPerPage, buildEntitiesFilters(filters, selected))];
                case 1:
                    data = (_a.sent()).data;
                    if (onSuccess)
                        onSuccess(data);
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    logError(err_2);
                    if (onError)
                        onError(err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
};
