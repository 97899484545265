import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import fetchMedia from 'utils/fetchMedia';
import { Visibility } from '@mui/icons-material';
import styles from './ImgPlaceholder.module.css';
export default function ImgPlaceholder(_a) {
    var src = _a.src, alt = _a.alt, className = _a.className, _b = _a.placeholder, placeholder = _b === void 0 ? null : _b;
    var _c = useState('LOADING'), status = _c[0], setStatus = _c[1];
    var _d = useState(), url = _d[0], setUrl = _d[1];
    useEffect(function () {
        var cancel = false;
        if (src && !cancel) {
            var handleSetUrl = function (data) {
                if (!cancel) {
                    setUrl(data);
                    setStatus('SUCCESS');
                }
            };
            fetchMedia(handleSetUrl, placeholder, "/media".concat(src));
        }
        else {
            setStatus('SUCCESS');
            setUrl(src);
        }
        return function () { cancel = true; };
    }, [src]);
    if (status === 'LOADING') {
        return (_jsx("div", { className: "".concat(styles.placeholder, " ").concat(className), children: _jsx("div", { className: styles.loading }) }));
    }
    return (_jsxs(_Fragment, { children: [_jsx("img", { src: url, alt: alt, className: className, onError: function () { return setStatus('LOADING'); } }), _jsxs("a", { href: url, className: styles.link, target: "_blank", rel: "noreferrer", children: [_jsx(Visibility, {}), _jsx("span", { children: "See document" })] })] }));
}
