var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Table from 'components/Table';
import TableToolbar from 'components/Table/TableToolbar';
import TablePagination from 'components/Table/TablePagination';
import TableTitle from 'components/Table/TableTitle';
import TableRows from 'components/Table/TableRows';
import LoadingSpinner from 'components/library/LoadingSpinner';
import Loading from 'components/library/Loading';
import styles from 'components/Table/TableRows/TableRows.module.css';
import useKycs from 'hooks/useKycs';
import Status from './Status';
import TestScore from './TestScore';
export default function SearchTabResults(_a) {
    var _b;
    var type = _a.type, filters = _a.filters;
    var _c = useKycs({
        type: type,
        filters: filters,
    }), status = _c.status, error = _c.error, page = _c.page, resultsPerPage = _c.resultsPerPage, data = _c.data, total = _c.total, nextPage = _c.nextPage, previousPage = _c.previousPage, goPage = _c.goPage, setResultsPerPage = _c.setResultsPerPage, updateData = _c.updateData;
    var handleSelectAll = function (checked) {
        if (checked) {
            updateData(function (kycs) { return kycs.map(function (kyc) { return (__assign(__assign({}, kyc), { selected: true })); }); });
        }
        else {
            updateData(function (kycs) { return kycs.map(function (kyc) { return (__assign(__assign({}, kyc), { selected: false })); }); });
        }
    };
    var handleSelectKyc = function (iss, checked) {
        updateData(function (kycs) {
            var index = kycs.findIndex(function (kyc) {
                if (kyc.iss)
                    return kyc.iss === iss;
                return kyc.issUser === iss;
            });
            return __spreadArray(__spreadArray(__spreadArray([], kycs.slice(0, index), true), [
                __assign(__assign({}, kycs[index]), { selected: checked })
            ], false), kycs.slice(index + 1, kycs.length), true);
        });
    };
    var getName = function (row) {
        if (type === 'user')
            return "".concat(row.firstName, " ").concat(row.lastName);
        return "".concat(row.societyName || row.legalName);
    };
    var USER = [
        'Account ID', 'Submission date', 'Appropriateness score',
        'Suitability score', 'Test status', 'Application status', 'PEP',
    ];
    var userColumns = [
        function (row) { var _a; return (_a = row.iss) === null || _a === void 0 ? void 0 : _a.toString(); },
        function (row) { return row.acceptConditions; },
        function (row) { var _a, _b; return (_b = ((((_a = row.answers) === null || _a === void 0 ? void 0 : _a.appropriatenessScore) || 0) + 1)) === null || _b === void 0 ? void 0 : _b.toString(); },
        function (row) { var _a, _b; return (_b = ((((_a = row.answers) === null || _a === void 0 ? void 0 : _a.suitabilityScore) || 0) + 1)) === null || _b === void 0 ? void 0 : _b.toString(); },
        function (row) {
            var _a, _b, _c, _d;
            return (_jsx(TestScore, { levelOfRisk: ((_a = row.answers) === null || _a === void 0 ? void 0 : _a.levelOfRisk) || '', experienceTrading: ((_b = row.answers) === null || _b === void 0 ? void 0 : _b.experienceTrading) || '', suitabilityScore: (((_c = row.answers) === null || _c === void 0 ? void 0 : _c.suitabilityScore) || 0) + 1, appropriatenessScore: (((_d = row.answers) === null || _d === void 0 ? void 0 : _d.appropriatenessScore) || 0) + 1 }));
        },
        function (row) { return (_jsx(Status, { state: row.status })); },
        function (row) { return (row.pep ? _jsx("p", { className: "".concat(styles.status, " ").concat(styles.rejected), children: "PEP" }) : _jsx("p", {})); },
    ];
    var ENTITY = [
        'Account ID', 'Account type', 'Last update', 'Registration number', 'Application status',
    ];
    var entityColumns = [
        function (row) { var _a; return (_a = row.issUser) === null || _a === void 0 ? void 0 : _a.toString(); },
        function (row) { var _a, _b; return ((((_a = row.tag) === null || _a === void 0 ? void 0 : _a.toUpperCase()) === 'P2P') ? 'CORPORATE ISSUER' : (_b = row.tag) === null || _b === void 0 ? void 0 : _b.toUpperCase()); },
        function (row) { return row === null || row === void 0 ? void 0 : row.updated; },
        function (row) { return row.siren; },
        function (row) { return _jsx(Status, { state: row.status || '' }); },
    ];
    var USER_COLUMNS = { gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.25fr 0.25fr' };
    var ENTITY_COLUMNS = { gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.25fr' };
    return (_jsx(Loading, { status: status, fallback: _jsx(LoadingSpinner, {}), errorFallback: _jsx("p", { children: error }), children: _jsxs("div", { className: "container", children: [_jsx(TableToolbar, { children: _jsx(TablePagination, { page: page, resultsPerPage: resultsPerPage, total: total, onNext: nextPage, onPrevious: previousPage, onPageClick: goPage, onTotalRowsSelect: setResultsPerPage }) }), _jsxs(Table, { children: [_jsx(TableTitle, { selected: ((_b = data === null || data === void 0 ? void 0 : data.filter(function (kyc) { return kyc.selected; })) === null || _b === void 0 ? void 0 : _b.length) || 0, onSelectedChange: handleSelectAll, columns: (type === 'user') ? USER : ENTITY, columnsStyle: (type === 'user')
                                ? USER_COLUMNS
                                : ENTITY_COLUMNS }), type === 'user'
                            ? (_jsx(TableRows, { data: data, name: getName, link: function (row) { return "/search/customer/".concat(row.iss); }, columns: userColumns, columnsStyle: USER_COLUMNS, onSelect: handleSelectKyc }))
                            : (_jsx(TableRows, { data: data, name: getName, link: function (row) { return "/search/entity/".concat(row.siren, "/").concat(row.issUser); }, columns: entityColumns, columnsStyle: ENTITY_COLUMNS, onSelect: handleSelectKyc }))] })] }) }));
}
