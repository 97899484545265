export var identityDocumentAdaptor = function (data) {
    var expirationDate = data === null || data === void 0 ? void 0 : data.expirationDate;
    if (expirationDate) {
        expirationDate = expirationDate.split(' ')[0];
    }
    return {
        expirationDate: expirationDate,
        files: data.files,
        identityNumber: data.identityNumber,
    };
};
export var toIdentityDocumentBackend = function (data) { return ({
    expiration_date: data.expirationDate,
    identity_number: data.identityNumber,
}); };
export var proofOfAddressAdaptor = function (data) {
    var emissionDate = data === null || data === void 0 ? void 0 : data.emission_date;
    if (emissionDate) {
        emissionDate = emissionDate.split(' ')[0];
    }
    return {
        documentType: data.document_type,
        emissionDate: emissionDate,
        files: data.files,
    };
};
export var toProofOfAddressDocumentBackend = function (data) { return ({
    document_type: data.documentType,
    emission_date: data.emissionDate,
}); };
