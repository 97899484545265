// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kuEqR5UsTDOY4uBOkm0n {
  padding-top: 16px;
  width: 60%;
}
`, "",{"version":3,"sources":["webpack://./src/views/Entity/EntityResult/EntityResult.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,UAAU;AACZ","sourcesContent":[".main {\n  padding-top: 16px;\n  width: 60%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `kuEqR5UsTDOY4uBOkm0n`
};
export default ___CSS_LOADER_EXPORT___;
