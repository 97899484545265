import axios from '../axiosClient';
import { keysToSnake } from 'utils/converter';
import { logError } from 'utils/logs';
export var getNextCustomer = function (source) { return axios.get('/next_pipe', {
    cancelToken: source.token,
}); };
export var sendPipeAction = function (data) { return axios.post('/pipe_action', data); };
export var getHistory = function (iss, idEntity, source) { return axios.get('/history', {
    params: { iss_user: iss, id_entity: idEntity },
    cancelToken: source === null || source === void 0 ? void 0 : source.token,
}); };
export var createLog = function (data) {
    try {
        return axios.post('/history', keysToSnake(data));
    }
    catch (err) {
        logError(err);
    }
};
