import { jsx as _jsx } from "react/jsx-runtime";
import useAPI from 'hooks/useAPI';
import { getNextCustomer } from 'services/network/api/pipe';
import { pipeUserAdaptor } from 'services/network/adaptor/pipe';
import Loading from 'components/library/Loading';
import LoadingSpinner from 'components/library/LoadingSpinner';
import TunnelVerification from './TunnelVerification';
/**
 * Tunnel view is responsible of getting a kyc to verify,
 * getting the next one and display the verification process
 */
export default function Tunnel() {
    var _a = useAPI({
        request: function (source) { return getNextCustomer(source); },
        adaptor: pipeUserAdaptor,
    }), status = _a.status, data = _a.data, fetchData = _a.fetchData;
    return (_jsx(Loading, { status: status, fallback: _jsx(LoadingSpinner, {}), children: _jsx(TunnelVerification, { data: data, onNext: fetchData }) }));
}
