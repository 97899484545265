import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from 'components/library/Button';
import styles from './TunnelVerificationFooter.module.css';
export default function TunnelVerificationFooter(_a) {
    var step = _a.step, hasNextStep = _a.hasNextStep, onSkip = _a.onSkip, onValidate = _a.onValidate, onNext = _a.onNext;
    return (_jsxs(_Fragment, { children: [(step !== 'default')
                && (_jsxs("div", { className: styles.footer, children: [_jsx(Button, { variant: "ghost", onClick: onSkip, children: hasNextStep
                                ? 'Skip'
                                : 'Reject the KYC' }), _jsx(Button, { onClick: onValidate, children: hasNextStep
                                ? 'Validate information & next'
                                : 'Approve the KYC' })] })), (!hasNextStep && step === 'default')
                && (_jsx(Button, { onClick: onNext, className: styles.next, children: "Next customer" }))] }));
}
