import { toHuman } from './converter';
export var getActivityTitle = function (activity) {
    if (activity.action === 'validated_kyc')
        return "KYC approved by ".concat(activity.actor);
    if (activity.action === 'rejected_kyc')
        return "KYC rejected by ".concat(activity.actor);
    return activity.action;
};
export var getActivityDescription = function (activity) {
    if (activity.action === 'validated_kyc')
        return "KYC has been approved the ".concat(new Date(activity.date).toDateString(), " ").concat(new Date(activity.date).toTimeString());
    if (activity.action === 'rejected_kyc')
        return "KYC has been rejected the ".concat(new Date(activity.date).toDateString(), " ").concat(new Date(activity.date).toTimeString());
    return "".concat(toHuman(activity.action), " on the ").concat(new Date(activity.date).toDateString(), " ").concat(new Date(activity.date).toTimeString());
};
