import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from 'components/library/Button';
import styles from './CommentForm.module.css';
export default function CommentForm(_a) {
    var options = _a.options, _b = _a.field, field = _b === void 0 ? '' : _b, close = _a.close, submit = _a.submit;
    var _c = useState((field !== '') ? field : 'user.address'), option = _c[0], setOption = _c[1];
    var _d = useState(''), comment = _d[0], setComment = _d[1];
    var _e = useState(''), title = _e[0], setTitle = _e[1];
    var _f = useState('afs'), visibility = _f[0], setVisibility = _f[1];
    var _g = useState('error'), status = _g[0], setStatus = _g[1];
    var _h = useState('task'), type = _h[0], setType = _h[1];
    var handleOption = function (e) { return setOption(e.target.value); };
    var handleComment = function (e) { return setComment(e.target.value); };
    var handleVisibility = function (e) { return setVisibility(e.target.name); };
    var handleTitle = function (e) { return setTitle(e.target.value); };
    var handleStatus = function (e) {
        var availableStatus = ['error', 'warning', 'common'];
        var index = availableStatus.indexOf(e.target.value);
        if (index !== -1) {
            setStatus(availableStatus[index]);
        }
    };
    var handleSubmit = function () {
        if (comment !== '' && title !== '') {
            submit(option, title, comment, visibility === 'all', status, (type === 'null') ? null : type);
        }
    };
    return (_jsx("div", { className: "fade-in ".concat(styles.background), children: _jsxs("section", { className: "bounce-in ".concat(styles.modal), children: [_jsxs("header", { className: styles.header, children: [_jsx("p", { className: styles.title, children: "Add a comment" }), _jsx("p", { className: styles.description, children: "You indicated that a field contain an error. Provide more information to the user by adding a comment on the type of the error or keep track of what you need." })] }), _jsxs("main", { className: styles.main, children: [_jsxs("label", { htmlFor: "fields", className: styles.input, children: ["Field containing error", _jsx("select", { id: "fields", onChange: handleOption, className: styles.field, value: option, children: Object.entries(options).map(function (_a) {
                                        var key = _a[0], value = _a[1];
                                        return (_jsx("option", { value: key, children: value }, key));
                                    }) })] }), _jsxs("label", { htmlFor: "status", className: styles.input, children: ["Status", _jsxs("select", { id: status, onChange: handleStatus, className: styles.field, children: [_jsx("option", { value: "error", children: "error" }), _jsx("option", { value: "warning", children: "warning" }), _jsx("option", { value: "common", children: "common" })] })] }), _jsxs("label", { htmlFor: "type", className: styles.input, children: ["Type", _jsxs("select", { id: "type", className: styles.field, onChange: function (e) { return setType(e.target.value); }, value: type, children: [_jsx("option", { value: "task", children: "Task" }), _jsx("option", { value: "follow_up", children: "Follow up" }), _jsx("option", { value: "null", children: "Other" })] })] }), _jsxs("label", { htmlFor: "title", className: styles.input, children: ["Title", _jsx("input", { name: "title", id: "title", value: title, onChange: handleTitle, className: styles.field })] }), _jsxs("label", { htmlFor: "comment", className: styles.input, children: ["Comment", _jsx("textarea", { name: "comment", id: "comment", value: comment, onChange: handleComment, className: styles.field })] }), _jsxs("div", { className: styles.radios, children: [_jsx("p", { className: styles.visibility, children: "Visibility" }), _jsxs("label", { htmlFor: "afs", children: [_jsx("input", { type: "radio", name: "afs", checked: visibility === 'afs', onChange: handleVisibility, className: styles.radio }), "Only AFS members"] }), _jsxs("label", { htmlFor: "all", children: [_jsx("input", { type: "radio", name: "all", checked: visibility === 'all', onChange: handleVisibility, className: styles.radio }), "AFS members & the client"] })] })] }), _jsxs("footer", { className: styles.footer, children: [_jsx(Button, { onClick: close, variant: "ghost", children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, disabled: title === '' || comment === '', children: "Send the comment" })] })] }) }));
}
