var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import filtersStyles from 'components/Filters/FiltersStatus/FiltersStatus.module.css';
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import styles from './FilterDocument.module.css';
export default function FilterDocument() {
    var _a, _b;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var onChecked = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                document: (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected.document || []), true), [e.target.value], false) : (selected.document || []).filter(function (value) { return value !== e.target.value; }),
            },
        });
    };
    return (_jsxs("div", { className: filtersStyles.dropdown, children: [_jsxs("label", { htmlFor: "valid", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", name: "valid", value: "valid", id: "valid", checked: ((_a = selected.document) === null || _a === void 0 ? void 0 : _a.includes('valid')) || false, onChange: onChecked, "aria-label": "valid" }), _jsx("span", { className: styles.valid, children: "VALID" })] }), _jsxs("label", { htmlFor: "expired", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", name: "expired", value: "expired", id: "expired", checked: ((_b = selected.document) === null || _b === void 0 ? void 0 : _b.includes('expired')) || false, onChange: onChecked, "aria-label": "expired" }), _jsx("span", { className: styles.expired, children: "EXPIRED" })] })] }));
}
