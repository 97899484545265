import { jsx as _jsx } from "react/jsx-runtime";
import styles from 'components/Table/TableRows/TableRows.module.css';
export default function TestScore(_a) {
    var levelOfRisk = _a.levelOfRisk, experienceTrading = _a.experienceTrading, suitabilityScore = _a.suitabilityScore, appropriatenessScore = _a.appropriatenessScore;
    if (levelOfRisk === 'none' || experienceTrading === 'none') {
        return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.rejected), children: "REJECTED" }));
    }
    if ((suitabilityScore < 13) || (appropriatenessScore < 5)) {
        return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.low), children: "LOW" }));
    }
    return (_jsx("p", { className: "".concat(styles.status, " ").concat(styles.approved), children: "PASS" }));
}
