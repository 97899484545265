// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bL7vzItXrTrdb6jf3TzU {
  background-color: #f8f9fb;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 24px;
  width: 100%;
}

.Uyx8k58TS5iNmHhLU76g,
.XXAfRJlxjcrwF5V3N1sY {
  margin: auto;
  max-width: 1440px;
  width: 100%;
}

.krZ39_5squmV2T142B4k {
  font-weight: 500;
  margin: 0;
  text-decoration: none;
}

.xKcbhIkP2Q7WHYIs0ol_ {
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 8px;
  margin-top: 8px;
}

.D9FTCb9glUfi8K3m3_1r {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

.sBfD_bkV6N1fXXG4MHug {
  margin-right: 8px;
}

.AcrK5XlCKZ5Zum0DtAMn {
  align-items: center;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  display: flex;
  flex-direction: row;
}

.TA_mMTueeBZiJDQyK55Q {
  margin-right: 4px;
}

.XXAfRJlxjcrwF5V3N1sY {
  display: flex;
  flex-direction: row;
  gap: 48px;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/views/Entity/EntityLayout/EntityLayout.module.css"],"names":[],"mappings":"AAGA;EACE,yBAAyB;EACzB,gCAAgC;EAChC,sBAAsB;EACtB,aAAa;EACb,WAAW;AACb;;AAEA;;EAEE,YAAY;EACZ,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,mDAAwB;EACxB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,8BAA8B;AAChC","sourcesContent":["@value color: \"components/library/style/colors.css\";\n@value color-text-subtle from color;\n\n.header {\n  background-color: #f8f9fb;\n  border-bottom: 1px solid #e5e5e5;\n  box-sizing: border-box;\n  padding: 24px;\n  width: 100%;\n}\n\n.content,\n.main {\n  margin: auto;\n  max-width: 1440px;\n  width: 100%;\n}\n\n.link {\n  font-weight: 500;\n  margin: 0;\n  text-decoration: none;\n}\n\n.title {\n  font-family: Roboto, sans-serif;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  margin-bottom: 8px;\n  margin-top: 8px;\n}\n\n.description {\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 0;\n}\n\n.number {\n  margin-right: 8px;\n}\n\n.time {\n  align-items: center;\n  color: color-text-subtle;\n  display: flex;\n  flex-direction: row;\n}\n\n.icon {\n  margin-right: 4px;\n}\n\n.main {\n  display: flex;\n  flex-direction: row;\n  gap: 48px;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"header": `bL7vzItXrTrdb6jf3TzU`,
	"content": `Uyx8k58TS5iNmHhLU76g`,
	"main": `XXAfRJlxjcrwF5V3N1sY`,
	"link": `krZ39_5squmV2T142B4k`,
	"title": `xKcbhIkP2Q7WHYIs0ol_`,
	"description": `D9FTCb9glUfi8K3m3_1r`,
	"number": `sBfD_bkV6N1fXXG4MHug`,
	"time": `AcrK5XlCKZ5Zum0DtAMn`,
	"icon": `TA_mMTueeBZiJDQyK55Q`
};
export default ___CSS_LOADER_EXPORT___;
