// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RlHZvu_Yyq4CyYV_WVmZ {
  background-color: white;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  box-shadow: 0 2px 1px rgb(0 0 0 / 10%);
}

.rqgRXGfn6YEGG23QmHY8 {
  display: flex;
  flex-direction: row;
  font-size: 0.875rem !important;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  width: max-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/FiltersStatus/FiltersStatus.module.css"],"names":[],"mappings":"AAGA;EACE,uBAAuB;EACvB,8DAA4B;EAC5B,sBAAsB;EACtB,sCAAsC;AACxC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,oBAAoB;EACpB,kBAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray, color-text-subtle, color-purple, color-purple-bg from colors;\n\n.dropdown {\n  background-color: white;\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  box-shadow: 0 2px 1px rgb(0 0 0 / 10%);\n}\n\n.option {\n  display: flex;\n  flex-direction: row;\n  font-size: 0.875rem !important;\n  gap: 0.5rem;\n  padding: 0.5rem 1rem;\n  width: max-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"dropdown": `RlHZvu_Yyq4CyYV_WVmZ`,
	"option": `rqgRXGfn6YEGG23QmHY8`
};
export default ___CSS_LOADER_EXPORT___;
