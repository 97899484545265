// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../components/library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CfEjJPgrP6PMOaXH_Ycw {
  background-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]};
  border-bottom: 1px solid #e5e5e5;
  padding-top: 24px;
}

.C97n0Eve0e8pViDiTIcX {
  font-weight: 500;
  margin: 0;
  text-decoration: none;
}

.t70krWcHSShQaNd6amSW {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 8px;
}

.a3BzbjrozX9ivzYqUQcQ {
  align-items: center;
  display: flex;
  margin-top: 0;
  padding-bottom: 18px;
}

.a3BzbjrozX9ivzYqUQcQ span {
  align-items: center;
  display: flex;
  margin: 0 0 0 8px;
}

.YYiip6yfSekIFLJxGL2g {
  margin-right: 4px;
}

.bF_6gI28LfKQj_zT3Sog {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.XI6cfLmKSiaTvrfqe5XU {
  column-gap: 86px;
  display: flex;
  flex-direction: row;
  padding: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/views/Customer/CustomerLayout/CustomerLayout.module.css"],"names":[],"mappings":"AAGA;EACE,8DAAiC;EACjC,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,aAAa;AACf","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-purple-bg, color-text-subtle from colors;\n\n.header {\n  background-color: color-purple-bg;\n  border-bottom: 1px solid #e5e5e5;\n  padding-top: 24px;\n}\n\n.link {\n  font-weight: 500;\n  margin: 0;\n  text-decoration: none;\n}\n\n.name {\n  font-size: 24px;\n  font-weight: 500;\n  margin-bottom: 0;\n  margin-top: 8px;\n}\n\n.description {\n  align-items: center;\n  display: flex;\n  margin-top: 0;\n  padding-bottom: 18px;\n}\n\n.description span {\n  align-items: center;\n  display: flex;\n  margin: 0 0 0 8px;\n}\n\n.icon {\n  margin-right: 4px;\n}\n\n.updated {\n  color: color-text-subtle;\n}\n\n.main {\n  column-gap: 86px;\n  display: flex;\n  flex-direction: row;\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-purple-bg": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-bg"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"header": `CfEjJPgrP6PMOaXH_Ycw`,
	"link": `C97n0Eve0e8pViDiTIcX`,
	"name": `t70krWcHSShQaNd6amSW`,
	"description": `a3BzbjrozX9ivzYqUQcQ`,
	"icon": `YYiip6yfSekIFLJxGL2g`,
	"updated": `bF_6gI28LfKQj_zT3Sog`,
	"main": `XI6cfLmKSiaTvrfqe5XU`
};
export default ___CSS_LOADER_EXPORT___;
