// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jLAAq3Lr_1QQMEANBvAq {
  font-weight: 500;
  margin: 0;
}

.cx8zoOCCOxQKePSCJgm7 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.RrdzH8ByEZGVBG_Xly0G {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.cx8zoOCCOxQKePSCJgm7 select,
.cx8zoOCCOxQKePSCJgm7 input {
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.njheEeRHleQta7OvjmZV {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.WMaaQMW7u6S34NoyMqXv {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 1rem !important;
  transition: 0.3s;
}

.UdxB4VMe0SQa0UbNvL1f {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  margin: 0 0 0.5rem;
}

.UdxB4VMe0SQa0UbNvL1f,
.UdxB4VMe0SQa0UbNvL1f .WMaaQMW7u6S34NoyMqXv {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]};
}

.RttW1dNKYSfmKGer8hH8 {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  font-size: 1rem;
  transition: 0.3s;
}

.RttW1dNKYSfmKGer8hH8:hover .WMaaQMW7u6S34NoyMqXv {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
}

.RttW1dNKYSfmKGer8hH8:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
}

.r3JC4QCrgYDBtyvEL4mS {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
}

.r3JC4QCrgYDBtyvEL4mS span {
  color: black;
  margin-left: 0.25rem;
}

.BfNokuD_RYQZ6jCGmf1D {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/ValidationSummary/ValidationSummaryRisk/ValidationSummaryRisk.module.css"],"names":[],"mappings":"AAGA;EACE,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;;EAEE,8DAA4B;EAC5B,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,mDAAwB;EACxB,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,mDAAkB;AACpB;;AAEA;EACE,mDAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,mDAAmB;AACrB;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,mDAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray, color-text-subtle, color-purple, color-purple-dark, color-error from colors;\n\n.title {\n  font-weight: 500;\n  margin: 0;\n}\n\n.form {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n.label {\n  display: flex;\n  flex-direction: column;\n  gap: 0.25rem;\n}\n\n.form select,\n.form input {\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  padding: 0.5rem;\n}\n\n.row {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 0.5rem;\n}\n\n.icon {\n  color: color-text-subtle;\n  font-size: 1rem !important;\n  transition: 0.3s;\n}\n\n.error {\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  gap: 0.25rem;\n  margin: 0 0 0.5rem;\n}\n\n.error,\n.error .icon {\n  color: color-error;\n}\n\n.edit {\n  color: color-purple;\n  font-size: 1rem;\n  transition: 0.3s;\n}\n\n.edit:hover .icon {\n  color: color-purple;\n}\n\n.edit:hover {\n  color: color-purple-dark;\n}\n\n.value {\n  color: color-text-subtle;\n}\n\n.value span {\n  color: black;\n  margin-left: 0.25rem;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]}`,
	"color-error": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-error"]}`,
	"title": `jLAAq3Lr_1QQMEANBvAq`,
	"form": `cx8zoOCCOxQKePSCJgm7`,
	"label": `RrdzH8ByEZGVBG_Xly0G`,
	"row": `njheEeRHleQta7OvjmZV`,
	"icon": `WMaaQMW7u6S34NoyMqXv`,
	"error": `UdxB4VMe0SQa0UbNvL1f`,
	"edit": `RttW1dNKYSfmKGer8hH8`,
	"value": `r3JC4QCrgYDBtyvEL4mS`,
	"content": `BfNokuD_RYQZ6jCGmf1D`
};
export default ___CSS_LOADER_EXPORT___;
