// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trdH2oRnxLKIidTPBoAc {
  background-color: rgb(211 211 211);
  overflow: hidden;
  position: relative;
  z-index: 44;
}

.rCMzzQutkc7qTueaSVkv {
  animation: rCMzzQutkc7qTueaSVkv 1s infinite;
  background-image:
    linear-gradient(
      to left,
      rgb(251 251 251 / 5%),
      rgb(251 251 251 / 30%),
      rgb(251 251 251 / 60%),
      rgb(251 251 251 / 30%),
      rgb(251 251 251 / 5%)
    );
  height: 100%;
  left: -45%;
  position: absolute;
  width: 45%;
  z-index: 45;
}

.TNlqL0K7C9ubv9Zl2mS_ {
  column-gap: 8px;
  display: flex;
  font-weight: 500;
  text-decoration: none;
}

@keyframes rCMzzQutkc7qTueaSVkv {
  0% {
    left: -45%;
  }

  100% {
    left: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/library/ImgPlaceholder/ImgPlaceholder.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,2CAA8B;EAC9B;;;;;;;;KAQG;EACH,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".placeholder {\n  background-color: rgb(211 211 211);\n  overflow: hidden;\n  position: relative;\n  z-index: 44;\n}\n\n.loading {\n  animation: loading 1s infinite;\n  background-image:\n    linear-gradient(\n      to left,\n      rgb(251 251 251 / 5%),\n      rgb(251 251 251 / 30%),\n      rgb(251 251 251 / 60%),\n      rgb(251 251 251 / 30%),\n      rgb(251 251 251 / 5%)\n    );\n  height: 100%;\n  left: -45%;\n  position: absolute;\n  width: 45%;\n  z-index: 45;\n}\n\n.link {\n  column-gap: 8px;\n  display: flex;\n  font-weight: 500;\n  text-decoration: none;\n}\n\n@keyframes loading {\n  0% {\n    left: -45%;\n  }\n\n  100% {\n    left: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholder": `trdH2oRnxLKIidTPBoAc`,
	"loading": `rCMzzQutkc7qTueaSVkv`,
	"link": `TNlqL0K7C9ubv9Zl2mS_`
};
export default ___CSS_LOADER_EXPORT___;
