// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aH3sHudNop72gnBT3qAI {
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  justify-content: space-between;
  min-width: 200px;
  transition: 0.3s;
  white-space: nowrap;
}

button:hover {
  border-color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  cursor: pointer;
}

.C4yV8ggdnUC3wGnlbbFF {
  font-size: 1rem !important;
  padding-left: 24px;
}

.mEGh9y5QCEpTu7a_V68S {
  align-items: center;
  display: flex;
}

.UxjndxpaMIaxSybllnO9 {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  box-sizing: border-box;
  min-width: 100%;
  padding: 8px;
  position: absolute;
  z-index: 100;
}

.O29bQ30UbP9TdPDYSGgO {
  height: max-content;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/components/library/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAGA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,8BAA8B;EAC9B,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0DAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,sCAAsC;EACtC,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@value colors: \"../style/colors.css\";\n@value color-purple from colors;\n\n.button {\n  align-items: center;\n  border: 1px solid #e5e5e5;\n  border-radius: 5px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: row;\n  font-size: 0.875rem;\n  font-weight: 400;\n  height: 2rem;\n  justify-content: space-between;\n  min-width: 200px;\n  transition: 0.3s;\n  white-space: nowrap;\n}\n\nbutton:hover {\n  border-color: color-purple;\n  cursor: pointer;\n}\n\n.icon {\n  font-size: 1rem !important;\n  padding-left: 24px;\n}\n\n.center {\n  align-items: center;\n  display: flex;\n}\n\n.modal {\n  background-color: white;\n  border-radius: 5px;\n  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);\n  box-sizing: border-box;\n  min-width: 100%;\n  padding: 8px;\n  position: absolute;\n  z-index: 100;\n}\n\n.dropdown {\n  height: max-content;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"../style/colors.css"`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"button": `aH3sHudNop72gnBT3qAI`,
	"icon": `C4yV8ggdnUC3wGnlbbFF`,
	"center": `mEGh9y5QCEpTu7a_V68S`,
	"modal": `UxjndxpaMIaxSybllnO9`,
	"dropdown": `O29bQ30UbP9TdPDYSGgO`
};
export default ___CSS_LOADER_EXPORT___;
