import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'components/library/style/normalize.css';
import 'components/library/style/reset.css';
import 'components/library/style/animation.css';
import 'components/library/style/typography.css';
import Toasts from 'components/library/Toasts';
import ErrorBoundary from 'components/library/DebugErrorBoundary';
import RouteConfig from 'routes/routes';
function App() {
    return (_jsxs(ErrorBoundary, { children: [_jsx(RouteConfig, {}), _jsx(Toasts, {})] }));
}
export default App;
