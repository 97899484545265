import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { NavigateNext } from '@mui/icons-material';
import BreadcrumbStatus from './BreadcrumbStatus';
import styles from './TunnelLayoutBreadcrumb.module.css';
export default function TunnelLayoutBreadcrumb(_a) {
    var steps = _a.steps, currentStep = _a.currentStep;
    var STEPS = {
        company: 'Company information',
        authorized: 'Authorized representative',
        general: 'General information',
        appropriateness: 'Appropriateness test',
        suitability: 'Suitability test',
    };
    return (_jsx("div", { className: styles.row, children: Object.keys(steps).filter(function (step) { return step !== 'default'; }).map(function (step, key) { return (_jsxs("div", { className: styles.step, children: [_jsxs("div", { className: "".concat(styles.step, " ").concat((key === currentStep) ? styles.active : ''), children: [_jsx(BreadcrumbStatus, { status: steps[step] || 'default', step: key + 1, current: currentStep, active: key === currentStep }), _jsx("p", { className: styles.status, children: STEPS[step] })] }), (key + 1 < Object.keys(steps).filter(function (curr) { return curr !== 'default'; }).length)
                    && (_jsx(NavigateNext, {}))] }, step)); }) }));
}
