import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Button from 'components/library/Button';
import { toHuman } from 'utils/converter';
import styles from './CareTunnelActions.module.css';
export default function CareTunnelActions(_a) {
    var _b;
    var onSubmit = _a.onSubmit, onClose = _a.onClose;
    var _c = useState(undefined), selectedAction = _c[0], setSelectedAction = _c[1];
    var handleChange = function (e) {
        setSelectedAction(e.target.value);
    };
    var handleSubmit = function () {
        if (selectedAction) {
            onSubmit(selectedAction);
        }
    };
    return (_jsx("div", { className: styles.background, children: _jsxs("div", { className: styles.modal, children: [_jsx("p", { className: styles.title, children: "Why are you ending the call ?" }), _jsxs("select", { value: selectedAction, className: styles.select, onChange: handleChange, children: [_jsx("option", { selected: true, disabled: true, children: "Select an option" }), ((_b = ['lndp', 'call_later', 'wrong_number', 'treated']) === null || _b === void 0 ? void 0 : _b.map(function (action) { return (_jsx("option", { value: action, children: toHuman(action) })); }))] }), _jsx(Button, { onClick: handleSubmit, disabled: !selectedAction, children: "Validate and go to the next client" }), _jsx(Button, { variant: "ghost", onClick: onClose, className: styles.cancel, children: "Cancel" })] }) }));
}
