import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Edit, ExpandLess, ExpandMore, Save, } from '@mui/icons-material';
import * as statuses from 'services/store/states';
import styles from './DataViewerLayout.module.css';
export default function DataViewerLayout(_a) {
    var label = _a.label, status = _a.status, save = _a.save, _b = _a.pill, pill = _b === void 0 ? null : _b, _c = _a.isExpandable, isExpandable = _c === void 0 ? true : _c, children = _a.children;
    var _d = useState(true), isDropdownOpen = _d[0], setIsDropdownOpen = _d[1];
    var _e = useState(false), isEdit = _e[0], setIsEdit = _e[1];
    useEffect(function () {
        if (status === statuses.SUCCESS) {
            setIsEdit(false);
        }
    }, [status]);
    var handleEditMode = function () { return setIsEdit(function (state) { return !state; }); };
    var handleDropdown = function (e) {
        if (isExpandable) {
            var target = e.target;
            if (target.nodeName !== 'BUTTON') {
                setIsDropdownOpen(function (state) { return !state; });
            }
        }
    };
    var handleSave = function () {
        if (save) {
            save();
        }
        setIsEdit(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { role: "button", tabIndex: -1, className: styles.header, onClick: handleDropdown, onKeyDown: function (e) {
                    if (isExpandable && e.key === 'Enter') {
                        setIsDropdownOpen(function (state) { return !state; });
                    }
                }, children: [_jsxs("div", { className: styles.row, children: [(isExpandable) && ((isDropdownOpen)
                                ? _jsx(ExpandLess, { className: styles.expand })
                                : _jsx(ExpandMore, { className: styles.expand })), _jsx("p", { className: styles.title, children: label }), pill] }), (save)
                        && ((isEdit)
                            ? (_jsx("button", { type: "button", className: styles.button, onClick: handleSave, disabled: status === statuses.LOADING, children: (status !== statuses.LOADING)
                                    ? (_jsxs(_Fragment, { children: [_jsx(Save, { className: styles.icon }), "Save"] }))
                                    : (_jsx(_Fragment, { children: "Loading..." })) }))
                            : (_jsxs("button", { type: "button", className: styles.button, onClick: handleEditMode, children: [_jsx(Edit, { className: styles.icon }), "Modify"] })))] }), isDropdownOpen && children(isEdit)] }));
}
