import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from '../Validation.module.css';
export var NavigationTabs;
(function (NavigationTabs) {
    NavigationTabs["summary"] = "summary";
    NavigationTabs["comments"] = "comments";
    NavigationTabs["callRecord"] = "callRecord";
    NavigationTabs["customerCare"] = "customerCare";
    NavigationTabs["projects"] = "projects";
})(NavigationTabs || (NavigationTabs = {}));
export default function ValidationNavigation(_a) {
    var active = _a.active, onChange = _a.onChange;
    return (_jsxs("nav", { className: styles.navigation, children: [_jsx("button", { type: "button", "data-navigation": NavigationTabs.summary, className: "".concat(styles.button, " ").concat((active === NavigationTabs.summary) ? styles.active : ''), onClick: onChange, children: "Summary" }), _jsx("button", { type: "button", "data-navigation": NavigationTabs.comments, className: "".concat(styles.button, " ").concat((active === NavigationTabs.comments) ? styles.active : ''), onClick: onChange, children: "Comments" }), _jsx("button", { type: "button", "data-navigation": NavigationTabs.callRecord, className: "".concat(styles.button, " ").concat((active === NavigationTabs.callRecord) ? styles.active : ''), onClick: onChange, children: "Call record" }), _jsx("button", { type: "button", "data-navigation": NavigationTabs.customerCare, className: "".concat(styles.button, " ").concat((active === NavigationTabs.customerCare) ? styles.active : ''), onClick: onChange, children: "Care" }), _jsx("button", { type: "button", "data-navigation": NavigationTabs.projects, className: "".concat(styles.button, " ").concat((active === NavigationTabs.projects) ? styles.active : ''), onClick: onChange, children: "Projects" })] }));
}
