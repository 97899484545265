import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Image } from '@mui/icons-material';
import styles from '../EntityDocuments.module.css';
export default function EntityDocumentsFile(_a) {
    var document = _a.document, openDocument = _a.openDocument;
    if (!document)
        return _jsx("p", { className: styles.empty, children: "No file provided" });
    var type = document.type, name = document.name;
    return (_jsxs("button", { type: "button", className: styles.file, "data-type": type, onClick: openDocument, children: [_jsx(Image, { className: styles.image }), name] }));
}
