// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CabNS_O0rRNy5ZlJiBZC {
  align-items: center;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: 16px;
  padding: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Table/TableToolbar/TableToolbar.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".container {\n  align-items: center;\n  background-color: white;\n  border: 1px solid #e5e5e5;\n  border-radius: 5px 5px 0 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  margin-bottom: 0;\n  margin-top: 16px;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CabNS_O0rRNy5ZlJiBZC`
};
export default ___CSS_LOADER_EXPORT___;
