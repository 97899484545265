// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header */

.V3Ge5Sk0oZQ8C7OUodY2 {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.i_aZ0oM0dfM7wWQjKWab {
  margin-left: 0.25rem;
}

/* Calls' list */

.hDza4lKrk5ipywYb9St0 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Call item */

.AouHm7zFZ4WCz0CzK_ip {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.gvNxATwt3SmLJGG5Kkec,
.VyU_p5T2xS1DcfdcsLQO {
  font-size: 0.9rem;
}

.VyU_p5T2xS1DcfdcsLQO {
  font-weight: 500;
  margin-left: 0.25rem;
  text-transform: capitalize;
}

.LyLigrHUty0Okwr_GS7S {
  font-weight: 500;
  margin-left: 0.25rem;
}

.KS78rIOv4nKBwS5YcC4A {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 0.85rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Validation/CallRecord/CallRecord.module.css"],"names":[],"mappings":"AAGA,WAAW;;AAEX;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA,gBAAgB;;AAEhB;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA,cAAc;;AAEd;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;EACpB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,mDAAwB;EACxB,kBAAkB;AACpB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-text-subtle from colors;\n\n/* Header */\n\n.title {\n  font-size: 1rem;\n  font-weight: 500;\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n}\n\n.count {\n  margin-left: 0.25rem;\n}\n\n/* Calls' list */\n\n.record {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n\n/* Call item */\n\n.call-item {\n  display: flex;\n  flex-direction: column;\n  gap: 0.5rem;\n}\n\n.call,\n.name {\n  font-size: 0.9rem;\n}\n\n.name {\n  font-weight: 500;\n  margin-left: 0.25rem;\n  text-transform: capitalize;\n}\n\n.duration {\n  font-weight: 500;\n  margin-left: 0.25rem;\n}\n\n.date {\n  color: color-text-subtle;\n  font-size: 0.85rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"title": `V3Ge5Sk0oZQ8C7OUodY2`,
	"count": `i_aZ0oM0dfM7wWQjKWab`,
	"record": `hDza4lKrk5ipywYb9St0`,
	"call-item": `AouHm7zFZ4WCz0CzK_ip`,
	"call": `gvNxATwt3SmLJGG5Kkec`,
	"name": `VyU_p5T2xS1DcfdcsLQO`,
	"duration": `LyLigrHUty0Okwr_GS7S`,
	"date": `KS78rIOv4nKBwS5YcC4A`
};
export default ___CSS_LOADER_EXPORT___;
