var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { keysToCamel } from 'utils/converter';
export function toBackendCommentAdaptor(data) {
    return {
        field: data.field,
        id_entity: data.idEntity || null,
        iss_user: data === null || data === void 0 ? void 0 : data.iss,
        resolved: data.isResolved,
        status: data.status,
        text: data.text,
        title: data.title,
        visible: data.isVisibleByUser,
        type: data.type,
    };
}
export function commentAdaptor(data) {
    var _a, _b, _c;
    var formattedData = keysToCamel(data);
    return {
        id: formattedData.id,
        writer: {
            name: "".concat((_a = formattedData.owner) === null || _a === void 0 ? void 0 : _a.firstName, " ").concat((_b = formattedData.owner) === null || _b === void 0 ? void 0 : _b.lastName),
            iss: ((_c = formattedData.owner) === null || _c === void 0 ? void 0 : _c.iss) || 0,
        },
        iss: formattedData.issUser,
        idEntity: (formattedData.idEntity) ? formattedData.idEntity : undefined,
        text: formattedData.text || '',
        title: formattedData.title || '',
        field: formattedData.field || '',
        isVisibleByUser: formattedData.visible || false,
        isResolved: formattedData.resolved || false,
        date: formattedData.updated || formattedData.created,
        status: formattedData.status || 'common',
        type: formattedData.type || null,
    };
}
export var getCommentsAsObject = function (comments) {
    if (!comments)
        return {};
    return comments.reduce(function (acc, curr) {
        var _a;
        return (__assign(__assign({}, acc), (_a = {}, _a[curr.field] = curr, _a)));
    }, {});
};
