import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoadingSpinner from 'components/library/LoadingSpinner';
import useAPI from 'hooks/useAPI';
import * as statuses from 'services/store/states';
import { getCalls } from 'services/network/api/ringover';
import { toCalls } from 'services/network/adaptor/call';
import styles from './CallRecord.module.css';
export default function CallRecord(_a) {
    var phone = _a.phone;
    var _b = useAPI({
        request: function (source) { return getCalls(phone, source); },
        adaptor: toCalls,
        cached: "call-record-".concat(phone),
    }), status = _b.status, data = _b.data;
    return (_jsxs("div", { children: [_jsxs("p", { className: styles.title, children: ["Call records", (status === statuses.SUCCESS) && (_jsx("span", { className: styles.count, children: "(".concat(data === null || data === void 0 ? void 0 : data.length, ")") }))] }), (status === statuses.LOADING) && (_jsx(LoadingSpinner, {})), (status === statuses.SUCCESS) && (_jsx("div", { className: styles.record, children: data === null || data === void 0 ? void 0 : data.map(function (call) { return (_jsxs("div", { className: styles['call-item'], children: [_jsx("p", { className: styles.date, children: call.date }), _jsxs("p", { className: styles.call, children: ["Call lead by:", _jsx("span", { className: styles.name, children: call.fullName })] }), _jsxs("p", { className: styles.call, children: ["Call duration", _jsx("span", { className: styles.duration, children: "".concat(call.duration, " seconds") })] }), _jsxs("audio", { controls: true, children: [_jsx("source", { src: call.record, type: "audio/mpeg" }), _jsx("track", { default: true, kind: "captions" }), "Your browser does not support the audio element"] })] }, call.date)); }) }))] }));
}
