var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Error } from '@mui/icons-material';
import { USER_INPUTS } from 'constants/kyc';
import { getCommentsAsObject } from 'services/network/adaptor/comment';
import { toHuman } from 'utils/converter';
import ButtonTooltip from '../../ButtonTooltip';
import styles from './DataViewerSimple.module.css';
export default function DataViewerSimple(_a) {
    var data = _a.data, adaptor = _a.adaptor, keyAdaptor = _a.keyAdaptor, _b = _a.comments, comments = _b === void 0 ? [] : _b, _c = _a.commentPrefix, commentPrefix = _c === void 0 ? '' : _c, isEdit = _a.isEdit, onChange = _a.onChange, onComment = _a.onComment;
    var formattedComments = useMemo(function () { return getCommentsAsObject(comments); }, [comments]);
    var dataComments = data.comments, newData = __rest(data, ["comments"]);
    return (_jsx("div", { className: styles.form, "data-testid": commentPrefix, children: Object.keys(newData).map(function (key) {
            var _a, _b, _c;
            return (_jsxs("div", { className: styles.answer, children: [_jsx("p", { className: styles.label, children: (!keyAdaptor)
                            ? toHuman(key)
                            : keyAdaptor(key) }), (isEdit)
                        ? (_jsxs(_Fragment, { children: [(typeof data[key] !== 'object' || !data[key])
                                    ? (_jsx("input", { type: "text", name: key, value: (!adaptor)
                                            ? data[key]
                                            : adaptor(key, data[key]), onChange: onChange, className: styles.field, disabled: USER_INPUTS["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)] === undefined }))
                                    : (_jsx("div", { className: styles.column, children: data[key]
                                            && Object.keys(data[key]).map(function (subKey) { return (_jsx("input", { type: "text", name: "".concat(key, "-").concat(subKey), value: (!adaptor)
                                                    ? data[key][subKey]
                                                    : adaptor(key, data[key][subKey]), onChange: onChange, className: styles.field }, "".concat(key, "-").concat(subKey))); }) })), (onComment && comments)
                                    && USER_INPUTS["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)]
                                    && (_jsx("div", { className: styles.left, children: _jsx(ButtonTooltip, { className: (formattedComments["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)]) ? styles.active : '', value: (_a = (formattedComments[key])) === null || _a === void 0 ? void 0 : _a.text, onClick: function () { return onComment("".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)); }, children: _jsx(Error, { className: styles.error }) }) }))] }))
                        : (_jsxs(_Fragment, { children: [(typeof data[key] !== 'object')
                                    ? (_jsx("p", { className: styles.data, "data-testid": key, children: (!adaptor)
                                            ? (_b = data[key]) === null || _b === void 0 ? void 0 : _b.toString()
                                            : adaptor(key, data[key]) }))
                                    : (_jsx("div", { children: data[key] && Object.keys(data[key]).map(function (subKey) {
                                            var _a;
                                            return (_jsx("p", { className: styles.data, "data-testid": key, children: (!adaptor)
                                                    ? (_a = data[key][subKey]) === null || _a === void 0 ? void 0 : _a.toString()
                                                    : adaptor(key, data[key][subKey]) }, subKey));
                                        }) })), (onComment
                                    && comments
                                    && USER_INPUTS["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)])
                                    && (_jsx("div", { className: styles.left, children: _jsx(ButtonTooltip, { className: (formattedComments["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)]) ? styles.active : '', value: (_c = formattedComments["".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)]) === null || _c === void 0 ? void 0 : _c.text, onClick: function () { return onComment("".concat(commentPrefix).concat(commentPrefix !== '' ? '.' : '').concat(key)); }, children: _jsx(Error, { className: styles.error }) }) }))] }))] }, key));
        }) }));
}
