import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getSuitabilityAnswers, getSuitabilityResult } from 'services/controller/userTest';
import { keysToCamel } from 'utils/converter';
import answers from 'services/network/adaptor/CustomerTestAnswers';
import useAPI from 'hooks/useAPI';
import Loading from 'components/library/Loading';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import styles from '../TunnelVerificationAppropriateness/TunnelVerificationAppropriateness.module.css';
import { getUserAnswersByIss } from 'services/network/api/users';
export default function TunnelVerificationSuitability(_a) {
    var user = _a.user;
    var _b = useAPI({
        request: function (source) { return getUserAnswersByIss(source, user.iss); },
        adaptor: function (res) { return getSuitabilityAnswers(keysToCamel(res)); },
        cached: "userAnswer".concat(user.iss),
    }), data = _b.data, status = _b.status;
    return (_jsx(Loading, { status: status, children: _jsxs(_Fragment, { children: [_jsx("p", { className: styles.title, children: "Suitability" }), _jsxs("p", { className: styles.results, children: [_jsx("span", { children: "Test result :" }), data === null || data === void 0 ? void 0 : data.suitabilityScore] }), _jsxs("p", { className: styles.results, children: [_jsx("span", { children: "Risk warning :" }), _jsx("span", { className: "".concat(styles.pill, " ").concat(styles[getSuitabilityResult(data === null || data === void 0 ? void 0 : data.suitabilityScore, data === null || data === void 0 ? void 0 : data.levelOfRisk)]), children: getSuitabilityResult(data === null || data === void 0 ? void 0 : data.suitabilityScore, data === null || data === void 0 ? void 0 : data.levelOfRisk) })] }), (data)
                    && (_jsx(DataViewerSimple, { data: data, isEdit: false, adaptor: function (key, value) { return (
                        // @ts-ignore
                        answers.suitability[key] !== undefined
                            // @ts-ignore
                            && answers.suitability[key][value])
                            || value.toString(); } }))] }) }));
}
