// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vN3KNcUcOm7LQO1JwOOU {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/views/Tunnel/TunnelVerification/TunnelSummaryEntity/TunnelSummaryEntity.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB","sourcesContent":[".subtitle {\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `vN3KNcUcOm7LQO1JwOOU`
};
export default ___CSS_LOADER_EXPORT___;
