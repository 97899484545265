var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var toCamel = function (s) { return s.replace(/([-_][a-z])/ig, function ($1) { return $1.toUpperCase()
    .replace('-', '')
    .replace('_', ''); }); };
export var toSnake = function (s) {
    if (s === s.toUpperCase())
        return s;
    return s.replace(/[A-Z]/g, function (m) { return "_".concat(m.toLowerCase()); });
};
export var toHuman = function (s) {
    if (s === s.toUpperCase())
        return s;
    return s.replace(/[A-Z]/g, function (m) { return " ".concat(m.toLowerCase()); }).replaceAll('_', ' ').replaceAll('.', ' - ');
};
var isArray = function (a) { return Array.isArray(a); };
export var isObject = function (o) { return o === Object(o) && !isArray(o) && typeof o !== 'function'; };
export var flattenData = function (values) { return values.reduce(function (acc, value) {
    if (isObject(value)) {
        return __spreadArray(__spreadArray([], acc, true), flattenData(Object.values(value)), true);
    }
    return __spreadArray(__spreadArray([], acc, true), [value], false);
}, []); };
export var keysToCamel = function (o) {
    if (isObject(o)) {
        var n_1 = {};
        Object.keys(o)
            .forEach(function (k) {
            n_1[toCamel(k)] = keysToCamel(o[k]);
        });
        return n_1;
    }
    if (isArray(o)) {
        return o.map(function (i) { return keysToCamel(i); });
    }
    return o;
};
export var keysToSnake = function (o) {
    if (isObject(o)) {
        var n_2 = {};
        Object.keys(o)
            .forEach(function (k) {
            n_2[toSnake(k)] = keysToSnake(o[k]);
        });
        return n_2;
    }
    if (isArray(o)) {
        return o.map(function (i) { return keysToSnake(i); });
    }
    return o;
};
export var capitalize = function (str) { return "".concat(str[0].toUpperCase()).concat(str.slice(1)); };
