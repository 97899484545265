var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { backendPipeActionAdaptor } from 'services/network/adaptor/pipe';
import { addToast } from 'services/store/features/toasts/actions';
import getErrorMessage from 'utils/error';
import { sendPipeAction } from 'services/network/api/pipe';
import TunnelSummaryEntity from './TunnelSummaryEntity';
import TunnelVerificationEntity from './TunnelVerificationEntity';
import TunnelVerificationFooter from './TunnelVerificationFooter';
import TunnelVerificationComments from './TunnelVerificationComments';
import TunnelSummaryUser from './TunnelSummaryUser';
import TunnelLayout from './TunnelLayout';
import TunnelVerificationUser from './TunnelVerificationUser';
import TunnelVerificationAppropriateness from './TunnelVerificationAppropriateness';
import TunnelVerificationSuitability from './TunnelVerificationSuitability';
import styles from './TunnelVerification.module.css';
import { logError } from 'utils/logs';
export default function TunnelVerification(_a) {
    var _this = this;
    var _b, _c, _d, _e, _f;
    var data = _a.data, userName = _a.userName, iss = _a.iss, onNext = _a.onNext;
    var _g = useState('default'), step = _g[0], setStep = _g[1];
    var _h = useState(((data === null || data === void 0 ? void 0 : data.type) === 'user')
        ? {
            general: 'default',
            appropriateness: 'default',
            suitability: 'default',
        }
        : {
            company: 'default',
            authorized: 'default',
        }), stepsStatus = _h[0], setStepsStatus = _h[1];
    var _j = useState((data === null || data === void 0 ? void 0 : data.activities) || []), activities = _j[0], setActivities = _j[1];
    var dispatch = useDispatch();
    var getKycStatus = function () { return Object.values(stepsStatus).reduce(function (acc, curr) {
        if (acc === curr && acc === 'completed')
            return 'completed';
        if (acc === curr)
            return acc;
        if (acc !== curr && (curr === 'error' || acc === 'error'))
            return 'error';
        return 'default';
    }, 'completed'); };
    var getStep = function () {
        var steps = Object.keys(stepsStatus);
        if (step === 'default'
            && (getKycStatus() === 'completed' || getKycStatus() === 'error'))
            return steps.length;
        return steps.indexOf(step);
    };
    var hasNextStep = function () {
        var steps = Object.keys(stepsStatus);
        return getStep() < steps.length - 1;
    };
    var nextStep = function () {
        var steps = Object.keys(stepsStatus);
        if (steps.indexOf(step) + 1 < steps.length) {
            setStep(function (curr) { return steps[steps.indexOf(curr) + 1]; });
        }
    };
    var handleApproveSuccess = function () {
        if (getKycStatus() === 'default') {
            setStepsStatus(function (curr) {
                var newStatus = curr;
                Object.keys(curr).forEach(function (key) {
                    newStatus[key] = 'completed';
                });
                return newStatus;
            });
        }
        setActivities(function (curr) { return (__spreadArray(__spreadArray([], curr, true), [
            {
                action: 'validated_kyc',
                date: new Date().toString(),
                actor: userName,
            },
        ], false)); });
    };
    var approveKyc = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, err_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, sendPipeAction(backendPipeActionAdaptor({
                            action: 'validated_kyc',
                            idEntity: ((data === null || data === void 0 ? void 0 : data.type) === 'entity') ? (_b = (_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.general) === null || _b === void 0 ? void 0 : _b.id : undefined,
                            iss: (((data === null || data === void 0 ? void 0 : data.type) === 'user') ? (_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.iss : (_d = data === null || data === void 0 ? void 0 : data.entity) === null || _d === void 0 ? void 0 : _d.user.iss) || iss,
                        }))];
                case 1:
                    response = _e.sent();
                    if (response.data.status === 'OK') {
                        handleApproveSuccess();
                    }
                    else {
                        dispatch(addToast({
                            type: 'error',
                            title: 'KYC validation as failed',
                            description: getErrorMessage(response.data),
                        }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _e.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'KYC validation as failed',
                        description: getErrorMessage(err_1),
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleRejectSuccess = function () {
        if (getKycStatus() === 'default') {
            setStepsStatus(function (curr) {
                var newStatus = curr;
                Object.keys(curr).forEach(function (key) {
                    newStatus[key] = 'error';
                });
                return newStatus;
            });
        }
        setActivities(function (curr) { return __spreadArray(__spreadArray([], curr, true), [
            {
                date: new Date().toString(),
                actor: userName,
                action: 'rejected_kyc',
            }
        ], false); });
    };
    var rejectKyc = function () { return __awaiter(_this, void 0, void 0, function () {
        var response, err_2;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, sendPipeAction(backendPipeActionAdaptor({
                            action: 'rejected_kyc',
                            idEntity: ((data === null || data === void 0 ? void 0 : data.type) === 'entity') ? (_b = (_a = data === null || data === void 0 ? void 0 : data.entity) === null || _a === void 0 ? void 0 : _a.general) === null || _b === void 0 ? void 0 : _b.id : undefined,
                            iss: (((data === null || data === void 0 ? void 0 : data.type) === 'user') ? (_c = data === null || data === void 0 ? void 0 : data.user) === null || _c === void 0 ? void 0 : _c.iss : (_d = data === null || data === void 0 ? void 0 : data.entity) === null || _d === void 0 ? void 0 : _d.user.iss) || iss,
                        }))];
                case 1:
                    response = _e.sent();
                    if (response.data.status === 'OK') {
                        handleRejectSuccess();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _e.sent();
                    logError(err_2);
                    dispatch(addToast({
                        type: 'error',
                        title: 'KYC rejection as failed',
                        description: getErrorMessage(err_2),
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var validateStep = function () {
        setStepsStatus(function (curr) {
            var _a;
            return (__assign(__assign({}, curr), (_a = {}, _a[step] = 'completed', _a)));
        });
        if (hasNextStep()) {
            nextStep();
        }
        else {
            setStep('default');
            approveKyc();
        }
    };
    var skipStep = function () {
        setStepsStatus(function (curr) {
            var _a;
            return (__assign(__assign({}, curr), (_a = {}, _a[step] = 'error', _a)));
        });
        if (hasNextStep()) {
            nextStep();
        }
        else {
            setStep('default');
            rejectKyc();
        }
    };
    var getActiveStep = function (comments, onCreate, onDelete, onEdit, markAsResolved) {
        var _a;
        if ((data === null || data === void 0 ? void 0 : data.type) === 'user' && (data === null || data === void 0 ? void 0 : data.user)) {
            var USER_STEPS = {
                default: _jsx(TunnelSummaryUser, { activities: activities, data: __assign(__assign({}, data.user), { documentsMissing: ((_a = data.userDocuments.documentMissing) === null || _a === void 0 ? void 0 : _a.length) > 0
                            ? data.userDocuments.documentMissing.join(', ')
                            : 'None' }), onReview: nextStep, onReject: rejectKyc, onApprove: approveKyc, status: getKycStatus(), comments: comments, onCreate: onCreate, onDelete: onDelete, onEdit: onEdit, markAsResolved: markAsResolved }),
                general: _jsx(TunnelVerificationUser, { user: data.user, comments: comments, handleCreate: onCreate }),
                appropriateness: _jsx(TunnelVerificationAppropriateness, { user: data.user }),
                suitability: _jsx(TunnelVerificationSuitability, { user: data.user }),
            };
            return USER_STEPS[step];
        }
        if ((data === null || data === void 0 ? void 0 : data.type) === 'entity' && (data === null || data === void 0 ? void 0 : data.entity)) {
            var ENTITY_STEPS = {
                default: _jsx(TunnelSummaryEntity, { data: data.entity, activities: activities, onReview: nextStep, onReject: rejectKyc, onApprove: approveKyc, status: getKycStatus(), comments: comments, onCreate: onCreate, onDelete: onDelete, onEdit: onEdit, markAsResolved: markAsResolved }),
                company: _jsx(TunnelVerificationEntity, { entity: data.entity, comments: comments, onCreate: onCreate }),
                authorized: _jsx(TunnelVerificationUser, { user: data.entity.user, comments: comments, handleCreate: onCreate }),
            };
            return ENTITY_STEPS[step];
        }
        return null;
    };
    if (!data) {
        return (_jsx("p", { className: styles.empty, children: "You have processed all requests. Please come back later." }));
    }
    return (_jsxs(TunnelLayout, { data: data, step: getStep(), steps: stepsStatus, children: [(((_b = data === null || data === void 0 ? void 0 : data.user) === null || _b === void 0 ? void 0 : _b.iss) || ((_d = (_c = data === null || data === void 0 ? void 0 : data.entity) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.iss))
                && (_jsx(TunnelVerificationComments, { iss: (data.type === 'entity') ? (_e = data.entity) === null || _e === void 0 ? void 0 : _e.user.iss : (_f = data.user) === null || _f === void 0 ? void 0 : _f.iss, comments: data.comments, children: function (comments, onCreate, onDelete, onEdit, markAsResolved) { return (getActiveStep(comments, onCreate, onDelete, onEdit, markAsResolved)); } })), _jsx(TunnelVerificationFooter, { step: step, hasNextStep: hasNextStep(), onSkip: skipStep, onValidate: validateStep, onNext: onNext })] }));
}
