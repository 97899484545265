var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CommentForm from 'components/CommentForm';
import DataViewerLayout from 'components/DataViewer/DataViewerLayout';
import DataViewerSimple from 'components/DataViewer/DataViewerSimple';
import { USER_INPUTS } from 'constants/kyc';
import { updateUserByIss } from 'services/network/api/users';
import { userBackendAdaptor } from 'services/network/adaptor/user';
import * as statuses from 'services/store/states';
import { addToast } from 'services/store/features/toasts/actions';
import flattenObject, { unflattenObject } from 'utils/flattenObject';
import getErrorMessage from 'utils/error';
import getGeneralInformation, { getValue, getKey } from 'utils/customer';
import styles from './CustomerGeneral.module.css';
import { logError } from 'utils/logs';
export default function CustomerGeneral(_a) {
    var _this = this;
    var user = _a.user, comments = _a.comments, _b = _a.isExpandable, isExpandable = _b === void 0 ? true : _b, handleCreate = _a.handleCreate;
    var _c = useState(''), field = _c[0], setField = _c[1];
    var _d = useState(false), isOpen = _d[0], setIsOpen = _d[1];
    var _e = useState(statuses.IDLE), status = _e[0], setStatus = _e[1];
    var _f = useState(flattenObject(user, '')), data = _f[0], setData = _f[1];
    var dispatch = useDispatch();
    var handleEdit = function (e) { return setData(function (curr) {
        var _a;
        return (__assign(__assign({}, curr), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    }); };
    var handleSave = function () { return __awaiter(_this, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setStatus(statuses.LOADING);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, updateUserByIss(user.iss, userBackendAdaptor(unflattenObject(data)))];
                case 2:
                    _a.sent();
                    setStatus(statuses.SUCCESS);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    logError(err_1);
                    dispatch(addToast({
                        type: 'error',
                        title: 'Error while updating user info',
                        description: getErrorMessage(err_1),
                        autoDeleteTime: true,
                    }));
                    setStatus(statuses.FAIL);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClose = function () { return setIsOpen(false); };
    var handleSubmit = function (option, title, comment, visibility, commentStatus, type) {
        handleCreate(option, title, comment, visibility, commentStatus, type);
        setIsOpen(false);
    };
    return (_jsxs("div", { className: styles.general, children: [(isOpen)
                && (_jsx(CommentForm, { options: USER_INPUTS, field: field, close: handleClose, submit: handleSubmit })), _jsx(DataViewerLayout, { label: "General information", status: status, save: (isExpandable) ? handleSave : undefined, isExpandable: isExpandable, children: function (isEdit) { return (_jsx(DataViewerSimple, { data: getGeneralInformation(data), comments: comments, adaptor: getValue, keyAdaptor: getKey, commentPrefix: "user", isEdit: isEdit, onComment: function (fieldStr) {
                        if (fieldStr === void 0) { fieldStr = ''; }
                        setIsOpen(true);
                        setField(fieldStr);
                    }, onChange: handleEdit })); } })] }));
}
