import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import useOutsideClickAlert from 'hooks/useOutsideClick';
import styles from './Dropdown.module.css';
export default function Dropdown(_a) {
    var value = _a.value, icon = _a.icon, children = _a.children;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var modal = useRef(null);
    useOutsideClickAlert(modal, function () { return isOpen && setIsOpen(false); });
    var handleClick = function () { return setIsOpen(function (state) { return !state; }); };
    return (_jsxs("div", { ref: modal, className: styles.dropdown, children: [_jsxs("button", { type: "button", onClick: handleClick, className: styles.button, children: [value, _jsxs("div", { className: styles.center, children: [(isOpen)
                                ? _jsx(ExpandLess, { className: styles.icon })
                                : _jsx(ExpandMore, { className: styles.icon }), icon] })] }), (isOpen)
                && (_jsx("div", { className: styles.modal, children: children }))] }));
}
