// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../library/style/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO: right or left should depends on the number of filters */

.uFifrpw13N8_kPBioFus {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  left: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
}

.pNrEpHp_aY_oLkqESALe {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  position: relative;
  width: max-content;
  z-index: 1;
}

.gaFjOGg6AnVkLnYrQvhd {
  background-color: transparent;
  border: 1px solid ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]};
  border-radius: 0.25rem;
  height: 2rem;
  padding: 0;
  transition: 0.3s;
  width: 2rem;
}

.pWe_YtW4h7Y_CfFqx6yH {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]};
  font-size: 0.875rem !important;
}

.gaFjOGg6AnVkLnYrQvhd:hover,
.gaFjOGg6AnVkLnYrQvhd:focus {
  background-color: #ececec;
  cursor: pointer;
}

.zta5UyaYfAnq9SmJTnLA {
  background: none;
  border: none;
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]};
  font-family: Roboto, sans-serif;
  font-size: 0.875rem;
  padding: 0;
  transition: 0.3s;
}

.zta5UyaYfAnq9SmJTnLA:hover {
  color: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]};
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Filters/Filters.module.css"],"names":[],"mappings":"AAGA,gEAAgE;;AAEhE;EACE,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,2BAA2B;EAC3B,OAAO;EACP,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,6BAA6B;EAC7B,8DAA4B;EAC5B,sBAAsB;EACtB,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,mDAAwB;EACxB,8BAA8B;AAChC;;AAEA;;EAEE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,mDAAmB;EACnB,+BAA+B;EAC/B,mBAAmB;EACnB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,mDAAwB;EACxB,eAAe;AACjB","sourcesContent":["@value colors: \"components/library/style/colors.css\";\n@value color-gray, color-text-subtle, color-purple, color-purple-dark from colors;\n\n/* TODO: right or left should depends on the number of filters */\n\n.dropdown {\n  align-items: flex-start;\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n  justify-content: flex-start;\n  left: 0;\n  position: absolute;\n  top: calc(100% + 0.5rem);\n}\n\n.filter-group {\n  align-items: center;\n  display: flex;\n  flex-flow: row wrap;\n  gap: 0.5rem;\n  position: relative;\n  width: max-content;\n  z-index: 1;\n}\n\n.button {\n  background-color: transparent;\n  border: 1px solid color-gray;\n  border-radius: 0.25rem;\n  height: 2rem;\n  padding: 0;\n  transition: 0.3s;\n  width: 2rem;\n}\n\n.icon {\n  color: color-text-subtle;\n  font-size: 0.875rem !important;\n}\n\n.button:hover,\n.button:focus {\n  background-color: #ececec;\n  cursor: pointer;\n}\n\n.clear {\n  background: none;\n  border: none;\n  color: color-purple;\n  font-family: Roboto, sans-serif;\n  font-size: 0.875rem;\n  padding: 0;\n  transition: 0.3s;\n}\n\n.clear:hover {\n  color: color-purple-dark;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors": `"components/library/style/colors.css"`,
	"color-gray": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-gray"]}`,
	"color-text-subtle": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-text-subtle"]}`,
	"color-purple": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple"]}`,
	"color-purple-dark": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["color-purple-dark"]}`,
	"dropdown": `uFifrpw13N8_kPBioFus`,
	"filter-group": `pNrEpHp_aY_oLkqESALe`,
	"button": `gaFjOGg6AnVkLnYrQvhd`,
	"icon": `pWe_YtW4h7Y_CfFqx6yH`,
	"clear": `zta5UyaYfAnq9SmJTnLA`
};
export default ___CSS_LOADER_EXPORT___;
