var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFiltersDispatch, useFiltersState } from 'contexts/FiltersProvider';
import { RiskLevel } from 'services/network/adaptor/user';
import filtersStyles from '../FiltersStatus/FiltersStatus.module.css';
export default function FilterRisk() {
    var _a, _b, _c;
    var selected = useFiltersState().selected;
    var dispatch = useFiltersDispatch();
    var updateSelection = function (e) {
        if (!dispatch)
            return;
        dispatch({
            type: 'UPDATE',
            payload: {
                risk: (e.target.checked)
                    ? __spreadArray(__spreadArray([], (selected.risk || []), true), [e.target.value], false) : (selected.risk || []).filter(function (value) { return value !== e.target.value; }),
            },
        });
    };
    return (_jsxs("div", { className: filtersStyles.dropdown, children: [_jsxs("label", { htmlFor: "risk-high", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", id: "risk-high", value: RiskLevel.HIGH, checked: ((_a = selected.risk) === null || _a === void 0 ? void 0 : _a.includes(RiskLevel.HIGH)) || false, onChange: updateSelection }), RiskLevel.HIGH] }), _jsxs("label", { htmlFor: "risk-medium", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", id: "risk-medium", value: RiskLevel.MEDIUM, checked: ((_b = selected.risk) === null || _b === void 0 ? void 0 : _b.includes(RiskLevel.MEDIUM)) || false, onChange: updateSelection }), RiskLevel.MEDIUM] }), _jsxs("label", { htmlFor: "risk-low", className: filtersStyles.option, children: [_jsx("input", { type: "checkbox", id: "risk-low", value: RiskLevel.LOW, checked: ((_c = selected.risk) === null || _c === void 0 ? void 0 : _c.includes(RiskLevel.LOW)) || false, onChange: updateSelection }), RiskLevel.LOW] })] }));
}
