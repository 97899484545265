var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useReducer, } from 'react';
var StateContext = createContext({
    loading: true,
    selected: {},
});
var DispatchContext = createContext(null);
var reducer = function (state, action) {
    switch (action === null || action === void 0 ? void 0 : action.type) {
        case 'FETCH_SELECTED_FILTERS':
            return __assign(__assign({}, state), { loading: false, selected: __assign(__assign({}, state.selected), action.payload) });
        case 'UPDATE':
            window.localStorage.setItem('ayomi-kyc-filters', JSON.stringify(__assign(__assign({}, state.selected), action.payload)));
            return __assign(__assign({}, state), { loading: false, selected: __assign(__assign({}, state.selected), action.payload) });
        case 'CLEAR':
            return __assign(__assign({}, state), { loading: false, selected: {} });
        default:
            return state;
    }
};
export function FiltersProvider(_a) {
    var children = _a.children;
    var _b = useReducer(reducer, { loading: true, selected: {} }), state = _b[0], dispatch = _b[1];
    useEffect(function () {
        // retrieve last filters
        var savedFilters = window.localStorage.getItem('ayomi-kyc-filters');
        dispatch({ type: 'FETCH_SELECTED_FILTERS', payload: savedFilters ? JSON.parse(savedFilters) : {} });
    }, []);
    return (_jsx(StateContext.Provider, { value: state, children: _jsx(DispatchContext.Provider, { value: dispatch, children: children }) }));
}
export var useFiltersState = function () { return useContext(StateContext); };
export var useFiltersDispatch = function () { return useContext(DispatchContext); };
