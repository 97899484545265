import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag, WatchLater } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import styles from '../TunnelLayout.module.css';
export default function TunnelLayoutHeader(_a) {
    var name = _a.name, type = _a.type, date = _a.date, iss = _a.iss, link = _a.link;
    return (_jsxs("header", { className: styles.header, children: [_jsxs("div", { children: [_jsxs("p", { className: styles.title, children: [name, _jsx("span", { className: "".concat(styles.pill, " ").concat(styles.investor), children: type })] }), _jsxs("div", { className: styles.row, children: [_jsxs("p", { className: styles.details, children: [_jsx(WatchLater, { className: styles.icon }), _jsx("span", { children: "Send on ".concat(date) })] }), _jsxs("p", { className: styles.details, children: [_jsx(Tag, { className: styles.icon }), _jsx("span", { children: "ISS : ".concat(iss) })] })] })] }), _jsx(NavLink, { to: link, className: styles.button, children: link.includes('entity')
                    ? 'Open entity view'
                    : 'Open customer view' })] }));
}
