var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import Loading from 'components/library/Loading';
import useAPI from 'hooks/useAPI';
import { getNextProfile } from 'services/network/api/care';
import { userAdaptor } from 'services/network/adaptor/user';
import * as statuses from 'services/store/states';
import { entityAdaptor } from 'services/network/adaptor/entity';
import { createLog } from 'services/network/api/pipe';
import CareTunnelLogic from '../CareTunnelLogic';
export default function CareTunnelManager(_a) {
    var _b, _c;
    var connectedUser = _a.connectedUser;
    var _d = useAPI({
        request: function (source) { return getNextProfile(source); },
        adaptor: function (inData) {
            var _a, _b, _c, _d, _e, _f;
            return ({
                lead: {
                    user: __assign(__assign({}, userAdaptor(((_a = inData.lead) === null || _a === void 0 ? void 0 : _a.user) || inData.lead)), { iss: ((_c = (_b = inData.lead) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.iss) || ((_d = inData.lead) === null || _d === void 0 ? void 0 : _d.issUser) }),
                    entity: (((_e = inData === null || inData === void 0 ? void 0 : inData.lead) === null || _e === void 0 ? void 0 : _e.entity) || (inData === null || inData === void 0 ? void 0 : inData.entity))
                        ? entityAdaptor(((_f = inData === null || inData === void 0 ? void 0 : inData.lead) === null || _f === void 0 ? void 0 : _f.entity) || (inData === null || inData === void 0 ? void 0 : inData.entity))
                        : undefined,
                },
            });
        },
    }), status = _d.status, data = _d.data, error = _d.error, fetchData = _d.fetchData;
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (data === null || data === void 0 ? void 0 : data.lead) {
            createLog({
                action: 'tunnel_care_get_lead', idEntity: (_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.lead) === null || _a === void 0 ? void 0 : _a.entity) === null || _b === void 0 ? void 0 : _b.general) === null || _c === void 0 ? void 0 : _c.id, issActor: connectedUser, issUser: ((_e = (_d = data === null || data === void 0 ? void 0 : data.lead) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.iss) || ((_g = (_f = data === null || data === void 0 ? void 0 : data.lead) === null || _f === void 0 ? void 0 : _f.user) === null || _g === void 0 ? void 0 : _g.issUser),
            });
        }
    }, [data]);
    return (_jsx(Loading, { status: status, errorFallback: (status === statuses.FAIL && error) ? _jsx("p", { children: error }) : undefined, children: (data)
            && (_jsx(CareTunnelLogic, { user: ((_b = data.lead) === null || _b === void 0 ? void 0 : _b.user) || data.lead, entity: (_c = data.lead) === null || _c === void 0 ? void 0 : _c.entity, connectedUser: connectedUser, onNext: fetchData })) }));
}
